<template lang="pug">
  Loading(v-if="isLoading")
  .uk-container.layout.uk-margin-large-top.event-single-root(v-else)
    ul.uk-breadcrumb(class="uk-visible@s")
      li
        router-link( :to="{ name: 'home' }")
          icon.icon-position-fixed.uk-margin-small-right(type="home" width="15" height="12")
          | {{ $t('HOME') }}
      li
        router-link( :to="{ name: 'eventsHome' }") {{ $t('EVENTS') }}
      li {{ post.title }}

    .uk-margin-medium-top
      Hooper.slideshow(
        v-if="banners.length"
        style="height: auto"
        :centerMode="true"
        :wheelControl="false"
        :infiniteScroll="true"
      )
        Slide(v-for="banner in banners" :key="banner")
          .uk-position-relative
            .uk-background-cover(:style="{ backgroundImage: `url(${banner})` }")
        HooperNavigation(slot="hooper-addons")
        HooperPagination(slot="hooper-addons" class="uk-visible@m")

    .uk-margin-medium-top
      vk-grid.detail-wrapper(gutter="small")
        div(class="uk-width-2-3@m")
          vk-tabs(
            :activeTab.sync="activeTab"
          )
            vk-tabs-item(:title="$t('EVENT_INTRODUTION')")
              .content(v-html="post.detail")
            vk-tabs-item(:title="$t('EVENT_MAP')")
              .map-iframe(
                v-html="post.googlemap_iframe_code"
              )
              //- iframe.map-iframe(
              //-   frameborder="0"
              //-   style="border:0"
              //-   allowfullscreen
              //-   :src="post.googlemap_iframe_code"
              //- )

        div.side-info(
          v-if="post.post_id"
          class="uk-width-1-3@m"
        )
          vk-sticky(
            :offset="170"
            :bottom="true"
            :showOnUp="activeTab === 1"
            media="960"
          )
            div(style="z-index: 1")
              .title {{ post.title }}

              .sub-title {{ post.title_en }}

              .status {{ post.status }}

              .price-wrapper
                priceGroup(
                  :price="post.price"
                  :priceSale="post.price_sale"
                  :curr="post.curr"
                )

              .summary(v-html="post.summary")

              //- .form-wrapper.uk-margin-top
              //-   EditorTypeCount(
              //-     :typeOptions="typeOptions"
              //-     v-model="form"
              //-   )

              .button-wrapper.uk-margin-small-top
                a.uk-button.uk-button-primary.uk-width-1-1.uk-margin-small-top(
                  v-if="isOnSale"
                  :href="post.ticket_url"
                ) {{ $t('EVENT_APPLY') }}
                button.uk-button.uk-button-success.uk-width-1-1.uk-margin-small-top(
                  v-if="isClosed"
                  disabled
                ) {{ $t('EVENT_SOLD_OUT') }}
                button.uk-button.uk-button-event-activing.uk-width-1-1.uk-margin-small-top(
                  v-if="isInProgress"
                  disabled
                ) {{ $t('EVENT_ACTIVING') }}
                button.uk-button.uk-button-primary.uk-width-1-1.uk-margin-small-top(
                  v-if="isUninit"
                  disabled
                ) {{ $t('EVENT_NOT_YET_START') }}


                DropdownButton(
                  v-if="hasAgent"
                  buttonClass="uk-button uk-button-default uk-width-1-1 uk-margin-small-top btn-other-site"
                )
                  span
                    icon.uk-margin-small-right(type="hand" width="20" height="19")
                    | {{ $t('EVENT_OTHER_SITE') }}

                  template(slot="content")
                    a.agent-item(
                      v-for="agent in post.agent"
                      v-if="agent.agent_name && agent.agent_url"
                      :key="agent.agent_name + agent.agent_url"
                      :href="agent.agent_url"
                      target="_blank"
                    ) {{ agent.agent_name }}

                ButtonShareGroup.uk-margin-small-top(:post="post")

              .info-wrapper.uk-margin-medium-top
                .info-wrapper__content.uk-margin-small-top
                  span.info__title {{ $t('EVENT_APPLY_DATE') }}：
                  span.info__value
                    div {{ post.date_request_start }} ({{ getDayInWeek(post.date_request_start) }})
                    div {{ $t('EVENT_DATE_TO') }}
                    div {{ post.date_request_end }} ({{ getDayInWeek(post.date_request_end) }})

                .info-wrapper__content.uk-margin-small-top
                  span.info__title {{ $t('EVENT_EVENT_DATE') }}：
                  span.info__value
                    div {{ post.date_start }} ({{ getDayInWeek(post.date_start) }})
                    div {{ $t('EVENT_DATE_TO') }}
                    div {{ post.date_end }} ({{ getDayInWeek(post.date_end) }})

                .info-wrapper__content.uk-margin-small-top
                  span.info__title {{ $t('EVENT_PLACE') }}：
                  span.info__value {{ post.place }}

                .info-wrapper__content.uk-margin-small-top
                  span.info__title {{ $t('SERIAL_NUMBER') }}：
                  span.info__value {{ post.serial }}
</template>
<script>
import {
  Hooper,
  Slide,
  Pagination as HooperPagination,
  Navigation as HooperNavigation
} from 'hooper';
import 'hooper/dist/hooper.css';

import { eventSinglePost } from '../mocks/eventSinglePost';

import { apiGetEventsSingle } from '../apiRequest/events';
import { DAY_OF_WEEK_SHORT } from '../utils/dayOfWeek';
import { eventsStatus } from '../utils/eventsStatus'

import ButtonShareGroup from '../components/buttons/ButtonShareGroup.vue';
import priceGroup from '../components/commons/priceGroup.vue';
import EditorTypeCount from '../components/commons/EditorTypeCount.vue';
import DropdownButton from '../components/buttons/DropdownButton.vue';

/*
interface IPost {
  post_id: 0;
  slider_img_url: string // 'url1,url2,url3';;
  title: string;
  title_en: string;
  price: string;
  price_sale: string;
  summary: string;
  status: string;
  ticker_url: string;
  date_request_start: string;
  date_request_end: string;
  date_start: string;
  date_end: string;
  serial: string;
  detail: string;
  googlemap_iframe_code: string;
  agent: {
    agent_name: string;
    agent_url: string;
  }[];
}
*/

const EVENT_STATUS = {
  NOT_YET: '尚未開始',
  READY: '報名中',
  ACTIVING: '進行中',
  ENDED: '已經結束',
};

export default {
  name: 'EventSingle',
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination,
    ButtonShareGroup,
    priceGroup,
    EditorTypeCount,
    DropdownButton,
  },
  data () {
    return {
      post: {},
      activeTab: 0,
      // form: {
      //   type: '',
      //   quantity: 1,
      // },
      // typeOptions: [
      //   {
      //     value: 'DVD',
      //     label: 'DVD',
      //   },
      // ],
      isLoading: false,
    };
  },
  async created() {
    try {
      this.isLoading = true;
      await this.init(this.$route.params.id);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    getDayInWeek(date) {
      return this.$t(DAY_OF_WEEK_SHORT[moment(date).day()]);
    },
    async init(id) {
      // this.form = {
      //   type: '',
      //   quantity: 1,
      // };

      await Promise.all([
        this.getPost(id),
      ]);

      // this.form.type = this.typeOptions[0].value;
    },
    async getPost(id) {
      try {
        const res = await apiGetEventsSingle(id, {params: this.$route.query});
        this.post = res[0]
      } catch (e) {
        console.error(e);
        this.$router.push({ name: 'home' });
      }
    }
  },
  computed: {
    banners() {
      return this.post.slider_img_url
        ? this.post.slider_img_url
        : [];
    },
    isUninit() {
      return eventsStatus.uninitiated.includes(this.post.status);
    },
    isOnSale() {
      return eventsStatus.onSale.includes(this.post.status);
    },
    isInProgress() {
      return eventsStatus.inProgress.includes(this.post.status);
    },
    isClosed() {
      return eventsStatus.closed.includes(this.post.status);
    },
    hasAgent() {
      return !_.isEmpty(this.post.agent);
    },
  },
  head: {
    title: {
      inner: ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/sass/helper/_variable.scss';
@import '@/sass/helper/_mixin.scss';

.slideshow {
  .uk-background-cover {
    @include mq(640px) {
      padding-top: calc( 170 / 335 * 100% ) !important;
    }
  }
}

.detail-wrapper {
  @include mq(960px) {
    flex-direction: column-reverse;
  }
}

.title {
  font-size: 24px;
  line-height: 48px;

  @include mq(960px) {
    font-size: 24px;
    line-height: 39px;
  }
}

.sub-title {
  font-size: 20px;
  line-height: 48px;

  @include mq(960px) {
    font-size: 18px;
    line-height: 20px;
  }
}

.status {
    display: inline-block;
    border: 1px solid #c7000b;
    padding: 5px;
    color: #c7000b;
    font-size: 20px;
}

.price-wrapper {
  font-size: 30px;
}

.summary {
  color: #959595;
  font-size: 14px;
  line-height: 25px;

  @include mq(960px) {
    font-size: 16px;
  }
}

.signup {
  font-size: 14px;
}

.info-wrapper {
  font-size: 14px;

  .info__title {
    color: #959595;
  }
}

.content {
  font-size: 16px;
  line-height: 35px;
  margin-bottom: 50px;
}

.uk-button-success {
  color: #fff;
  background: #38B826;
}

.uk-button-event-activing {
  color: #fff;
  background: #848484;
}

.map-iframe {
  width: 100%;
  height: 342px;
  margin-bottom: 50px;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.btn-other-site {
  position: relative;
  .icon-down {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.info-wrapper__content {
  display: flex;
}

.agent-item {
  color: $primary;
  margin-right: 10px;
  font-size: 15px;
  text-decoration: underline;

  &::after{
    content: ',';
    margin-left: 5px;
  }

  &:nth-last-child(1) {
    &::after{
      content: none;
    }
  }
}

.side-info {
  margin-bottom: 100px;

  @include mq(960px) {
    margin-bottom: 50px;
  }
}
</style>

<style lang="scss">
.event-single-root {
  .map-iframe {

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

