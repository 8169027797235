import i18n from './i18n';
import { LANG_MAP } from './utils/locales';
import axios from 'axios';

/** axois instance for API request */
const axoisApi = axios.create();

axoisApi.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
if (typeof document !== 'undefined') {
  let token = document.head.querySelector('meta[name="csrf-token"]');
  if (token) {
    axoisApi.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
  } else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
  }
}

//const API_URL = 'http://cnex.nongdesign.com/api';
// var API_URL = 'https://www.cnex.com.tw/api';
// var API_URL = 'https://test.cnex.com.tw/api';
//if (typeof process !== 'undefined'){
//	if ((typeof process.env.NODE_ENV !== 'undefined')&&(typeof process.env.NODE_ENV !== 'development')){
//		API_URL = 'https://test.cnex.com.tw/api';
//	}
//}



axoisApi.interceptors.request.use((config) => {
  let API_URL = '';
  if (typeof window !== 'undefined')
    API_URL = window.location.origin + '/api';
  else
    API_URL = 'https://www.cnex.com.tw/api';
  const lang = _.get(config, 'meta.lang') || LANG_MAP[i18n.locale].api;
  const baseUrl = _.get(config, 'meta.baseUrl') || API_URL;

  config.url = `${baseUrl}/${lang}/${config.url}`;

  return config;
});

axoisApi.interceptors.response.use((res) => {
  return res.data;
});

if (typeof window !== 'undefined') {
  window.axios = axoisApi;
}

if (typeof global !== 'undefined') {
  global.axios = axoisApi;
}
