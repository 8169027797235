<template lang="pug">
  .loading
    //- | Loading
    vk-spinner(ratio="1.5")
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_variable.scss';
.loading {
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  // font-size: 28px;
  .uk-spinner {
    color: $primary;
  }
}
</style>