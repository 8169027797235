<template lang="pug">
  span.share-btn(
    @click="click"
  )
    icon(
      type="wechat"
      width="16"
      height="16"
      style="margin-right: 5px;"
    )
    | {{ $t('SHARE_BY_WECHAT') }}
</template>

<script>
import { setWx } from '../../apiRequest/wechat';

export default {
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    async click() {
      await setWx(window.location.href);
      wx.hideMenuItems({
        menuList: [
          'menuItem:share:qq',
          'menuItem:share:QZone',
          'menuItem:share:weiboApp',
          'menuItem:copyUrl',
        ],
      });
      wx.ready(() => {
        wx.onMenuShareTimeline({
          title: this.post.title,
          link: window.location.href,
        });

        wx.onMenuShareAppMessage({
          title: this.post.title,
          link: window.location.href,
        });
      });
    },
  },
}
</script>

<style lang="scss" scoped>
$bg: #02C322;

.share-btn {
  background: $bg;

  &:hover {
    background: darken($bg, 5%);
  }
}
</style>
