import Vue from 'vue';
import VeeValidate from 'vee-validate';
import ch from 'vee-validate/dist/locale/zh_TW';
import en from 'vee-validate/dist/locale/en';
import cn from 'vee-validate/dist/locale/zh_CN';

import i18n from './i18n';

Vue.use(VeeValidate, {
  i18n,
  dictionary: {
    ch,
    en,
    cn,
  },
});