if (typeof window !== 'undefined') {
  window._ = require('lodash');
  window.moment = require('moment');

  try {
    window.$ = window.jQuery = require('jquery');
    require('./utils/jquerys2t');
  } catch (e) { }
}

import Vue from "vue";
import Vuikit from "vuikit";
import VuikitIcons from "@vuikit/icons";
import VueMeta from 'vue-meta';

import { EventBus } from './event-bus';
import router from "./routes.js";
import store from "./store.js";
import i18n from './i18n';
import './axios';
import './filters';
import './veeValidate';
import './icons/index'
import Loading from './components/Loading.vue'

import App from './App.vue';
import { checkLangSite } from './utils/helpers';

Vue.use(Vuikit);
Vue.use(VuikitIcons);
Vue.use(vue => {
  vue.prototype.$event = EventBus;
});
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
});

Vue.component('Loading', Loading);

Vue.mixin({
  updated() {
    if (this.$i18n.locale === 'cn') {
      $('body').t2s();
    }
  },
});

const shouldRender = checkLangSite();

export default new Vue({
  router,
  store,
  i18n,
  render: h => h(shouldRender ? App : 'div'),
});
