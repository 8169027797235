<template lang="pug">
  Loading(v-if="isLoading")
  .uk-container.layout.uk-margin-large-top.uk-margin-xxlarge-bottom.shopping-single-root(v-else)
    ul.uk-breadcrumb(class="uk-visible@s")
      li
        router-link( :to="{ name: 'home' }")
          icon.icon-position-fixed.uk-margin-small-right(type="home" width="15" height="12")
          | {{ $t('HOME') }}
      li
        router-link( :to="{ name: 'shoppingHome' }") {{ $t('SHOPPING') }}
      li {{ post.title }}

    vk-grid(gutter="small")
      div(class="uk-width-2-3@m")
        SlideWithThumbs(
          :items="slides"
          :trailerUrl="post.trailer_url"
          :showTrailer="false"
          :hot="post.hot"
        )

      div(class="uk-width-1-3@m")
        .title {{ post.title }}

        .sub-title {{ post.title_en }}

        .price-wrapper.uk-margin-small-top
          priceGroup(
            :price="post.price"
            :priceSale="post.price_sale"
            :curr="post.curr"
          )

        .summary.uk-margin-small-top(v-html="post.summary")

        //- .form-wrapper.uk-margin-top
        //-   EditorTypeCount(
        //-     :typeOptions="typeOptions"
        //-     v-model="form"
        //-   )

        .button-wrapper.uk-margin-top
          //- button.uk-button.uk-button-primary.uk-width-1-1.uk-margin-small-top(
          //- ) {{ $t('SHOP_ADD_TO_CART') }}
          button.uk-button.uk-button-primary.uk-width-1-1.uk-margin-small-top(
            v-if="post.status === 'n'"
            disabled
          ) {{ $t('SHOP_NOT_YET_SOLD') }}

          .btn-other-site-wapper(v-if="hasAgent")
            //- DropdownButton.uk-button.uk-button-default.uk-width-1-1.uk-margin-small-top.btn-other-site
            DropdownButton(
              buttonClass="uk-button uk-button-default uk-width-1-1 uk-margin-small-top btn-other-site"
            )
              span
                icon.uk-margin-small-right(type="hand" width="20" height="19")
                | {{ $t('SHOP_PRODUCT_AGENT_SITE') }}

              template(slot="content")
                a.agent-item(
                  v-for="agent in post.agent"
                  :key="agent.agent_name + agent.agent_url"
                  :href="agent.agent_url"
                  target="_blank"
                ) {{ agent.agent_name }}

          a.uk-button.uk-button-default.uk-width-1-1.uk-margin-small-top(
            v-if="post.offical_url"
            :href="post.offical_url"
            target="_blank"
          )
            icon.uk-margin-small-right(
              type="home-button"
              width="24"
              height="19"
            )
            | {{ $t('SHOP_OFFICAL_SITE') }}

          ButtonShareGroup.uk-margin-small-top(:post="post")

          .btn-trailer.uk-margin-small-top(
            v-if="post.trailer_url"
            @click="onShowVideo(post.trailer_url)"
          )
            icon(
              type="play-f2"
              width="15"
              height="15"
            )
            span.uk-margin-small-left {{ $t('WATCH_TRAILER') }}

        .info-wrapper.uk-margin-medium-top
          .info-wrapper__content.uk-margin-small-top(v-if="post.title")
            span.info__title {{ $t('SHOP_TITLE') }}&nbsp|&nbsp
            span.info__value {{ post.title }}

          .info-wrapper__content.uk-margin-small-top(v-if="post.time")
            span.info__title {{ $t('RUN_TIME') }}&nbsp|&nbsp
            span.info__value {{ post.time }}

          .info-wrapper__content.uk-margin-small-top(v-if="post.date")
            span.info__title {{ $t('SHOP_RELEASE') }}&nbsp|&nbsp
            span.info__value {{ post.date }}

          .info-wrapper__content.uk-margin-small-top(v-if="post.director")
            span.info__title {{ $t('DIRECTOR') }}&nbsp|&nbsp
            span.info__value {{ post.director }}

          .info-wrapper__content.uk-margin-small-top(v-if="post.type")
            span.info__title {{ $t('TYPE') }}&nbsp|&nbsp
            span.info__value {{ post.type }}

          .info-wrapper__content.uk-margin-small-top(v-if="post.quality")
            span.info__title {{ $t('QUALITY') }}&nbsp|&nbsp
            span.info__value {{ post.quality }}

          .info-wrapper__content.uk-margin-small-top(v-if="post.serial_id")
            span.info__title {{ $t('SHOP_SERIAL') }}&nbsp|&nbsp
            span.info__value {{ post.serial_id }}

      .detail-wrapper(class="uk-width-2-3@m")
        .uk-margin-top
          vk-tabs
            vk-tabs-item(:title="$t('SHOP_PRODUCT_DESC')")
              div(v-html="post.detail")
            vk-tabs-item(:title="$t('SHOP_SPEC')")
              div(v-html="post.spec")
            vk-tabs-item(:title="$t('SHOP_PURCHASE_NOTE')")
              div(v-html="post.notice")

      div(class="uk-width-1-3@m")
        .relate-product-wrapper.uk-margin-top
          .relate-product__title.uk-margin-bottom {{ $t('RELATED_PRODUCT') }}
          .relate-product__posts
            vk-grid(gutter="collapse")
              ShoppingPostCard(
                v-for="relatedPost in relatedPosts"
                :key="relatedPost.post_id"
                class="uk-width-1-1@m uk-width-1-3@s uk-width-1-2"
                :class="{\
                  'card-l': isWidthL,\
                  'card-m': isWidthM,\
                  'card-s': isWidthS,\
                }"
                :style="postCardStyle"
                :post="relatedPost"
                :vertical="isWidthM || isWidthS"
                @click.native="onClickRelated(relatedPost.post_id)"
              )
</template>

<script>
import SlideWithThumbs from '../components/commons/SlideWithThumbs.vue';
import ShoppingPostCard from '../components/ShoppingPostCard.vue';
import ButtonShareGroup from '../components/buttons/ButtonShareGroup.vue';
import priceGroup from '../components/commons/priceGroup.vue';
import DropdownButton from '../components/buttons/DropdownButton.vue';
import EditorTypeCount from '../components/commons/EditorTypeCount.vue';

import { mqWidthRange } from '../utils/mediaQuery';
import {
  apiGetShopSingle,
  apiGetShopSingleRelated,
} from '../apiRequest/shopping';
import { MODAL_EVENT } from '../utils/events';

import { shoppingSinglePost } from '../mocks/shoppingSinglePost';
import { shoppingPosts } from '../mocks/shoppingPosts';

/*
interface IPost {
  post_id: 0;
  slideshow_url: string[] // 'url1,url2,url3';
  trailer_url: string;
  title: string;
  title_en: string;
  price: string;
  price_sale: string;
  summary: string;
  disc: string;
  agent: {
    agent_name: string;
    agent_url: string;
  }[];
  purchase_url: string;
  country: string;
  time: string;
  date: string;
  company: string;
  director: string;
  type: string;
  quality: string;
  serial_id: string;
  detail: string;
  spec: string;
  notice: string
  status:
    // 'y': 回傳y顯示購物車按鈕
    // 'n': 回傳n顯示尚未開賣按鈕
    // 'h': 回傳h不顯示該商品跳轉回頁
}
*/

export default {
  name: 'ShoppingSingle',
  components: {
    SlideWithThumbs,
    ShoppingPostCard,
    ButtonShareGroup,
    priceGroup,
    DropdownButton,
    EditorTypeCount,
  },
  data () {
    return {
      post: {},
      relatedPostsOrigin: [],
      // form: {
      //   type: '',
      //   quantity: 1,
      // },
      // typeOptions: [
      //   {
      //     value: 'DVD',
      //     label: 'DVD',
      //   },
      // ],
      isLoading: false,
    };
  },
  async created() {
    try {
      this.isLoading = true;
      // this.form.type = this.typeOptions[0].value;
      await this.init(this.$route.params.id);
    } finally {
      this.isLoading = false;
    }
  },
  async beforeRouteUpdate(to, from, next) {
    this.init(to.params.id);

    next();
  },
  methods: {
    async init(id) {
      // this.form = {
      //   type: '',
      //   quantity: 1,
      // };

      await Promise.all([
        this.getPost(id),
      ]);

      await this.getReleatedPosts(id, { params: { type: this.post.type } });

      // this.form.type = this.typeOptions[0].value;
    },
    async getPost(id) {
      try {
        const res = await apiGetShopSingle(id);
        if (res[0].status === 'h') {
          this.$router.push({ name: 'home' });
          return;
        }
        this.post = res[0];
      } catch (e) {
        console.error(e);
        this.$router.push({ name: 'home' });
      }
    },
    async getReleatedPosts(id, payload) {
      const res = await apiGetShopSingleRelated(id, payload);
      if (!res) return;
      this.relatedPostsOrigin = res;
    },
    onClickRelated() {
      this.$router.replace()
    },
    onShowVideo(url) {
      if (!url) return;

      this.$event.$emit(MODAL_EVENT.SHOW_TRAILER, {
        videoUrl: url,
      });
    },
  },
  computed: {
    relatedPosts() {
      let count = 5;
      if (this.isWidthM) count = 3;
      if (this.isWidthS) count = 2;

      return this.relatedPostsOrigin.slice(0, count);
    },
    isWidthL() {
      return this.$store.getters['windowSize/isWidthL'];
    },
    isWidthM() {
      return this.$store.getters['windowSize/isWidthM'];
    },
    isWidthS() {
      return this.$store.getters['windowSize/isWidthS'];
    },
    postCardStyle() {
      if (!this.isWidthL) return { padding: '15px' };
      else return { 'margin-bottom': '15px' };
    },
    slides() {
      if (!this.post.slideshow_url) return [];

      return this.post.slideshow_url.map(
        o => ({ img_url: o }),
      );
    },
    hasAgent() {
      return !_.isEmpty(this.post.agent);
    },
  },
  metaInfo() {
    const imgUrl = Array.isArray(this.post.slideshow_url)
      ? this.post.slideshow_url[0]
      : '';
    return {
      title: this.post.title,
      meta: [
        { property: 'og:title', content: this.post.title },
        { property: 'og:description', content: this.post.summary },
        { property: 'og:site_name', content: 'CNEX' },
        { property: 'og:image', content: imgUrl },
      ]
    };
  },
}
</script>

<style lang="scss" scoped>
@import '@/sass/helper/_variable.scss';
@import '@/sass/helper/_mixin.scss';

.title {
  font-size: 24px;
  color: #000;
  letter-spacing: 2.4px;
}

.sub-title {
  font-size: 18px;
  color: #000;
  letter-spacing: 2.4px;
}

.price-wrapper {
  font-size: 30px;
}

.summary {
  color: #959595;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 2.4px;
}

.signup {
  font-size: 14px;

  @include mq(640px) {
    margin-bottom: 55px;
  }
}

.info-wrapper {
  font-size: 14px;
  letter-spacing: 2px;

  .info__title {
    color: #959595;
  }
}

.detail-wrapper {
  @include mq(960px) {
    transform: translateY(0px);
  }
}

.relate-product-wrapper {

  .relate-product__posts {
    padding: 0 20px;
  }

  .relate-product__title {
    font-size: 24px;
    color: $primary;
    font-weight: bold;
    border-bottom: 1px solid $primary;
  }
}

.agent-item {
  color: $primary;
  margin-right: 10px;
  font-size: 15px;
  text-decoration: underline;

  &::after{
    content: ',';
    margin-left: 5px;
  }

  &:nth-last-child(1) {
    &::after{
      content: none;
    }
  }
}

.btn-trailer {
  font-size: 15px;
  color:  $primary;
  cursor: pointer;
  display: flex;
  align-items: center;
}
</style>

<style lang="scss">
.relate-product-wrapper {
  .card-l {
    .uk-background-cover {
      padding-top: 145%;
    }
  }
}

// .shopping-single-root {
//   .uk-tab {
//     margin-top: 50px;
//   }
// }
</style>
