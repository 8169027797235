export const modals = {
  state: {
    isModalLoginActive: false, // 登入彈跳視窗狀態
    isModalSingUpActive: false, // 註冊彈跳視窗狀態
    isModalPasswordNavActive: false, // 忘記密碼彈跳視窗狀態
    isModalMobileNavActive: false, // 手機版選單跳狀態
    isModalQuestionActive: false, // 常見問題彈跳視窗狀態
    isModalCataloguePostLicenseActive: false,
  },
  mutations: {
    // 切換登入彈跳視窗狀態
    modalLoginToggle ( state ) {
      state.isModalLoginActive = !state.isModalLoginActive;
      state.isModalSingUpActive = false;
      state.isModalMobileNavActive = false;
    },
    // 切換登入彈跳視窗狀態
    modalSignUpToggle(state) {
      state.isModalSingUpActive = !state.isModalSingUpActive;
      state.isModalLoginActive = false;
      state.isModalPasswordNavActive = false;
      state.isModalMobileNavActive = false;
    },
    // 切換登入彈跳視窗狀態
    modalPasswordToggle(state) {
      state.isModalPasswordNavActive = !state.isModalPasswordNavActive;
      state.isModalLoginActive = false;
    },
    // 切換手機版選單狀態
    modalMobileNavToggle(state) {
      state.isModalMobileNavActive = !state.isModalMobileNavActive;
      console.log(state);
    },
    // 切換常見問題彈跳視窗狀態
    modalQuestionToggle(state) {
      state.isModalQuestionActive = !state.isModalQuestionActive;
    },
    modalCataloguePostLicenseToggle(state) {
      state.isModalCataloguePostLicenseActive = !state.isModalCataloguePostLicenseActive;
    },
    // 回到初始值
    modalClose(state) {
      Object.keys(state).forEach(k => {
        state[k] = false;
      });
      // state.isModalMobileNavActive = false
      // state.isModalLoginActive = false
      // state.isModalPasswordNavActive = false
      // state.isModalSingUpActive = false
    }
  }
}