export const channelPosts = [
  {
    post_id: 1,
    img_url: '../img/post1.jpg',
    title: '瘋狂新聞台',
    title_en: 'Mad as Hell',
    award: '2014 加拿大Hot Docs國際紀錄片影展',
    date: '2018-03-11',
    time: '13:00',
    tags: [ '2015', '伊朗', '52min', '導演' ],
    country: '伊朗',
    director: '導演',
    country: '伊朗',
    director: '導演',
  },
  {
    post_id: 2,
    img_url: '../img/post2.jpg',
    title: '二手衣的奇幻旅程',
    title_en: 'The Secret Life of Your Clothes',
    award: '2014 加拿大Hot Docs國際紀錄片影展',
    date: '2018-03-11',
    time: '13:00',
    tags: [ '2015', '伊朗', '52min', '導演' ],
    country: '伊朗',
    director: '導演',
  },
  {
    post_id: 3,
    img_url: '../img/post3.jpg',
    title: '馬利啟示錄',
    title_en: 'Bamako, Year0',
    award: '',
    date: '2018-03-11',
    time: '13:00',
    tags: [ '2015', '伊朗', '52min', '導演' ],
    country: '伊朗',
    director: '導演',
  },
  {
    post_id: 4,
    img_url: '../img/post1.jpg',
    title: '瘋狂新聞台',
    title_en: 'Mad as Hell',
    award: '2014 加拿大Hot Docs國際紀錄片影展',
    date: '2018-03-11',
    time: '13:00',
    tags: [ '2015', '伊朗', '52min', '導演' ],
    country: '伊朗',
    director: '導演',
  },
  {
    post_id: 5,
    img_url: '../img/post2.jpg',
    title: '二手衣的奇幻旅程',
    title_en: 'The Secret Life of Your Clothes',
    award: '2014 加拿大Hot Docs國際紀錄片影展',
    date: '2018-03-11',
    time: '13:00',
    tags: [ '2015', '伊朗', '52min', '導演' ],
    country: '伊朗',
    director: '導演',
  },
  {
    post_id: 6,
    img_url: '../img/post3.jpg',
    title: '馬利啟示錄',
    title_en: 'Bamako, Year0',
    award: '',
    date: '2018-03-11',
    time: '13:00',
    tags: [ '2015', '伊朗', '52min', '導演' ],
    country: '伊朗',
    director: '導演',
  },
];
