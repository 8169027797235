<template lang='pug'>
    div
        vk-modal.modal-remove-padding.modal-large-width(:show.sync='modalState', center)
            vk-modal-close.text-dark(@click='modalQuestionToggle')
            .uk-margin-remove
                .uk-position-relative
                    h3.uk-text-center.uk-margin-top.text-red.uk-margin-medium-top.text-bold(style='font-size: 22px;') {{ $t('FAQ') }}
                .uk-position-relative.lbdiv
                    label.lbfaq {{ $t('FAQ_CA') }}
                    .divfaq
                        select#faqsl.slfaq.uk-select(v-model="cate" @change="catechange")
                            option(value='0') {{ $t('FAQ_0') }}
                            option(value='A') {{ $t('FAQ_A') }}
                            option(value='B') {{ $t('FAQ_B') }}
                            option(value='C') {{ $t('FAQ_C') }}
                            option(value='D') {{ $t('FAQ_D') }}
                            option(value='E') {{ $t('FAQ_E') }}
                            option(value='F') {{ $t('FAQ_F') }}
                            option(value='G') {{ $t('FAQ_G') }}
                            option(value='H') {{ $t('FAQ_H') }}
                            option(value='I') {{ $t('FAQ_I') }}
                        .btnfag(@click="svgClk") ∨
                .uk-margin-medium-bottom.scrollbar
                    VueFaqAccordion(:items='formatPosts')
</template>
<script>
import VueFaqAccordion from 'vue-faq-accordion'
import { apiGetFAQ } from '../apiRequest/others';


export default {
    name: 'FAQ',
    components: {
        VueFaqAccordion
    },
    data () {
        return {
            posts: [],
            cate: '0',
        };
    },
    watch: {
        async modalState(v) {
            if (!v) return;
            this.getPosts({ params: { ...this.$route.query, category: this.cate,} });
        }
    },
    methods: {
        async getPosts(payload) {
            const res = await apiGetFAQ(payload);
            this.posts = res;
        },
        catechange() {
            this.getPosts({ params: { ...this.$route.query, category: this.cate,} });
        },
        modalQuestionToggle(){
            this.$store.commit('modalQuestionToggle')
        },
        svgClk() {
            document.getElementById('faqsl').click();
        },
    },
    computed: {
        formatPosts() {
            return this.posts.map(
                o => ({
                    title: o.question,
                    value: o.answer,
                }),
            );
        },
        modalState: {
            get() {
                return this.$store.state.modals.isModalQuestionActive
            },
            set() {
                this.$store.state.modals.isModalQuestionActive
            }
        }
    },
    head: {
        title: {
            inner: ''
        },
    },
}
</script>
<style lang="scss" scoped>
.lbdiv {
    display: flex;
    justify-content: center;
}
.lbfaq {
    font-size: 18px;
    color: black;
    padding-right: 10px;
}
.slfaq {
    font-size: 16px;
    text-align: center;
    background-color: transparent;
}
.btnfag {
    height: 30px;
    width: 30px;
    border-left: 1px solid;
    left: -40px;
    position: relative;
    text-align: right;
    caret-color: transparent;
    z-index: -1;
}
.divfaq {
    width: 30%;
    display: flex;
    align-items: center;
}
</style>
