<template lang="pug">
  Loading(v-if="isLoading")
  div.home-index(v-else)
    .section.section-first
      Hooper.slideshow(
        v-if="banners.length"
        style="height: auto"
        :centerMode="true"
        :wheelControl="false"
        :infiniteScroll="true"
        :playSpeed="bannerSlideSpeed"
        autoPlay
      )
        Slide(v-for="banner in banners" :key="banner.id")
          HomeBanner(:banner="banner")
        HooperNavigation(slot="hooper-addons")

    .section
      .uk-position-relative.post-wrapper(
        v-for="(post, index) in posts"
        :key="post.id"
        class="uk-visible@m"
        :class="{ even: index % 2 }"
      )
        vk-grid(gutter="small")
          .post-background.uk-width-2-5(:style="{ backgroundImage: `url(${post.img_url})` }")
          .post-content.uk-width-expand
            HomePostPanel(:post="post")
          .post-cover.uk-width-2-5
            .post-cover__img(:style="{ backgroundImage: `url(${post.img_url})` }")

      HomePostPanelM(
        v-for="post in posts"
        :key="post.id + 'm'"
        :post="post"
      )
</template>

<script>
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination,
} from 'hooper';
import 'hooper/dist/hooper.css';

import {
  apiGetHomeSlideshow,
  apiGetHomeSlideshowBottom,
} from '../apiRequest/home';

import HomeBanner from '../components/HomeBanner.vue';
import HomePostPanel from '../components/HomePostPanel.vue';
import HomePostPanelM from '../components/HomePostPanelM.vue';

import { homeBanners, homePosts } from '../mocks/home.js';

export default {
  name: 'Home',
  components: {
    Hooper,
    HooperNavigation,
    HooperPagination,
    Slide,
    HomeBanner,
    HomePostPanel,
    HomePostPanelM,
  },
  data () {
    return {
      banners: [],
      posts: [],
      isLoading: false,

      postSlideSettings: {
        vertical: true,
        itemsToShow: 1,
        centerMode: true,
        autoPlay: true,
      },
      currentPostIndex: 0,
    }
  },
  async created() {
    try {
      this.isLoading = true;
      await Promise.all([
        this.getHomeSlideshow({ params: { ...this.$route.query }}),
        this.getHomeSlideshowBottom({ params: { ...this.$route.query }}),
      ]);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    onPostSlide(e) {
      this.currentPostIndex = e.currentSlide;
    },

    onPrevPost() {
      const prevIndex = this.currentPostIndex - 1;
      this.currentPostIndex = prevIndex > -1 ? prevIndex : this.postLen - 1;
    },

    onNextPost() {
      const nextIndex = this.currentPostIndex + 1;
      this.currentPostIndex = nextIndex < this.postLen ? nextIndex : 0;
    },

    async getHomeSlideshow(payload) {
      this.banners = await apiGetHomeSlideshow(payload);
    },

    async getHomeSlideshowBottom(payload) {
      this.posts = await apiGetHomeSlideshowBottom(payload);
    },
  },
  computed: {
    currentPost() {
      return this.posts[this.currentPostIndex];
    },

    postLen() {
      return this.posts.length;
    },

    bannerSlideSpeed() {
      return this.$store.getters['slideshow/playSpeed']('seconds_home');
    },

    postSlideSpeed() {
      return this.$store.getters['slideshow/playSpeed']('seconds_home_bottom');
    },
  },
  // head: {
  //   title: {
  //     inner: ''
  //   },
  // },
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_variable.scss';
@import '../../sass/helper/_mixin.scss';

.post-wrapper {
  .post-background {
    height: 100vh;
    filter: blur(5px);
    background-size: cover;
  }

  .post-cover {
    position: absolute;
    top: 50%;
    left: 120px;
    transform: translate(0, -50%);
    width: 45%;


    .post-cover__img {
      background-repeat: no-repeat;
      background-size: cover;
      padding-top: 100%;
    }
  }

  .post-content {
    padding-top: 120px;
    padding-left: 0;
    padding-right: 50px;
  }

  &.even {
    .uk-grid {
      flex-direction: row-reverse !important;
    }

    .post-cover {
      right: 120px;
      left: initial;
    }
  }
}

.icon-arrow-top {
  transform: rotateZ(-180deg);
}

.section-first {
  @include mq-min(960px) {
    // margin-bottom: -150px;
    margin-bottom: 50px;
  }
}
</style>

<style lang="scss">
@import '../../sass/helper/_variable.scss';
@import '../../sass/helper/_mixin.scss';

.home-index {
  .post-wrapper {
    &.even {
      .content-wrapper {
        padding: 100px;
        padding-left: 200px;
        padding-right: 200px;
      }
    }
  }
}
</style>
