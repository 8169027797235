<template lang="pug">
  a.uk-position-relative.link-no-underline.link-card.post-panel(
    @click="toSingle"
  )
    div.img-col(class='uk-width-2-5')
      .uk-background-cover(:style='{ backgroundImage: "url("+ imgUrl +")"}')
        span.uk-position-center.text-small.text-white READ MORE
    div.detail-col
      .datetime
        span
          | {{ date }}
          span(style="margin: 0 10px") |
          span(v-if="time") {{ time }}
      .title {{ post.title }} | {{ post.title_en }}
      div {{ desc }}
      .tags
        //- button.uk-button-default(v-for="tag in post.tags" :key="tag") {{ tag }}
        button.uk-button-default(
          v-if="post.country"
          @click.stop="search(post.country)"
        ) {{ post.country }}
        button.uk-button-default(
          v-if="post.director"
          @click.stop="search(post.director)"
        ) {{ post.director }}
</template>

<script>
import { getImgUrl, formatTimeWithMin } from '../utils/helpers';
/*
interface IPost {
  post_id: number;
  img_url: string;
  program_start: string;
  title: string;
  title_en: string;
  date: string;
  time: string;
  country: string;
  director: string;
}
*/

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imgUrl: '',
    };
  },
  async created() {
    await this.getImgUrl();
  },
  methods: {
    async getImgUrl() {
      const url = await getImgUrl([
        this.post.img_url,
        `https://files.cnex.com.tw/channel/${this.post.post_id}_still.jpg`,
        'https://files.cnex.com.tw/channel/default_still.jpg',
      ]);
      this.imgUrl = url;
    },

    toSingle() {
      this.$router.push({
        name: 'channelSingle',
        params: {
          id: this.post.post_id,
        },
      });
    },

    search(keyword) {
      this.$router.push({
        name: 'channelList',
        query: { keyword },
      });
    }
  },
  computed: {
    date() {
      const date = moment(this.post.program_start);
      return date.isValid()
        ? date.format('YYYY-MM-DD')
        : this.post.program_start;
    },
    time() {
      const date = moment(this.post.program_start);

      return date.isValid()
        ? date.format('HH:mm')
        : '';
    },
    desc() {
      if (!this.post.desc) return '';
      const maxLen = 130;
      return this.post.desc.length > maxLen
        ? `${this.post.desc.slice(0, maxLen)}...`
        : this.post.desc;
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_variable.scss';
@import '../../sass/helper/_mixin.scss';

.post-panel {
  display: flex;
  margin-bottom: 20px;

  .title {
    font-size: 26px;
    font-weight: 500;
    color: #000;

    @include mq(960px) {
      font-size: 18px;
    }
    @include mq(640px) {
      font-size: 16px;
    }

  }

  .datetime {
    font-size: 22px;
    color: #000;

    @include mq(960px) {
      font-size: 20px;
    }
    @include mq(640px) {
      font-size: 16px;
    }
  }

  .img-col {
    margin-right: 30px;
    flex-shrink: 0;
    .uk-background-cover {
      padding-top: 70%;
    }
  }

  .detail-col {
      > div {
        margin-bottom: 10px;
      }
    .tags {
      display: flex;
      > button {
        margin-right: 10px;
        padding: 2px 15px;
        cursor: pointer;
      }
    }
  }
}

.desc {
  font-size: 14px;
  color: #000;
  margin: 5px 0;
  margin-bottom: 25px !important;
  letter-spacing: 2px;

  @include mq(960px) {
    display: none;
  }
}
</style>

