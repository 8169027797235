import Vue from 'vue';
import { apiGetSlideshowSetting } from '../apiRequest/others';

export const slideshow = {
  namespaced: true,
  state: {
    playSpeeds: {},
  },
  mutations: {
    updatePlaySpeeds(state, data) {
      state.playSpeeds = {
        ...state.playSpeeds,
        ...data,
      };
    }
  },
  getters: {
    playSpeed: state => key => {
      if (!state.playSpeeds[key]) return 3000;
      return state.playSpeeds[key] * 1000;
    },
  },
  actions: {
    async getPlaySpeeds({ commit }) {
      const res = await apiGetSlideshowSetting();
      commit('updatePlaySpeeds', res[0]);
    },
  },
};
