<template lang="pug">
  Loading(v-if="isLoading")
  .uk-container.layout.uk-margin-large-top.post-list(v-else)
    ul.uk-breadcrumb(class="uk-visible@s")
      li
        router-link(:to="{ name: 'home' }")
          icon.icon-position-fixed.uk-margin-small-right(type="home" width="15" height="12")
          | {{ $t('HOME') }}
      li
        router-link(:to="{ name: 'postsHome' }") {{ $t('POSTS') }}
      li {{ $t('SEARCH_RESULT') }}
      
    SearchForm(
      :optionSets="searchOptionSets"
      v-model="searchForm"
      @onSearch="onSearch"
    )

    .uk-margin-large-top
      h1.uk-heading-line.uk-text-center.text-red 
        span(v-html="$t('SEARCH_RESULT_TITLE', { count: posts.length, filter: oldSearchForm.keyword })")

      vk-grid
        PostPostPanel(
          v-for="post in posts"
          :key="post.post_id"
          :post="post"
        )
      
      ButtonLoadMore(
        v-if="!noMore"
        @click="getMorePost"
      )
</template>

<script>
import { postPosts } from '../mocks/postPosts.js';

import { PostSearch } from '../mixins/PostSearch';
import {
  apiGetPostsList,
} from '../apiRequest/posts';

import SearchForm from '../components/SearchForm.vue';
import PostPostPanel from '../components/PostPostPanel.vue';
import ButtonLoadMore from '../components/buttons/ButtonLoadMore.vue';

export default {
  name: 'PostList',
  mixins: [PostSearch],
  components: {
    SearchForm,
    PostPostPanel,
    ButtonLoadMore,
  },
  data () {
    return {
      posts: [],
      searchForm: {},
      oldSearchForm: {},
      noMore: false,
      isLoading: false,
    };
  },
  async created() {
    try {
      this.isLoading = true;
      await this.init(this.$route.query);      
    } finally {
      this.isLoading = false;
    }
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    this.init(to.query);
  },
  methods: {
    async init(query) {
      this.oldSearchForm = { ...query };
      this.searchForm = { ...query };

      Promise.all([
        this.getPosts({ params: query }).then(res => { this.posts = res; }),
      ]);
    },

    onSearch() {
      this.$router.replace({ query: this.searchForm });
    },

    async getMorePost() {
      const payload = {
        params: {
          offset: this.posts.length,
          ...this.searchForm,
        },
      };

      const res = await this.getPosts(payload);

      this.posts = [
        ...this.posts,
        ...res,
      ];
    },

    async getPosts(payload) {
      try {
        const res = await apiGetPostsList(payload);
        if (!res) return [];

        this.noMore = !res.length;
        return res;
      } catch (e) {
        console.log(e);
        this.noMore = true;
        return [];
      }
    },
  },
  head: {
    title: {
      inner: ''
    },
  },
}
</script>

<style lang="scss" scoped>
.post-panel {
  width: 100%;
}
</style>