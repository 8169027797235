export const DAY_OF_WEEK = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

export const DAY_OF_WEEK_SHORT = [
  'SUNDAY_SHORT',
  'MONDAY_SHORT',
  'TUESDAY_SHORT',
  'WEDNESDAY_SHORT',
  'THURSDAY_SHORT',
  'FRIDAY_SHORT',
  'SATURDAY_SHORT',
];