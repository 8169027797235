export async function apiGetHomeSlideshow(payload) {
  return await axios.get(
    `slideshow/home`,
    payload,
  );
}

export async function apiGetHomeSlideshowBottom(payload) {
  return await axios.get(
    `slideshow/home/bottom`,
    payload,
  );
}
