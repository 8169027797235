export const LANG_ID = {
  en: 'en',
  ch: 'ch',
  cn: 'cn',
};

export const LANG_OPTIONS = [
  {
    id: LANG_ID.ch,
    label: 'LANG_CH',
  },
  {
    id: LANG_ID.cn,
    label: 'LANG_CN',
  },
  {
    id: LANG_ID.en,
    label: 'LANG_EN',
  }
];

export const LANG_MAP = {
  [LANG_ID.ch]: {
    id: LANG_ID.ch,
    label: 'LANG_CH',
    api: 'ch',
  },
  [LANG_ID.cn]: {
    id: LANG_ID.cn,
    label: 'LANG_CN',
    api: 'ch',
  },
  [LANG_ID.en]: {
    id: LANG_ID.en,
    label: 'LANG_EN',
    api: 'en',
  },
};

export const DEFAULT_LANG = LANG_ID.ch;


export const nowLang = typeof window !== 'undefined'
  ? window.localStorage.getItem('locale')
  : LANG_ID.ch;