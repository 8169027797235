<template lang="pug">
  Loading(v-if="isLoading")
  .channel-home(v-else)
    Hooper.slideshow(
      v-if="banners.length"
      ref="bannerHooper"
      style="height: auto"
      :centerMode="true"
      :wheelControl="false"
      :infiniteScroll="true"
      :playSpeed="bannerSlideSpeed"
      autoPlay
    )
      Slide(v-for="banner in banners" :key="banner.post_id")
        .uk-position-relative.banner
          SlideShowVideo(
            v-if="banner.video_url"
            :videoUrl="banner.video_url"
            :imgUrl="banner.img_url"
          )
          .uk-background-cover(:style="{ backgroundImage: `url(${banner.img_url})`}")
          .uk-position-center-left.uk-padding.content-wrapper(
            class="uk-width-2-5@m uk-width-2-3@s uk-width-1-1"
            style="left: 10%"
          )
            .cnex_mod(v-if="banner._type === 'NOW_PLAYING'") {{ $t('NOW_MOD') }}
            .now-play-group(v-if="banner._type === 'NOW_PLAYING'")
              .icon-rec
              .text-white.text {{ $t('NOW_PLAYING') }}
            p.title-group
              .text-white.title {{ banner.title }}
              span.uk-display-block.uk-text-small.sub_title {{ banner.title_en }}
            p.text-red.tag(v-if="banner.date")
              | {{ banner.date | date }} |
              | {{ banner.country }} |
              | {{ banner.time }} |
              | {{ $t('DIRECTOR') }} {{ banner.director }}
            p.desc(
              class="uk-visible@m"
              v-html="desc(banner.desc)"
            )
            .banner-footer
              div(class="uk-width-1-2@s uk-width-auto")
                router-link.uk-button.uk-button-primary.uk-button-large.text-thin.btn-read-more(
                  v-if="banner._type === 'NOW_PLAYING'"
                  :to="{ name:'channelSingle', params: { id: banner.post_id } }"
                )
                  | {{ $t('READ_MORE') }}
                  vk-icon(icon="chevron-right")
                a.uk-button.uk-button-primary.uk-button-large.text-thin.btn-read-more(
                  v-else-if="banner._type !== 'NOW_PLAYING' && banner.link_url"
                  :href="banner.link_url"
                )
                  | {{ $t('READ_MORE') }}
                  vk-icon(icon="chevron-right")
              ButtonWatchTrailer(
                v-if="banner.trailer_url"
                @click="showTrailer(banner.trailer_url)"
              )
      HooperNavigation(slot="hooper-addons")
      HooperPagination(slot="hooper-addons" class="uk-visible@m")

    .uk-container.layout
      //- Editor Selected
      .uk-margin-large-top.editr-selected
        h1.uk-heading-line.uk-text-center.text-red
          span {{ $t('CHANNEL_FEATURES') }}
        Hooper.slider(
          v-if="postsFeatures.length"
          style="height: auto"
          :settings="sliderEditorSelectedSettings"
        )
          Slide( v-for="post in postsFeatures" :key="post.post_id" )
            ChannelVideoCard(:post="post")
          HooperNavigation(slot="hooper-addons")

      //- Search
      SearchForm(
        :optionSets="searchOptionSets"
        v-model="searchForm"
        @onSearch="onSearch"
      )
        template(#withSearchButton)
          .date-picker-input-wrapper
            span(@click="showDatePickerPop")
              icon(
                type="calendar-search"
                width="30"
                height="30"
              )
            date-picker(
              ref="datePicker"
              valueType="format"
              v-model="datePicker"
              @change="onChangeDatePicker"
          )

      //- Programs
      .uk-margin-large-top.programs
        h1.uk-heading-line.uk-text-center.text-red
          span {{ $t('CHANNEL_PROGRAM_LIST') }}
        ChannelDatePicker(
          @onChangeDate="onChangeDate"
        )
        ChannelPostPanel(
          v-for="(post, index) in posts"
          :key="post.post_id + index"
          :post="post"
        )
        //- ButtonViewAll(
        //-   @click="viewAll"
        //- )
</template>

<script>
import {
  Hooper,
  Slide,
  Pagination as HooperPagination,
  Navigation as HooperNavigation
} from 'hooper';
import 'hooper/dist/hooper.css';

import {
  apiGetChannleNowPlaying,
  apiGetChannelFeature,
  apiGetChannelList,
  apiGetChannelSlideshows,
} from '../apiRequest/channel';
import { getImgUrl } from '../utils/helpers';
import { MODAL_EVENT } from '../utils/events';

import { channelFeatures } from '../mocks/channelFeatures';
import { channelPosts } from '../mocks/channelPosts';

import { ChannelSearch } from '../mixins/ChannelSearch';

import SearchForm from '../components/SearchForm.vue'
import ChannelVideoCard from '../components/ChannelVideoCard.vue';
import ChannelPostPanel from '../components/ChannelPostPanel.vue';
import ButtonViewAll from '../components/buttons/ButtonViewAll.vue';
import ChannelDatePicker from '../components/ChannelDatePicker.vue';
import ButtonWatchTrailer from '../components/buttons/ButtonWatchTrailer.vue';
import SlideShowVideo from '../components/commons/SlideShowVideo.vue';

const SEARCH_FIELD = {
  year: 'year',
  month: 'month',
  date: 'date',
};

export default {
  name: 'ChannelHome',
  mixins: [ChannelSearch],
  components: {
    SearchForm,
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination,
    ChannelVideoCard,
    ChannelPostPanel,
    ButtonViewAll,
    ChannelDatePicker,
    ButtonWatchTrailer,
    SlideShowVideo,
  },
  data () {
    return {
      // ====== Mock Data Start ===== //
      // banner: {},
      banners: [],
      postsFeatures: [],
      posts: [],
      // ====== Mock Data End ===== //

      showModal: false,
      trailerUrl: '',

      searchForm: {},
      datePicker: '',

      sliderEditorSelectedSettings: {
        itemsToShow: 2,
        // centerMode: true,
        wheelControl: false,
        infiniteScroll: true,
        breakpoints: {
          960: {
            itemsToShow: 4
          },
          640: {
            itemsToShow: 3
          },
        }
      },
      isLoading: false,
    }
  },
  async created() {
    try {
      this.isLoading = true;
      let q = this.$route.query;
      await Promise.all([
        // this.getNowPlayings().then(res => {
        //   if (!res) return;
        //   this.banners = [res];
        // }),
        this.getBanners({params: q}),
        this.getChannelFeature({
          params: { limit: 12 },
        }),
        this.getPosts({
          params: {
            date: moment().format('YYYYMMDD'),
            limit: 99,
          },
        }),
      ]);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    onChangeDate(date /** YYYY-MM-DD */) {
      // const [ year, month, date ] = value.split('-');
      // this.$router.push({
      //   name: 'channelCalendar',
      //   query: {
      //     date: moment(date).format('YYYYMMDD'),
      //   },
      // });
      this.datePicker = date;
      this.getPosts({
        params: {
          date: moment(date).format('YYYYMMDD'),
          limit: 99,
        },
      });
    },

    onSearch() {
      this.$router.push({
        name: 'channelList',
        query: this.searchForm
      });
    },

    async getNowPlayings() {
      const res = await apiGetChannleNowPlaying();
      if (!res || typeof res === 'string') return;

      const nowplaying = res;
      const imgUrl = await getImgUrl([
        res.img_url,
        `https://files.cnex.com.tw/channel/${res.post_id}_still.jpg`,
        'https://files.cnex.com.tw/channel/default_still.jpg',
      ]);
      nowplaying.img_url = imgUrl;
      nowplaying._type = 'NOW_PLAYING';

      return nowplaying;
    },

    async getChannelFeature(payload) {
      const res = await apiGetChannelFeature(payload);
      this.postsFeatures = res;
    },

    async getPosts(payload) {
      const res = await apiGetChannelList(payload);
      this.posts = res;
    },

    async getSlideshows(payload) {
      const res = await apiGetChannelSlideshows(payload);
      if (!res || !Array.isArray(res)) return [];

      return res.map(o => ({
        ...o,
        _type: 'SLIDESHOW',
      }));
    },

    async getBanners(payload) {
      const results = await Promise.all([
        this.getNowPlayings(),
        this.getSlideshows(payload),
      ]);

      const banners = results[1];
      if (results[0]) {
        banners.unshift(results[0]);
      }

      this.banners = banners;
    },

    viewAll() {
      this.$router.push({
        name: 'channelList',
      });
    },

    desc(desc) {
      if (!desc) return '';
      const maxLen = 120;
      return desc.length >= maxLen
        ? `${desc.slice(0, maxLen - 3)}...`
        : desc;
    },

    showTrailer(trailerUrl) {
      this.$event.$emit(MODAL_EVENT.SHOW_TRAILER, {
        videoUrl: trailerUrl,
      });
    },
  },
  filters: {
    date(date) {
      if (!date) return '';
      return moment(date).format('YYYY');
    },
  },
  computed: {
    bannerSlideSpeed() {
      return this.$store.getters['slideshow/playSpeed']('seconds_channel');
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_variable.scss';
@import '../../sass/helper/_mixin.scss';
.cnex_mod {
    color: white;
    font-size: 24px;
    padding-bottom: 10px;
}

.content-wrapper {
  @include mq(640px) {
    left: 0 !important;
    top: 65%;
  }
}

.banner {
  .uk-background-cover {
    padding-top: calc( 750 / 1920 *100% );
    @include mq(960px) {
      padding-top: calc( 500 / 960 * 100% );
    }
    @include mq(640px) {
      padding-top: calc( 550 / 375 *100% );
    }
  }


  &:before {
    content: '';
    background-color: transparent !important;
    background: -moz-linear-gradient(left, rgba(0,0,0,1) 0%, rgba(0,0,0,0.51) 35%, rgba(0,0,0,0.05) 100%);
    background: -webkit-linear-gradient(left, rgba(0,0,0,1) 0%,rgba(0,0,0,0.51) 35%,rgba(0,0,0,0.05) 100%);
    background: linear-gradient(to right, rgba(0,0,0,1) 0%,rgba(0,0,0,0.51) 35%,rgba(0,0,0,0.05) 100%);
  }

  .title {
    font-size: 42px;

    @include mq(960px) {
      font-size: 36px;
    }
    @include mq(640px) {
      font-size: 30px;
    }
  }

  .sub_title {
    font-size: 30px;
    color: $secondary;

    @include mq(960px) {
      font-size: 24px;
    }
    @include mq(640px) {
      font-size: 18px;
    }
  }

  .desc {
    font-size: 20px;
    color: $secondary;
  }

  .now-play-group {
    display: flex;
    align-items: center;

    .icon-rec {
      width: 17.5px;
      height: 17.5px;
      border-radius: 50%;
      background: $primary;
      margin-right: 10px;
    }

    .text {
      font-size: 18px;
      border: medium;
    }
  }

  .banner-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mq(640px) {
      flex-direction: column-reverse;
      align-items: initial;
    }
  }
}

.btn-read-more {
  display: inline-flex;
  align-items: center;
}
</style>

<style lang="scss">
@import '../../sass/helper/_variable.scss';
@import '../../sass/helper/_mixin.scss';

$datePickerPrimary: #CC1949;
$datePickerTitle: #F6A9A8;

.channel-home {
  .iframe-wrapper {
    .mask-cover {
      background-color: transparent !important;
      background: -moz-linear-gradient(left, rgba(0,0,0,1) 0%, rgba(0,0,0,0.51) 35%, rgba(0,0,0,0.05) 100%);
      background: -webkit-linear-gradient(left, rgba(0,0,0,1) 0%,rgba(0,0,0,0.51) 35%,rgba(0,0,0,0.05) 100%);
      background: linear-gradient(to right, rgba(0,0,0,1) 0%,rgba(0,0,0,0.51) 35%,rgba(0,0,0,0.05) 100%);
    }
  }

  .keyword-input {
    flex-grow: 3;
  }
}
</style>
