<template lang="pug">
  Loading(v-if="isLoading")
  .uk-container.layout.uk-margin-large-top.uk-margin-large-bottom.channel-single-root(v-else)
    ul.uk-breadcrumb(class="uk-visible@s")
      li
        router-link( :to="{ name: 'home' }")
          icon.icon-position-fixed.uk-margin-small-right(type="home" width="15" height="12")
          | {{ $t('HOME') }}
      li
        router-link( :to="{ name: 'channelHome' }") {{ $t('CHANNEL') }}
      li {{ post.title }}

    .uk-margin-medium-top
      h1.uk-h2.text-bold
        .title {{ post.title }}
        .subtitle {{ post.title_en }}

    vk-grid(gutter="small")
      div(class="uk-width-2-3@m")
        SlideWithThumbs(
          :items="[imgUrl]"
          :hideThumb="true"
        )

      div(class="uk-width-1-3@m")
        vk-grid(gutter="small")
          div(class="uk-width-1-1@m uk-width-1-2@s uk-width-1-1")
            InfoTable(:infos="infos")
            ButtonShareGroup.uk-margin-small-top(:post="post")

      div(class="uk-width-2-3@m")
        .uk-margin-medium-top.tab-group
          vk-tabs
            vk-tabs-item(:title="$t('CATA_INTRODUCTION')")
              div(v-html="post.detail")
            vk-tabs-item(:title="$t('AWARD')")
              div(v-html="post.award")
            vk-tabs-item(:title="$t('COMMENT')")
              .fb-comments(
                :data-href="locationHref"
                data-width="100%"
                data-numposts="10"
              )
</template>

<script>
import { catalogueSinglePost } from '../mocks/catalogueSinglePost';

import { apiGetChannelSingle } from '../apiRequest/channel';
import { getImgUrl } from '../utils/helpers';

import SlideWithThumbs from '../components/commons/SlideWithThumbs.vue';
import InfoTable from '../components/commons/InfoTable.vue';
import ButtonShareGroup from '../components/buttons/ButtonShareGroup.vue';

/*
interface IPost {
  post-id: 0;
  title: string;
  title-en: string;
  slideshow-url: string;
  country: string;
  time: string;
  date: string;
  company: string;
  director: string;
  type: string;
  quality: string;
  program-start: string;
  detail: string;
  award: string;
}
*/

export default {
  name: 'ChannelSingle',
  components: {
    SlideWithThumbs,
    InfoTable,
    ButtonShareGroup,
  },
  data () {
    return {
      post: {},
      imgUrl: '',
      isLoading: false,
      tabElms: [],
    };
  },
  async created() {
    try {
      this.isLoading = true;
      await Promise.all([
        this.getPost(this.$route.params.id),
      ]);

      const url = await getImgUrl([
        this.post.slideshow_url,
        `https://files.cnex.com.tw/channel/${this.post.post_id}_still.jpg`,
        'https://files.cnex.com.tw/channel/default_still.jpg',
      ]);
      this.imgUrl = {
        img_url: url,
      };
    } finally {
      this.isLoading = false;
    }
  },
  mounted() {
    setTimeout(() => {
      this.tabElms = document.querySelectorAll('.tab-group li a');
      this.tabElms.forEach(o => {
        o.addEventListener('click', this.onTabClick);
      });
    }, 500);
  },
  beforeDestroy() {
    this.tabElms.forEach(o => {
      o.removeEventListener('click', this.onTabClick);
    });
  },
  methods: {
    async getPost(id) {
      try {
        const res = await apiGetChannelSingle(id);
        this.post = res[0] || {};
        setTimeout(() => {
          this.tabElms = document.querySelectorAll('.tab-group li a');
          console.log(this.tabElms);
          this.tabElms.forEach(o => {
            o.addEventListener('click', this.onTabClick);
          });
        }, 1000);
      } catch (e) {
        console.error(e);
        this.$router.push({ name: 'home' });
      }
    },

    async onTabClick(e) {
      const tab = $(e.currentTarget);
      if (!tab[0] || tab[0].innerText !== this.$t('COMMENT')) return;

      // await this.$nextTick();
      // FB.XFBML.parse();
      setTimeout(() => {
        FB.XFBML.parse();
      }, 500);
    },
  },
  computed: {
    infos() {
      const { post } = this;

      const playTimes = post.program_start
        ? post.program_start.split(',')
        : [];

      const formatedPlayTimes = playTimes.map(o =>{
        const date = moment(o);
        return date.isValid() ? date.format('MM/DD HH:mm') : o;
      });

      let p1 = (post.company == null)?'':post.company.replace(/,/g, ' | ');
      let p2 = (post.director == null)?'':post.director.replace(/,/g, ', ');
      let p3 = (post.type == null)?'':post.type.replace(/,/g, ', ');
      let p4 = (post.quality == null)?'':post.quality.replace(/,/g, ', ');
      let p5 = (post.production == null)?'':post.production.replace(/,/g, ' | ');

      return [
        { label: this.$t('CATA_INFO_TITLE'), values: [post.title_dhead] },
        { label: this.$t('COUNTRY'), values: [post.country] },
        { label: this.$t('RUN_TIME'), values: [`${post.time}`] },
        { label: this.$t('PUBLISH_DATE'), values: [post.date] },
        { label: this.$t('PRODUCTION'), values: [p5] },
        { label: this.$t('COMPANY'), values: [p1] },
        { label: this.$t('DIRECTOR'), values: [p2] },
        { label: this.$t('TYPE'), values: [p3] },
        { label: this.$t('QUALITY'), values: [p4] },
        { label: this.$t('CHANNEL_PLAY_DATE'), values: formatedPlayTimes },
      ];
    },
    locationHref() {
      if (typeof window !== 'undefined') {
        return window.location.href;
      } else {
        return '';
      }
    },
    slideshow_urls() {
      return this.post.slideshow_url.split(',')
        .map(o => ({ img_url: o }));
    },
  },
  head: {
    title: {
      inner: ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_mixin.scss';

.title, .subtitle {
  font-size: 34px;

  @include mq(960px) {
    font-size: 24px;
  }
}
</style>
