<template lang="pug">
  .slide-with-thumb-root
    .uk-position-relative
      .btn-trailer(
        v-if="trailerUrl && showTrailer"
        @click="onShowVideo(trailerUrl)"
      )
        icon(
          type="play-f2"
          width="15"
          height="15"
        )
        span.uk-margin-small-left {{ $t('WATCH_TRAILER') }}
      img(v-if="hot" style="position:absolute;z-index:1;width:25%;top:0;left:70%;" :src="require('../../../img/hot-sale.png')")
      hooper.slideshow-post(
        v-if="items.length"
        style="height: auto"
        :centerMode="false"
        :wheelControl="false"
        :infiniteScroll="false"
        ref="slideshowPost"
        @slide="getCurrenSlideIndex"
      )
        slide(
          v-for="(slide, index) in items"
          :key="index"
        )
          .uk-position-relative
            .mask(@click.stop="onShowVideo(slide.video_url)")
            .uk-background-cover(
              :style="{ backgroundImage: `url(${slide.img_url})`}"
            )
              a.play-icon.uk-position-center(
                v-if="slide.video_url"
                @click.stop="onShowVideo(slide.video_url)"
              )
                icon(type="video" width="90" height="63")
              .iframe-box.uk-position-top-left(
                v-if="!slide.img_url && slide.video_url"
                v-html="slide.video_url"
              )
      button.uk-button.uk-button-link.uk-position-center-left(
        v-if="items.length > 1"
        @click="slidePrevAll"
      )
        vk-icon.text-red.icon-bold(icon="chevron-left" ratio="2")
      button.uk-button.uk-button-link.uk-position-center-right(
        v-if="items.length > 1"
        @click="slideNextAll"
      )
        vk-icon.text-red.icon-bold(icon="chevron-right" ratio="2")

    hooper.slideshow-thumb(
      v-if="!hideThumb && items.length"
      style="height: auto"
      :centerMode="false"
      :wheelControl="false"
      :itemsToShow="5"
      :itemsToSlide="5"
      :infiniteScroll="false"
      ref="slideshowThumb"
    )
      slide(
        v-for="(slide, index) of items"
        :key="slide.slide_id"
      )
        .uk-position-relative
          a.uk-background-cover.uk-display-block.thumb(
            :id="`thumb${index}`"
            :style="{ backgroundImage: `url(${slide.img_url})`}"
            :class="{ active: index === 0 }"
            @click="updateSlideshowThumbActive"
            @click.prevent="slideTo(index)"
          )
            span.uk-position-center(
              v-if="slide.video_url"
              href="#"
            )
              icon(type="video" width="38" height="27")
</template>

<script>
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation
} from 'hooper';
import 'hooper/dist/hooper.css';
import { MODAL_EVENT } from '../../utils/events';

/*
interface IItem {
  slide_id: number;
  img_url: string;
  viedo_url: string;
}
*/
export default {
  name: 'SlideWithThumbs',
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
    hot: {type:Boolean, default:false,},
    trailerUrl: String,
    hideThumb: {
      type: Boolean,
      default: false,
    },
    showTrailer: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      carouselData: 0,
    };
  },
  created() {
  },
  methods: {
    onShowVideo(url) {
      if (!url) return;

      this.$event.$emit(MODAL_EVENT.SHOW_TRAILER, {
        videoUrl: url,
      });
    },
    slidePrevAll() {
      this.$refs.slideshowPost.slidePrev();
      if( this.carouselData%5 === 4 || this.carouselData === 4 ){
        this.$refs.slideshowThumb.slidePrev();
      }
      this.removeSlideshowThumbActive();
      document.querySelectorAll('.thumb')[this.carouselData].classList.add('active');
    },

    slideNextAll() {
      this.$refs.slideshowPost.slideNext();
      if( this.carouselData%5 === 0 ){
        this.$refs.slideshowThumb.slideNext();
      }
      this.removeSlideshowThumbActive();
      document.querySelectorAll('.thumb')[this.carouselData].classList.add('active');
    },

    // 點擊 thumbnail 跳到相對應的 slide
    slideTo(index) {
      this.$refs.slideshowPost.slideTo(index);
    },

    // 取得滑動後的 slide index
    getCurrenSlideIndex(payload) {
      this.carouselData = payload.currentSlide;
    },

    // 切換 thumbnail 的 class 狀態
    updateSlideshowThumbActive(e){
      this.removeSlideshowThumbActive();
      let thumbCurrent = e.target
      thumbCurrent.classList.add('active')
    },

    removeSlideshowThumbActive(){
      let thumb = document.querySelectorAll('.thumb')
      for (let index = 0; index < thumb.length; index++) {
        thumb[index].classList.remove('active')
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../../sass/helper/_variable.scss';

.btn-trailer {
  position: absolute;
  bottom: 0;
  right: 5px;
  z-index: 2;
  font-size: 15px;
  color:  $primary;
  cursor: pointer;
  display: flex;
  align-items: center;
}
</style>

<style lang="scss">
@import '../../../sass/helper/_variable.scss';

.slide-with-thumb-root {
  .hooper {
    .hooper-list {
      .mask {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      .iframe-box {
        width: 100%;
        height: 100%;
      }

      iframe {
        pointer-events: none;
      }
    }
  }
}

.play-icon {
  z-index: 1;
}
</style>
