<template lang="pug">
  div
    CataloguePost(
      v-for="post in posts"
      :key="post.post_id"
      :post="post"
    )
</template>

<script>
import CataloguePost from './CataloguePost.vue';

export default {
  components: {
    CataloguePost,
  },
  props: {
    posts: {
      type: Array,
      default: () => ([]),
    },
  },
}
</script>
