<template lang="pug">
  .uk-position-relative.post-wrapper-m(class="uk-hidden@m")
    .post-background(:style="{ backgroundImage: `url(${post.img_url})` }")
    .post-content.uk-width-4-5.uk-margin-xlarge-top
      .post-cover__img(:style="{ backgroundImage: `url(${post.img_url})` }")
      .content-wrapper
        .title-wapper
          .subtitle {{ post.title_en }}
          .title {{ post.title }}
        .desc(v-html="post.desc" style="margin-top: 10px;")
        a.uk-button.uk-button-primary.uk-button-large.uk-margin-top.text-thin(
          :href="post.link_url"
        )
          | {{ $t('READ_MORE') }}
          icon.icon-position-fixed.uk-margin-small-left(
            type="play-f"
            width="21"
            height="21"
          )
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '../../sass/helper/_variable.scss';
@import '../../sass/helper/_mixin.scss';

.post-wrapper-m {
  text-align: center;
  position: relative;
  background: #F8F8F8;
  // overflow: overlay;

  @include mq(960px) {
    min-height: 90vh;
  }

  .paginater {
    position: absolute;
    bottom: 50px;
    left: 50px;

    button {
      padding: 0 15px;
    }
  }

  .post-background {
    padding-top: 33%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    filter: blur(5px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .post-content {
    display: inline-block;
    text-align: left;
    position: relative;
    margin-bottom: 50px;
  }

  .post-cover__img {
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 70%;
  }

  .content-wrapper {
    position: relative;
    padding: 0 25px;
  }

  .title-wapper {
    background: #fff;
    padding: 15px;
    display: inline-block;

    @include mq(640px) {
      display: block;
      font-size: 28px;
    }
  }

  .title, .subtitle {
    font-size: 40px;
    color: $primary;
    font-weight: bold;

    @include mq(640px) {
      font-size: 28px;
    }
  }

  .desc {
    font-size: 16px;
    line-height: 32px;
    margin-top: 60px;

    @include mq(640px) {
      font-size: 14px;
      margin-top: 40px;
    }
  }

  .uk-button {
    border: none;
  }
}
</style>

