import { apiGetShopSearch } from '../apiRequest/shopping';

const SEARCH_FIELD = {
  type: 'type',
  publishDate: 'publish_date',
};

export const ShoppingSearch = {
  data() {
    return {
      searchOptions: {},
    };
  },
  async created() {
    Promise.all([
      this.getSearch({ params: { ...this.$route.query, field: SEARCH_FIELD.type } }),
      this.getSearch({ params: { ...this.$route.query, field: SEARCH_FIELD.publishDate } }),
    ]);
  },
  methods: {
    async getSearch(payload) {
      const res = await apiGetShopSearch(payload);
      this.searchOptions = {
        ...this.searchOptions,
        [payload.params.field]: res,
      };
    },
  },
  computed: {
    searchOptionSets() {
      return [
        {
          key: SEARCH_FIELD.type,
          label: this.$t('TYPE'),
          options: this.searchOptions[SEARCH_FIELD.type],
        },
        {
          key: SEARCH_FIELD.publishDate,
          label: this.$t('PUBLISH_DATE'),
          options: this.searchOptions[SEARCH_FIELD.publishDate]
        },
      ];
    },
  },
};
