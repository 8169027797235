<template lang="pug">
  .price-wrapper
    span.price(
      :class="{ strikethrough: hasPriceSale }"
    )
      //- span(v-if="$i18n.locale === 'ch'") NT
      //- | {{ $n(price, 'currency') }}
      | {{curr}} {{ price | currency }}
    span.price-sale(
      v-if="hasPriceSale"
    )
      //- span(v-if="$i18n.locale === 'ch'") NT
      //- | {{ $n(priceSale, 'currency') }}
      | {{curr}} {{ priceSale | currency }}
</template>

<script>
export default {
  props: {
    price: Number | String,
    priceSale: Number | String,
    curr: String,
  },
  computed: {
    hasPriceSale() {
      return !!this.priceSale;
    },
  },
  filters: {
    currency(price) {
      const priceNum = Number(price);
      return new Intl.NumberFormat().format(priceNum);
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../sass/helper/_variable.scss';

.price {
  color: #000;
}

.price-sale {
  color: $primary;
  font-weight: bold;
  margin-left: 10px;
  font-style: italic;
}

.strikethrough {
  position: relative;

  &::after {
    content: '';
    background: $primary;
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    top: 50%;
  }
}
</style>
