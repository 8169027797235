// require ('es6-promise').polyfill()

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use( Vuex )

import { modals } from './modules/modals';
import { windowSize } from './modules/windowSize';
import { auth } from './modules/auth';
import { slideshow } from './modules/slideshow';

export default new Vuex.Store({
  modules: {
    modals,
    windowSize,
    auth,
    slideshow,
  },
});
