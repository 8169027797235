<template lang="pug">
  p.uk-text-center.uk-margin-large-bottom
    a.uk-button.uk-button-primary.uk-button-large.uk-width-1-1.text-remove-spacing.text-medium(
      class="uk-width-1-3@m uk-width-1-2@s"
      @click="click"
    ) 
      | {{ $t('LOAD_MORE') }} &nbsp;
      icon.icon-arrow-down(type="arrow-down" width="10" height="16")
</template>

<script>
export default {
  name: 'ButtonLoadMore',
  methods: {
    click() {
      this.$emit('click');
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-arrow-down {
  filter: grayscale(1) brightness(8);
}
</style>
