<template lang="pug">
  .uk-position-relative.home-banner
    SlideShowVideo(
      v-if="banner.video_url"
      :videoUrl="banner.video_url"
      :imgUrl="banner.img_url"
    )
    .uk-background-cover(:style="{ backgroundImage: `url(${banner.img_url})` }")
    .uk-position-center.uk-text-center.uk-padding.content(class="uk-width-2-3@m uk-width-1-1")
      .title {{ banner.title }}
      .subtitle {{ banner.title_en }}
      .desc.uk-width-3-5(
        class="uk-visible@s"
        v-html="banner.desc"
      )
      p
        .button-wrapper
          a.uk-button.uk-button-primary.uk-button-large.text-thin.read-btn(
            v-if="banner.link_url"
            :href="banner.link_url"
          )
            | {{ $t('READ_MORE') }}
            vk-icon(icon="chevron-right")
          ButtonWatchTrailer.btn-trailer-lg(
            v-if="banner.trailer_url"
            @click="showTrailer"
          )
      ButtonWatchTrailer.btn-trailer-m(
        v-if="banner.trailer_url"
        @click="showTrailer"
      )
    .scroll-hint {{ $t('SCROLL_DOWN') }}
</template>

<script>
import ButtonWatchTrailer from '../components/buttons/ButtonWatchTrailer.vue';
import SlideShowVideo from '../components/commons/SlideShowVideo.vue';
import { MODAL_EVENT } from '../utils/events';

/*
interface IBanner {
  id: 0;
  img_url: string;
  title: string;
  title_en: string;
  desc: string;
  link_url: string;
  trailer_url: string;
}
*/

export default {
  name: 'HomeBanner',
  components: {
    ButtonWatchTrailer,
    SlideShowVideo,
  },
  props: {
    banner: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onClick() {
      this.$router.push({
        name: 'catalogueSingle',
          params: { id: banner.id }
      });
    },

    showTrailer() {
      this.$event.$emit(MODAL_EVENT.SHOW_TRAILER, {
        videoUrl: this.banner.trailer_url,
      });
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_variable.scss';
@import '../../sass/helper/_mixin.scss';

.title {
  font-size: 50px;
  color: #fff;
  font-weight: bold;
  letter-spacing: 5px;
}

.subtitle {
  font-size: 26px;
  color: #fff;
  font-weight: bold;
  letter-spacing: 2.6px;
}

.desc {
  font-size: 22px;
  color: #fff;
  display: inline-block;
  letter-spacing: 6.6px;
}

.uk-background-cover {
  @include mq(960px) {
    padding-top: 100vh !important;
  }
}

.scroll-hint {
  transform: translate(-50%, -50%) rotateZ(90deg);
  position: absolute;
  color: #fff;
  border-bottom: 1px solid $primary;
  padding: 5px 15px;
  bottom: 30px;
  left: 50%;
  font-size: 12px;

  &::before {
    content: '';
    width: 7px;
    height: 7px;
    background: $primary;
    display: inline-block;
    position: absolute;
    bottom: -4px;
    left: -5px;
  }
}

.content {
  width: 100%;
}

.home-banner {
  .button-wrapper {
    position: relative;
    .read-btn {
      svg {
        filter: grayscale(1) brightness(8);
      }
    }

    .btn-trailer-lg {
      position: absolute;
      top: 50%;
      transform: translate(20px, -50%);

      @include mq(960px) {
        display: none;
      }
    }
  }

  .btn-trailer-m {
    display: none;

    @include mq(960px) {
      display: inline-flex;
    }
  }
}

.read-btn {
  display: inline-flex;
  align-items: center;
}

.iframe-wrapper {
  width: 100%;
  height: 100%;

  .cover {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  iframe {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}
</style>
