<template lang="pug">
  Loading(v-if="isLoading")
  .uk-container.layout.uk-margin-large-top.shopping-list(v-else)
    ul.uk-breadcrumb(class="uk-visible@s")
      li
        router-link(:to="{ name: 'home' }")
          icon.icon-position-fixed.uk-margin-small-right(type="home" width="15" height="12")
          | {{ $t('HOME') }}
      li
        router-link(:to="{ name: 'shoppingHome' }") {{ $t('SHOPPING') }}
      li {{ $t('SEARCH_RESULT') }}

    SearchForm(
      :optionSets="searchOptionSets"
      v-model="searchForm"
      @onSearch="onSearch"
    )

    .uk-margin-large-top
      h1.uk-heading-line.uk-text-center.text-red
        span(v-html="$t('SEARCH_RESULT_TITLE', { count: posts.length, filter: oldSearchForm.keyword })")

      vk-grid(class="uk-child-width-1-4@m uk-child-width-1-3@s uk-child-width-1-2")
        ShoppingPostCard(
          v-for="post in posts"
          :key="post.post_id"
          :post="post"
        )

      ButtonLoadMore(
        v-if="!noMore"
        @click="getMorePost"
      )
</template>
<script>
import { shoppingPosts } from '../mocks/shoppingPosts';

import { ShoppingSearch } from '../mixins/ShoppingSearch';
import { ListMixin } from '../mixins/ListMixin';
import { apiGetShopList } from '../apiRequest/shopping';

import SearchForm from '../components/SearchForm.vue';
import ShoppingPostCard from '../components/ShoppingPostCard.vue';
import ButtonLoadMore from '../components/buttons/ButtonLoadMore.vue';

export default {
  name: 'ShoppingList',
  mixins: [ ShoppingSearch, ListMixin ],
  components: {
    SearchForm,
    ShoppingPostCard,
    ButtonLoadMore,
  },
  data () {
    return {
      posts: [],
      searchForm: {},
      oldSearchForm: {},
      noMore: false,
      isLoading: false,
    };
  },
  async created() {
    try {
      this.isLoading = true;
      console.log('G$$$', this.init);
      await this.init(this.$route.query);
    } finally {
      this.isLoading = false;
    }
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    this.init(to.query);
  },
  methods: {
    async getPosts(payload) {
      try {
        const res = await apiGetShopList(payload);
        if (!res) return [];

        this.noMore = !res.length;
        return res;
      } catch (e) {
        console.log(e);
        this.noMore = true;
        return [];
      }
    },
  },
  head: {
    title: {
      inner: ''
    },
  },
}
</script>
