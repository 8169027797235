export default {
  // Home, Nav, Footer, Utils
  HOME: '首页',
  CATALOGUE: '影片',
  CHANNEL: '频道',
  SHOPPING: '购物',
  EVENTS: '活动',
  POSTS: '文章',
  RELEASE: '发布',
  FOUNDATION: '基金会',
  SIGN_IN: '登入',
  LANG: '语言',
  DOWNLOAD: '下载',
  ABOUT: '关于',
  CONTACT_US: '联络我们',
  TERMS_AND_CONDITION: '隐私政策',
  PRIVACY: 'Cookie 政策',
  SCROLL_DOWN: 'SCROLL DOWN',
  READ_MORE: 'READ MORE',
  VIEW_ALL: 'View All',
  LOAD_MORE: 'Load More',
  COPYRIGHT: '© 2019 CNEX Studio Corp. All Rights reserved.',
  SLOGAN_1: '真‧见‧识‧无限可能',
  SLOGAN_2: 'Integrity‧Vision‧Knowledge‧Possibilities',
  SEARCH: '搜寻',
  SEARCH_RESULT: '搜寻结果',
  SEARCH_RESULT_TITLE: '{count} <i class="text-xxlarge">Results for</i> {filter}',
  STATUS: '状态',
  GENRE: '类型',
  COUNTRY: '出品国/地区',
  KEYWORDS: '关键字',
  PRODUCTION: '製片公司',
  COMPANY: '出品公司',
  DIRECTOR: '导演',
  TYPE: '种类',
  QUALITY: '画质',
  PUBLISH_DATE: '出品日期',
  COMMENT: '留言评论',
  MONDAY: '星期一',
  TUESDAY: '星期二',
  WEDNESDAY: '星期三',
  THURSDAY: '星期四',
  FRIDAY: '星期五',
  SATURDAY: '星期六',
  SUNDAY: '星期日',
  MONDAY_SHORT: '一',
  TUESDAY_SHORT: '二',
  WEDNESDAY_SHORT: '三',
  THURSDAY_SHORT: '四',
  FRIDAY_SHORT: '五',
  SATURDAY_SHORT: '六',
  SUNDAY_SHORT: '日',
  YEAR: '年',
  MONTH: '月',
  DATE: '日',
  AWARD: '得奖纪录',
  RUN_TIME: '片长',
  SERIAL_NUMBER: '产品编号',
  SIGN_UP_FREE_SHIPPING_FEE: '立即加入会员 享有免运费优惠',
  RELATED_PRODUCT: '相关商品',
  LAST_VIDEOS: '最新影片',
  FEATURES: '编辑精选',
  EMAIL: '电子邮件',
  USERNAME: '使用者名称',
  COMMON_PROBLEM: '常见问题',
  PASSWORD: '密码',
  CONFIRM_PASSWORD: '确认密码',
  OR: '或',
  LOGIN: '登入',
  SIGN_UP: '立即注册',
  WATCH_TRAILER: 'Watch Trailer',
  NOW_MOD: 'CNEX纪实频道 (中华电信MOD第600台)',
  NOW_PLAYING: 'Now Playing',
  LANGUAGE: '语言',
  LANG_EN: 'ENGLISH',
  LANG_CH: '繁体中文',
  LANG_CN: '简体中文',
  FINISHED_VIDEO: '完成影片',
  PROGRESS_VIDEO: '制作中影片',
  AGENT_VIDEO: '代理影片',
  TAG: '标签',
  SHARE_BY_LINE: '用LINE传送',
  SHARE_BY_TWEET: 'Tweet',
  SHARE_BY_FB: '分享',
  SHARE_BY_WECHAT: 'WECHAT分享',
  FB_LIKE: '赞',
  FB_SHARE: '分享',
  TAIPEI: '台北',
  BEIJING: '北京',
  HONG_KONG: '香港',
  SITE:'区域',
  SITE_TW: '台北',
  SITE_CH: '北京',
  SITE_HK: '香港',

  // Catalogue Home
  CATA_FEATURES: '推荐影片',
  CATA_PICK_UP: '<i class="text-white">P</i>ICK&nbsp;<i class="text-white">U</i>P',

  // Catalogue Single
  CATA_INFO_TITLE: '片名',
  CATA_VIDEO_OFFICIAL_SITE: '影片官方网站',
  CATA_LICENSE: '取得公播授权',
  CATA_LICENSE_DESCRIPTION: '取得公播授权',
  CATA_VIDEO_AGENT: '影片代理厂商',
  CATA_VIDEO_AGENT: '影片代理厂商',
  CATA_TVOD: '随选影片平台',
  CATA_INTRODUCTION: '影片介绍',
  PLACE_OF_ORIGIN: '出品国/地区',
  CATA_TEAM: '主创团队',
  CATA_STATUS: '来源与状态',
  CATA_SEARCH_STATUS: '来源与状态',

  // Channel Home
  CHANNEL_FEATURES: '编辑精选',
  CHANNEL_PROGRAM_LIST: '节目表',
  CHANNEL_MONTH_UNIT: '月',
  CHANNEL_DATE_UNIT: '日',

  // Channel Single
  CHANNEL_INTRODUCTION: '影片介绍',
  CHANNEL_PLAY_DATE: '播放时间',

  // Shopping Home
  SHOP_LATEST: '最新上架',
  SHOP_HOT: '热卖商品',
  SHOP_ADD_TO_CART: '加入购物车',
  SHOP_NOT_YET_SOLD: '尚未开卖',
  SHOP_PRODUCT_AGENT_SITE: '商品代销网站',
  SHOP_PRODUCT_DESC: '商品描述',
  SHOP_SPEC: '尺寸规格',
  SHOP_PURCHASE_NOTE: '购买须知',
  SHOP_OFFICAL_SITE: '产品官网连结',

  SHOP_TITLE: '商品名称',
  SHOP_RELEASE: '發行日期',
  SHOP_SERIAL: '参考编号',

    // Post
  POST_PREV: 'PREV | 上一篇',
  POST_NEXT: 'NEXT | 下一篇',
  POST_DATE: '文章日期',
  LAST_POST: '最新文章',
  POST_FTU: '编辑精选',
  POST_SRC: '文章来源：',
  POST_ORG: '原创',

  // Event
  EVENT_LATEST_EVENT: '最新活动',
  EVENT_NOW_ON_SALE: 'NOW ON SALE',
  EVENT_COMMING_SOON: 'COMMING SOON',
  EVENT_INTRODUTION: '活动说明',
  EVENT_MAP: '地图位置',
  EVENT_APPLY: '报名 / 售票',
  EVENT_OTHER_SITE: '其他代理售票网站',
  EVENT_APPLY_DATE: '报名日期',
  EVENT_EVENT_DATE: '活动日期',
  EVENT_SOLD_OUT: '已结束',
  EVENT_ACTIVING: '活动进行中',
  EVENT_NOT_YET_START: '尚未开始',
  EVENT_DATE_TO: '至',

  // Memter
  MEMBER_CENTRE: '会员中心',
  MEMBER_CHANGE_PASSWORD: '变更新密码',
  MEMBER_CONFIRM_PASSWORD: '确认变更新密码',
  MEMBER_CONFIRM: '确认修改',
  MEMBER_CANCEL: '取消变更',
  MEMBER_SUBSCRIBE_PAPER: '订阅电子报',

  // Contact Us
  CONTACT_NAME: '姓名',
  CONTACT_TITLE: '标题',
  CONTACT_CONTENT: '内容',
  CONTACT_CONFIRM: '确认送出',
  CONTACT_SUCCESS: '您的问题已成功送出，我们将有专人尽速与您联系！ ',
  CONTACT_FAIL: '系统出现异常，请稍候再试',

  // Download
  FILE_DOWNLOAD: '档案下载',
  NO_MORE_FILE: 'No More File',
  UPLOAD_DATE: '上传日期',

  // Login
  LOGIN_MEMBER_SIGN_IN: '会 员 登 入',
  LOGIN_USE_SOCIAL_MEDIA_SIGN_IN: '使用以下社群帐号登入',
  LOGIN_FACEBOOK_SIGN_IN: 'Facebook 登入',
  LOGIN_GOOGLE_SIGN_IN: 'Google 登入',
  LOGIN_NOT_MEMBER_YET: '还不是会员？ ',
  LOGIN_SIGN_UP_NOW: '立即注册',
  LOGIN_EVEN_BEEN_MEMBER: '我曾经注册过，但我',
  LOGIN_FORGET_PASSWORD: '忘记密码',
  LOGIN_JOIN_MEMBER: '加入会员',
  LOGIN_SIGNED_UP: '我已经注册过，',
  LOGIN_LOGIN_DIRECTLY: '直接登入',
  LOGIN_ENTER_EMAIL: '请输入您的注册过的Email',
  LOGIN_RETRIEVE_PASSWORD: '索取新密码',
  LOGIN_TERMS_AND_CONDITION: '隐私权政策',
  LOGIN_FAILED_MSG: '登入资讯错误',

  // Validator
  VALIDATOR_MSG_CONFIRM_PASSWORD: '确认密码 与 密码 不符',

  // FAQ
  FAQ: '常 见 问 题',
  FAQ_CA: '问题分类 :',
  FAQ_0: '全部',
  FAQ_A: '会员',
  FAQ_B: '影片(發行)',
  FAQ_C: '频道(节目表)',
  FAQ_D: '购物',
  FAQ_E: '文章',
  FAQ_F: '活动',
  FAQ_G: '网站(操作)',
  FAQ_H: '基金会',
  FAQ_I: '其他',
};
