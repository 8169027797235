import { apiGetPostSearch } from '../apiRequest/posts';

const SEARCH_FIELD = {
  tag: 'tag',
  publishDate: 'publish_date',
  year: 'year'
};

export const PostSearch = {
  data() {
    return {
      searchOptions: {},
    };
  },
  async created() {
      Promise.all([
      this.getSearch({ params: {...this.$route.query, field: SEARCH_FIELD.tag } }),
      this.getSearch({ params: {...this.$route.query, field: SEARCH_FIELD.publishDate } }),
    ]);
  },
  methods: {
    async getSearch(payload) {
      const res = await apiGetPostSearch(payload);
      this.searchOptions = {
        ...this.searchOptions,
        [payload.params.field]: res,
      };
    },
  },
  computed: {
    searchOptionSets() {
      return [
        {
          key: SEARCH_FIELD.tag,
          label: this.$t('TAG'),
          options: this.searchOptions[SEARCH_FIELD.tag]
        },
        {
          key: SEARCH_FIELD.year,
          label: this.$t('POST_DATE'),
          options: this.searchOptions[SEARCH_FIELD.publishDate]
        },
      ];
    },
  },
};
