<template lang="pug">
  .float-input
    label(
      :class="{ float: labelFloat }"
    )
      span.required(v-if="required") *
      span.label {{ label }}
    input.uk-input.uk-form-small.input-without-border(
      v-if="type === 'input'"
      type="text"
      v-model.trim="inputValue"
      @focus="onFocus"
      @blur="onBlur"
    )
    textarea.uk-input.uk-form-small.input-without-border(
      v-else-if="type === 'textarea'"
      type="text"
      :rows="rows"
      v-model.trim="inputValue"
      @focus="onFocus"
      @blur="onBlur"
    )
    .uk-position-relative(
      v-else-if="type === 'select'"
    )
      select.uk-select.uk-form-small.input-without-border(
        :class="{ valued: inputValue }"
        v-model="inputValue"
        @focus="onFocus"
        @blur="onBlur"
      )
        slot(name="options")
      icon.icon-select.uk-position-center-right.uk-position-small(
        type="select2"
        width="10"
        height="10"
      )
</template>

<script>
export default {
  props: {
    value: {
      type: String | Number,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'input',
    },
    rows: {
      type: String,
      default: '5',
    },
  },
  data() {
    return {
      isFocus: false,
    };
  },
  methods: {
    onFocus() {
      this.isFocus = true;
    },

    onBlur() {
      this.isFocus = false;
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v.trim());
      },
    },
    labelFloat() {
      return this.value || this.isFocus;
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../sass/helper/_variable.scss';

.float-input {
  position: relative;
}

label {
  position: absolute;
  top: 0px;
  font-size: 14px;
  transition: top .1s 0s ease-in-out;
  z-index: 1;

  &.float {
    top: -22px;
    color: $primary;
  }

  .required {
    color: $primary;
  }
}

input, textarea {
  &:active, &:focus {
    border-bottom: 1px solid $primary;
  }
}

.uk-select {
  padding-top: 27px;

  &.valued {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 39px !important;
  }

  &:not([multiple]):not([size]):focus {
    background-color: transparent;
  }
}

.icon-select {
  top: 8px;
}
</style>
