<template lang="pug">
  Loading(v-if="isLoading")
  .home-page.shopping-home(v-else)
    //- <TopSlide>
    Hooper.slideshow(
      v-if="banners.length"
      style="height: auto"
      :centerMode="true"
      :wheelControl="false"
      :infiniteScroll="true"
      :playSpeed="bannerSlideSpeed"
      autoPlay
    )
      Slide(v-for="banner in banners" :key="banner.id")
        a(:href="banner.link_url")
          .uk-position-relative.banner
            .uk-background-cover(:style="{ backgroundImage: `url(${banner.img_url})` }")
      HooperNavigation(slot="hooper-addons")
      HooperPagination(slot="hooper-addons" class="uk-visible@m")

    .uk-container.layout
      SearchForm(
        :optionSets="searchOptionSets"
        v-model="searchForm"
        @onSearch="onSearch"
      )

      .uk-margin-large-top
        h1.uk-heading-line.uk-text-center.text-red
          span {{ $t('SHOP_LATEST') }}

        vk-grid(class="uk-child-width-1-4@m uk-child-width-1-3@s uk-child-width-1-2")
          ShoppingPostCard(
            v-for="post in latestPosts"
            :key="post.post_id"
            :post="post"
          )
        p.uk-padding.uk-text-center
          router-link.uk-button.uk-button-default.uk-button-large.uk-width-1-1.text-remove-spacing.text-medium(
            :to="{ name: 'shoppingList' }"
            class="uk-width-1-3@m uk-width-1-2@s"
          )
            | {{ $t('VIEW_ALL') }}
            icon.icon-position-fixed.uk-margin-small-left(
              type="arrow-right"
              width="7"
              height="11"
              style="top: -1px"
            )

        .uk-margin-large-top
        h1.uk-heading-line.uk-text-center.text-red
          span {{ $t('SHOP_HOT') }}

        vk-grid(class="uk-child-width-1-4@m uk-child-width-1-3@s uk-child-width-1-2")
          ShoppingPostCard(
            v-for="post in hotPosts"
            :key="post.post_id"
            :post="post"
          )

        p.uk-padding.uk-text-center
          router-link.uk-button.uk-button-default.uk-button-large.uk-width-1-1.text-remove-spacing.text-medium(
            :to="{ name: 'shoppingList' }"
            class="uk-width-1-3@m uk-width-1-2@s"
          )
            | {{ $t('VIEW_ALL') }}
            icon.icon-position-fixed.uk-margin-small-left(
              type="arrow-right"
              width="7"
              height="11"
              style="top: -1px"
            )

        .uk-margin-large-top
</template>
<script>
import {
  Hooper,
  Slide,
  Pagination as HooperPagination,
  Navigation as HooperNavigation
} from 'hooper';
import 'hooper/dist/hooper.css';

import { shoppingBanners } from '../mocks/shoppingBanners';
import { shoppingPosts } from '../mocks/shoppingPosts';

import { ShoppingSearch } from '../mixins/ShoppingSearch';
import {
  apiGetShopSlideshows,
  apiGetShopList,
} from '../apiRequest/shopping';

import SearchForm from '../components/SearchForm.vue'
import ButtonViewAll from '../components/buttons/ButtonViewAll.vue';
import ShoppingPostCard from '../components/ShoppingPostCard.vue';

export default {
  name: 'ShoppingHome',
  mixins: [ShoppingSearch],
  components: {
    Hooper,
    Slide,
    HooperPagination,
    HooperNavigation,
    SearchForm,
    ButtonViewAll,
    ShoppingPostCard,
  },
  data () {
    return {
      banners: [],
      latestPosts: [],
      hotPosts: [],

      searchForm: {},
      isLoading: false,
    }
  },
  async created() {
    try {
      this.isLoading = true;
      await Promise.all([
        this.getBanners({ params: { ...this.$route.query } }),
        this.getHotPosts(this.$route.query),
        this.getLatestPosts(this.$route.query),
      ]);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    onSearch() {
      this.$router.push({
        name: 'shoppingList',
        query: this.searchForm
      });
    },
    async getBanners(payload) {
      const res = await apiGetShopSlideshows(payload);
      this.banners = res;
    },
    async getHotPosts(params) {
      const res = await apiGetShopList({
        params: {
          ...params,
          hot: true
        },
      });
      this.hotPosts = res;
    },
    async getLatestPosts(params) {
      const res = await apiGetShopList({
        params: {
          ...params,
          publish: 'latest'
        },
      });
      this.latestPosts = res;
    },
  },
  computed: {
    bannerSlideSpeed() {
      return this.$store.getters['slideshow/playSpeed']('seconds_shopping');
    },
  },
  head: {
    title: {
      inner: ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_mixin.scss';

</style>

<style lang="scss">
.shopping-home > .slideshow {
  .hooper-track li .uk-position-relative:before {
    background-color: transparent;
  }
}
</style>
