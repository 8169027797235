<template lang="pug">
  p.uk-padding.uk-text-center
    a.uk-button.uk-button-default.uk-button-large.uk-width-1-1.text-remove-spacing.text-medium(
      class="uk-width-1-3@m uk-width-1-2@s"
      @click="click"
    ) 
      | {{ transText }}
      icon.icon-position-fixed.uk-margin-small-left(
        type="arrow-right"
        width="7"
        height="11"
        style="top: -1px"
      )
</template>

<script>
export default {
  name: 'ButtonViewAll',
  props: {
    text: {
      type: String,
    },
  },
  methods: {
    click() {
      this.$emit('click');
    },
  },
  computed: {
    transText() {
      return this.text || this.$t('VIEW_ALL');
    },
  },
}
</script>
