const basePayload = {
  meta: {
    baseUrl: 'https://erp.cnex.com.tw/api',
  },
};

function mergeMeta(payload = { meta: {} }) {
  const meta = {
    ...basePayload.meta,
    ...payload.meta
  };
  const newPayload = {
    ...payload,
    meta,
  };
  return newPayload;
}

export async function apiGetChannelSlideshows(payload) {
  return await axios.get(
    `slideshow/channel/home`,
    payload,
  );
}

export async function apiGetChannleNowPlaying(payload) {
  return await axios.get(
    `channel/nowplaying`,
    mergeMeta(payload),
  );
}

export async function apiGetChannelFeature(payload) {
  return await axios.get(
    `channel/next`,
    mergeMeta(payload),
  );
}

export async function apiGetChannelList(payload) {
  return await axios.get(
    `channel/list`,
    mergeMeta(payload),
  );
}

export async function apiGetChannelSearch(payload) {
  return await axios.get(
    `channel/search`,
    mergeMeta(payload),
  );
}

export async function apiGetChannelSingle(id, payload) {
  return await axios.get(
    `channel/single/${id}`,
    mergeMeta(payload),
  );
}
