const defultPayload = {
  meta: {
    lang: 'ch',
  },
};

/**
 * 
 * @param body.params.username required
 * @param body.params.email required
 * @param body.params.password required
 */
export async function apiSignup(body, payload = defultPayload) {
  return await axios.post(
    `user/signup`,
    body,
    payload,
  );
}

/**
 * 
 * @param body.params.username required
 * @param body.params.email
 * @param body.params.fbid required
 */
export async function apiSignupFb(body, payload = defultPayload) {
  return await axios.post(
    `user/signup/fb`,
    body,
    payload,
  );
}

/**
 * 
 * @param body.params.username required
 * @param body.params.email
 * @param body.params.gid required
 */
export async function apiSignupGoogle(body, payload = defultPayload) {
  return await axios.post(
    `user/signup/google`,
    body,
    payload,
  );
}

/**
 * 
 * @param body.params.email required
 * @param body.params.password required
 */
export async function apiLogin(body, payload = defultPayload) {
  return await axios.post(
    `user/login`,
    body,
    payload,
  );
}

/**
 * 
 * @param body.params.fbid required
 */
export async function apiLoginFb(body, payload = defultPayload) {
  return await axios.post(
    `user/signup/fb`,
    body,
    payload,
  );
}

/**
 * 
 * @param body.params.gid required
 */
export async function apiLoginGoogle(body, payload = defultPayload) {
  return await axios.post(
    `user/signup/google`,
    body,
    payload,
  );
}

/**
 * 
 * @param body.params.email required
 */
export async function apiForgetPassword(body, payload = defultPayload) {
  return await axios.post(
    `user/password`,
    body,
    payload,
  );
}

/**
 * @param id user id
 * @param body.params.username
 * @param body.params.password
 * @param body.params.subscription boolean
 */
export async function apiUpdateUser(id, body, payload = defultPayload) {
  return await axios.post(
    `user/update/${id}`,
    body,
    payload,
  );
}

export async function apiGetUser(id, payload = defultPayload) {
  return await axios.get(
    `user/get/${id}`,
    payload,
  );
}

export async function apiContactUs(body, payload = defultPayload) {
  return await axios.post(
    `contact`,
    body,
    payload,
  );
}
