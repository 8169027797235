<template lang="pug">
  a.uk-position-relative.link-no-underline.link-card.post-panel(
    @click="clickLink"
  )
    div.img-col(class='uk-width-1-3')
      .uk-background-cover(:style='{ backgroundImage: `url(${post.img_url})` }')
        span.uk-position-center.text-small.text-white READ MORE
    div.detail-col
      .date
        span
          icon(
            type="calendar"
            width="15"
            height="15"
          )
          span.uk-margin-small-left {{ post.publish_date }}
        span.uk-margin-left
          icon(
            type="layer"
            width="15"
            height="15"
          )
          span.uk-margin-small-left {{ post.category }}
      .title {{ post.title }}
      .desc(v-html="desc")
      .tags
        button.uk-button-default.tag(
          v-for="tag in tags"
          :key="tag"
          @click.stop="onClickTag(tag)"
        ) {{ tag }}
</template>

<script>
/*
interface IPost {
  post_id: number;
  img_url: string;
  publish_date: string;
  title: string;
  desc: string;
  tag: string;
}
*/

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  methods: {
    clickLink() {
      this.$router.push({
        name: 'postsSingle',
        params: { id: this.post.post_id }
      });
    },
    onClickTag(tag) {
      if (this.$route.name === 'postsList') {
        this.$router.replace({
          query: { tag },
        });
      } else {
        this.$router.push({
          name: 'postsList',
          query: { tag },
        });
      }
    },
  },
  computed: {
    tags() {
      return this.post.tag.split(',');
    },
    desc() {
      const maxLen = 100;
      return this.post.desc.length > maxLen
        ? `${this.post.desc.slice(0, maxLen)}...`
        : this.post.desc;
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_variable.scss';
@import '../../sass/helper/_mixin.scss';

.post-panel {
  display: flex;
  margin-bottom: 30px;
  color: #000;
}

.img-col {
  margin-right: 10px;
  flex-shrink: 0;
  .uk-background-cover {
    padding-top: 70%;
  }
}

.detail-col {
  > div {
    margin-bottom: 10px;
  }

  .title {
    font-weight: bold;
    font-size: 26px;
    line-height: 40px;

    @include mq(960px) {
      font-size: 18px;
      line-height: 30px;
    }

    @include mq(640px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .date {
    font-size: 16px;
    color: $primary;

    @include mq(640px) {
      font-size: 12px;
    }

    span {
      display: inline-flex;
      align-items: center;
    }
  }

  .desc {
    font-size: 14px;
    color: #646464;
    line-height: 25px;

    @include mq(960px) {
      display: none;
    }

    * {
      margin: 0;
    }
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    > button {
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
}
</style>

