<template lang='pug'>
    div.uk-width-1-1.nav-root(style='z-index: 25')
        #headerTop(class='uk-visible@m')
            vk-sticky.uk-animation-toggle(:top="100")
                div
                    vk-navbar.navbar
                        vk-navbar-nav
                            h1.logo
                                router-link(to='/')
                                    icon(type='icon-logo', width='178')
                            h2.logo-w
                                router-link(to='/')
                                    icon(type='icon-logo-w', width='178')
                        vk-navbar-nav.uk-flex-middle.nav-menu(slot='right')
                            li
                                router-link(
                                    :class="{ active: routeActive('about') }"
                                    :to='{ name: "about" }'
                                ) {{ $t('ABOUT') | uppercase }} CNEX
                            li
                                router-link(
                                    :class="{ active: routeActive('catalogue') }"
                                    :to='{ name: "catalogueHome" }'
                                ) {{ $t('CATALOGUE') | uppercase }}
                            li
                                router-link(
                                    :class="{ active: routeActive('channel') }"
                                    :to='{ name: "channelHome" }'
                                ) {{ $t('CHANNEL') | uppercase }}
                            li
                                router-link(
                                    :class="{ active: routeActive('shop') }"
                                    :to='{ name: "shoppingHome" }'
                                ) {{ $t('SHOPPING') | uppercase }}
                            li
                                router-link(
                                    :class="{ active: routeActive('post') }"
                                    :to='{ name: "postsHome" }'
                                ) {{ $t('POSTS') | uppercase }}
                            li
                                router-link(
                                    :class="{ active: routeActive('event') }"
                                    :to='{ name: "eventsHome" }'
                                ) {{ $t('EVENTS') | uppercase }}
                            //- li
                            //-   a.release-wrapper(:class="{ active: routeActive('posts') || routeActive('events') }")
                            //-     span {{ $t('RELEASE') | uppercase }}
                            //-     vk-drop(
                            //-       position="bottom-center"
                            //-       :offset="40"
                            //-       :delay-hide="100"
                            //-     )
                            //-       vk-card
                            //-         .dropdown-item(
                            //-           v-for="i in releaseItems"
                            //-           :key="i.id"
                            //-           @click="toRelease(i.id)"
                            //-         ) {{ $t(i.label) }}
                            li
                                a.release-wrapper
                                    span {{ $t('FOUNDATION') | uppercase }}
                                    vk-drop(
                                        position="bottom-center"
                                        :offset="40"
                                        :delay-hide="100"
                                    )
                                        vk-card
                                            .dropdown-item(
                                                v-for="i in foundationItems"
                                                :key="i.id"
                                                @click="toFoundation(i.url)"
                                            ) {{ $t(i.label) }}
                                                //- a.foundation-link(
                                                //-   target="_blank"
                                                //-   :href="i.url"
                                                //- ) {{ $t(i.label) }}
                            li.navbar-divider
                                span.text-red |
                            li(v-if="isLogined")
                                router-link.link-no-underline(
                                    :class="{ active: routeActive('userinfo') }"
                                    :to="{ name: 'accountUserInfo' }"
                                    :title="userName"
                                )
                                    vk-icon(icon="user" ratio="1.2")
                            li(v-else)
                                a.link-no-underline(@click='modalLoginToggle')
                                    icon.margin-xsmall-left(type='signin', width='21')
                            li
                                a.link-no-underline
                                    icon.margin-xsmall-left(type='global', width='22')
                                    vk-drop(
                                        position="bottom-center"
                                        :offset="30"
                                        :delay-hide="100"
                                    )
                                        vk-card
                                            .dropdown-item(
                                                v-for="site in sites"
                                                :key="site.id"
                                                :class="{ active: site.id === nowSite }"
                                                @click="onChangeSite(site.id)"
                                            ) {{ $t(site.label) }}
                            li
                                a.link-no-underline
                                    icon.margin-xsmall-left(type='lang', width='22')
                                    vk-drop(
                                        position="bottom-center"
                                        :offset="30"
                                        :delay-hide="100"
                                    )
                                        vk-card
                                            .dropdown-item(
                                                v-for="lang in langs"
                                                :key="lang.id"
                                                :class="{ active: lang.id === nowLang }"
                                                @click="onChangeLang(lang.id)"
                                            ) {{ $t(lang.label) }}
                            li
                                router-link.link-no-underline(:to='{ name: "download" }')
                                    icon.margin-xsmall-left(type='download', width='26')
        .header-mobile(class='uk-hidden@m')
            vk-sticky
                div
                    h1.logo(class='uk-visible@s')
                        router-link(to='/')
                            icon(type='icon-logo', width='178')
                    h1.logo-s.uk-margin-remove(class='uk-hidden@s')
                        router-link(to='/')
                            icon(type='icon-logo-square', width='60' height='60')
                    .uk-position-top-right.trigger
                        a(@click='modalMobileNavToggle')
                            icon(type='trigger' width='80' height='80')
        .outline
            i.top
            i.bottom
            i.left
            i.right

</template>

<script>
    import { LANG_OPTIONS, nowLang } from '../utils/locales';
    import { FOUNDATION } from '../utils/foundations';
    import { urlAddLang } from '@/js/utils/helpers';
    import { urlAddSite } from '@/js/utils/helpers';
    import { SITE_OPTIONS, nowSite } from '../utils/sites';

    export default {
        name: 'HeaderTop',
        data () {
            return {
                nowLang,
                nowSite,
                FOUNDATION,
                langs: LANG_OPTIONS,
                sites: SITE_OPTIONS,
                releaseItems: [
                    {
                        id: 'postsHome',
                        label: 'POSTS',
                    },
                    {
                        id: 'eventsHome',
                        label: 'EVENTS',
                    },
                ],
                foundationItems: [
                    {
                        id: 'url1',
                        label: 'TAIPEI',
                        url: FOUNDATION.TW,
                    },
                    {
                        id: 'url2',
                        label: 'BEIJING',
                        url: FOUNDATION.CN,
                    },
                    {
                        id: 'url3',
                        label: 'HONG_KONG',
                        url: FOUNDATION.HK,
                    },
                ],
            };
        },
        methods: {
            modalLoginToggle() {
                this.$store.commit('modalLoginToggle');
            },
            modalMobileNavToggle() {
                this.$store.commit('modalMobileNavToggle');
            },
            modalSignUpToggle() {
                this.$store.commit('modalSignUpToggle')
            },
            routeActive(type) {
                return this.$route.path.includes(type);
            },
            onChangeSite(site) {
                window.localStorage.setItem('site', site);
                urlAddSite({ site });
            },
            onChangeLang(lang) {
                window.localStorage.setItem('locale', lang);
                urlAddLang({ lang });
            },
            toRelease(name) {
                this.$router.push({ name });
            },
            toFoundation(url) {
                window.open(url, '_blank');
            },
        },
        computed: {
            isLogined() {
                return this.$store.state.auth.id;
            },
            userName() {
                return (this.$store.state.auth.info && this.$store.state.auth.info.username)
                    || '';
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../sass/helper/_variable.scss';
    @import '../../sass/helper/_mixin.scss';

    .nav-root {
        @include mq(960) {
            position: absolute;
        }

        .nav-menu {
            a {
                font-size: 22px;
                font-weight: normal;
                padding: 0 20px;
            }
        }
    }

    .uk-open {
        width: auto;

        .uk-card-default {
            background: $primary;
            color: #fff;
            font-size: 15px;
        }

        .uk-card-body {
            padding: 0;
        }

        &::before {
            content:'';
            display:block;
            width:0;
            height:0;
            position:absolute;

            border-left: 8px solid transparent;
            border-bottom: 8px solid $primary;
            border-right:8px solid transparent;
            left: 50%;
            top: -7px;
            transform: translate(-50%);
        }
    }

    .dropdown-item {
        padding: 10px 25px;
        text-align: center;
        font-size: 20px;
        letter-spacing: 2px;
        position: relative;

        &::after {
            content: '';
            height: 1px;
            width: 80%;
            background: rgba($color: #fff, $alpha: .5);
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translate(-50%);
        }

        &:last-child {
            &::after {
                content: none;
            }
        }

        &.active, &:hover {
            background: darken($primary, 5%);
        }

        &.active::before {
            content: '▸';
            color: #fff;
            margin-right: 5px;
        }

        .foundation-link {
            color: #fff;
            text-decoration: none;

            &::before {
                content: none;
                display: none;
            }
        }
    }

    .release-wrapper {
        .dropdown-item {
            padding: 10px 45px;
        }
    }

    .lang-en {
        .nav-menu {
            li a {
                font-size: 18px;
                padding: 0 15px;

                @media all and (max-width: 1390px) {
                    font-size: 14px;
                }
            }
        }
    }
</style>
