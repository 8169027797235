import { render, staticRenderFns } from "./CatalogueHome.vue?vue&type=template&id=145367e5&scoped=true&lang=pug&"
import script from "./CatalogueHome.vue?vue&type=script&lang=js&"
export * from "./CatalogueHome.vue?vue&type=script&lang=js&"
import style0 from "./CatalogueHome.vue?vue&type=style&index=0&id=145367e5&lang=scss&scoped=true&"
import style1 from "./CatalogueHome.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "145367e5",
  null
  
)

export default component.exports