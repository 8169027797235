import axios from 'axios';
import sha1 from 'js-sha1';

const axios2 = axios.create();

const appId = 'wxab36bec88c48678f';
const secret = '310aac7cba478e5d430c0679db826842';

const baseUrl = 'https://api.weixin.qq.com/cgi-bin';

export async function apiGetWechatToken() {
  const res = await axios2.get(
    `${baseUrl}/token`,
    {
      params: {
        grant_type: 'client_credential',
        appid: appId,
        secret,
      },
    },
  );

  return res.data.access_token;
}

export async function apiGetWechatTicket(token) {
  const res = await axios2.get(
    `${baseUrl}/ticket/getticket`,
    {
      params: {
        access_token: token,
        type: 'jsapi',
      },
    },
  );
  
  return res.data.ticket;
}

export async function genWechatConfig(url) {
  const token = await apiGetWechatToken();
  const ticket = await apiGetWechatTicket(token);
  const nonceStr = 'Wm3WGCFGz0wzccnW';
  const timestamp = moment().unix();

  const string1 = `\
    jsapi_ticket=${ticket}\
    noncestr=${nonceStr}\
    timestamp=${timestamp}\
    url=${url}\
  `;

  const signature = sha1(string1);

  return {
    debug: true,
    appId,
    timestamp,
    nonceStr,
    signature,
    jsApiList: [
      'checkJsApi',
      'onMenuShareTimeline',
      'onMenuShareAppMessage',
      'hideMenuItems',
    ],
  };
}

export async function setWx(url) {
  const config = await genWechatConfig(url);
  wx.config(config);
  wx.error(res => {
    console.log('wx.error')
  });
}
