import Vue from "vue";

Vue.filter('capitalize', capitalize);
Vue.filter('uppercase', uppercase);

export function capitalize(value) {
  if (!value) return '';
  value = value.toString();

  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function uppercase(value) {
  if (!value) return '';
  value = value.toString();
  
  return value.toUpperCase();
}
