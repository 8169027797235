<template lang="pug">
  Loading(v-if="isLoading")
  .home-page.post-home-root(v-else)
    //- <TopSlide>
    Hooper.slideshow(
      v-if="banners.length"
      ref="bannerHooper"
      style="height: auto"
      :centerMode="true"
      :wheelControl="false"
      :infiniteScroll="true"
      :playSpeed="bannerSlideSpeed"
      autoPlay
    )
      Slide(v-for="slide in banners" :key="slide.id")
        .uk-position-relative.banner
          .uk-background-cover(:style="{ backgroundImage: `url(${slide.img_url})` }")
          .uk-position-bottom-center.uk-text-center.uk-padding(
            class="uk-width-2-3"
          )
            .title.text-white {{ slide.title }}
            p.text-white.desc(
              class="uk-visible@m"
              v-html="slide.desc"
            )
            p
              a.uk-button.uk-button-primary.uk-button-large.text-thin.btn-read-more(
                v-if="slide.link_url"
                :href="slide.link_url"
              )
                | {{ $t('READ_MORE') }}
                icon.icon-position-fixed.uk-margin-small-left(type="play" width="21" height="21" )
      HooperNavigation(slot="hooper-addons")
      HooperPagination(slot="hooper-addons" class="uk-visible@m")

    .uk-container.layout
      SearchForm(
        :optionSets="searchOptionSets"
        v-model="searchForm"
        @onSearch="onSearch"
      )

      .uk-margin-large-top.latest-posts-wrapper
        h1.uk-heading-line.uk-text-center.text-red
          span {{ $t('LAST_POST') }}
        Hooper.slider(
          v-if="latestPosts.length"
          style="height: auto"
          :settings="sliderLatestVideosSettings"
        )
          Slide( v-for="post in latestPosts" :key="post.post_id" )
            PostPostCard(:post="post")
          HooperNavigation(slot="hooper-addons")
        ButtonViewAll(
          @click="viewAll"
        )

      .uk-margin-large-top
        h1.uk-heading-line.uk-text-center.text-red
          span {{ $t('POST_FTU') }}
        PostPostPanel(
          v-for="post in featuresPosts"
          :key="post.post_id"
          :post="post"
        )
        ButtonViewAll(
          @click="viewAll"
        )
</template>
<script>
import {
  Hooper,
  Slide,
  Pagination as HooperPagination,
  Navigation as HooperNavigation
} from 'hooper';
import 'hooper/dist/hooper.css';

import { calalogueSidles } from '../mocks/calalogueSidles';
import { postPosts } from '../mocks/postPosts';

import {
  apiGetPostsSlideshows,
  apiGetPostsList,
} from '../apiRequest/posts';
import { PostSearch } from '../mixins/PostSearch';

import SearchForm from '../components/SearchForm.vue'
import PostPostCard from '../components/PostPostCard.vue';
import PostPostPanel from '../components/PostPostPanel.vue';
import ButtonViewAll from '../components/buttons/ButtonViewAll.vue';

export default {
  name: 'PostHome',
  mixins: [PostSearch],
  components: {
    Hooper,
    Slide,
    HooperPagination,
    HooperNavigation,
    SearchForm,
    PostPostCard,
    PostPostPanel,
    ButtonViewAll,
  },
  data () {
    return {
      banners: [],
      latestPosts: [],
      featuresPosts: [],

      sliderLatestVideosSettings: {
        itemsToShow: 2,
        // centerMode: true,
        wheelControl: false,
        infiniteScroll: true,
        breakpoints: {
          960: {
            itemsToShow: 3
          },
          640: {
            itemsToShow: 3
          },
        }
      },

      searchForm: {},
      isLoading: false,
    };
  },
  async created() {
    try {
      this.isLoading = true;
      await Promise.all([
        this.getFeaturePosts(this.$route.query),
        this.getLatestPosts(this.$route.query),
        this.getBanners({ params:{...this.$route.query}}),
      ]);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    onSearch() {
      this.$router.push({
        name: 'postsList',
        query: this.searchForm,
      });
    },

    async getPosts(payload) {
      const res = await apiGetPostsList(payload);
      return res;
    },

    async getFeaturePosts(payload) {
      const res = await this.getPosts({
        params: { feature: true, ...payload },
      });
      this.featuresPosts = res;
    },

    async getLatestPosts(payload) {
      const res = await this.getPosts({
        params: { publish: 'latest', ...payload },
      });

      this.latestPosts = res;
    },

    async getBanners(payload) {
      const res = await apiGetPostsSlideshows(payload);
      this.banners = res;
    },

    viewAll() {
      this.$router.push({
        name: 'postsList'
      });
    }
  },
  computed: {
    bannerSlideSpeed() {
      return this.$store.getters['slideshow/playSpeed']('seconds_post');
    },
  },
  head: {
    title: {
      inner: ''
    },
  },

}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_mixin.scss';

.banner {
  .title {
    font-size: 50px;

    @include mq(960px) {
      font-size: 28px;
    }
  }

  .desc {
    font-size: 24px;
    line-height: 35px;

    @include mq(960px) {
      font-size: 14px;
      line-height: 27px;
    }
  }
}

.uk-position-bottom-center {
  width: calc(100% * 2 / 3.001);
}

.btn-read-more {
  @include mq(640px) {
    padding: 0 13px;
  }
}
</style>

<style lang="scss">
@import '../../sass/helper/_mixin.scss';

.post-home-root {
  .latest-posts-wrapper {
    .hooper-navigation {
      @include mq(640px) {
        .hooper-prev {
          top: 18%;
          left: -1rem;
        }

        .hooper-next {
          top: 18%;
          right: -1rem;
        }
      }
    }
  }
}
</style>

