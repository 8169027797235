<template lang="pug">
  .uk-container.layout.margin-xxlarge-top.channel-list
    ul.uk-breadcrumb(class="uk-visible@s")
      li
        router-link(:to="{ name: 'home' }")
          icon.icon-position-fixed.uk-margin-small-right(type="home" width="15" height="12")
          | {{ $t('HOME') }}
      li
        router-link(:to="{ name: 'channelHome' }") {{ $t('CATALOGUE') }}
      li {{ $t('CHANNEL_PROGRAM_LIST') }}
    

    .uk-margin-large-top
      .uk-margin-large-top.programs
        h1.uk-heading-line.uk-text-center.text-red 
          span {{ $t('CHANNEL_PROGRAM_LIST') }}
        ChannelDatePicker(
          :initDate="date"
          @onChangeDate="onChangeDate"
        )
        ChannelPostPanel(
          v-for="post in posts"
          :key="post.post_id"
          :post="post"
        )

        ButtonLoadMore(
          v-if="!noMore"
          @click="getMorePost"
        )
</template>

<script>
import { channelPosts } from '../mocks/channelPosts';

import { apiGetChannelList } from '../apiRequest/channel';

import ChannelPostPanel from '../components/ChannelPostPanel.vue';
import ChannelDatePicker from '../components/ChannelDatePicker.vue';
import ButtonLoadMore from '../components/buttons/ButtonLoadMore.vue';

export default {
  name: 'ChannelCalendar',
  components: {
    ChannelPostPanel,
    ChannelDatePicker,
    ButtonLoadMore,
  },
  data () {
    return {
      posts: [],
      date: '',
      noMore: false,
    }
  },
  async created() {
    await this.init(this.$route.query.date);
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    await this.init(to.query.date);
  },
  methods: {
    async init(date) {
      this.date = date;

      Promise.all([
        this.getPosts({ params: { date } }).then(res => { this.posts = res; }),
      ]);
    },

    onChangeDate(date /** YYYY-MM-DD */) {
      this.$router.replace({ query: { date: moment(date).format('YYYYMMDD') } });
      this.date = date;
    },

    async getMorePost() {
      const payload = {
        params: {
          offset: this.posts.length,
          date: this.$route.query.date,
        },
      };

      const res = await this.getPosts(payload);

      this.posts = [
        ...this.posts,
        ...res,
      ];
    },

    async getPosts(payload) {
      try {
        const res = await apiGetChannelList(payload);
        if (!res || !Array.isArray(res)) return [];

        this.noMore = !res.length;
        return res;
      } catch (e) {
        console.log(e);
        this.noMore = true;
        return [];
      }
    },
  },
  head: {
    title: {
      inner: ''
    },
  },
}
</script>