<template lang="pug">
  .postsrc {{$t('POST_SRC')}}
      a.postsrc(v-if="flag" :href="content" target="_blank") {{title}}
      span.postsrc(v-else) {{title}}
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      title: '',
      content: '',
      flag: false,
    };
  },
  async created() {
      if (this.post['src_url'].trim() !== '') {
          this.content = this.post['src_url'];
          this.flag = true;
          if (this.post['src_name'].trim() !== '')
              this.title = this.post['src_name'];
          else
              this.title = this.post['src_url'];
      } else {
          if (this.post['src_name'].trim() !== '')
              this.title = this.post['src_name'];
          else
              this.title = this.$i18n.t('POST_ORG');
      }
  },
}
</script>

<style lang="scss" scoped>
.postsrc {
    color:#C7000B;
}

</style>
