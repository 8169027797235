<template lang="pug">
  .post-card-wrapper
    router-link.uk-position-relative.uk-display-block.link-no-underline.link-card.card-item(
      :to="{ name: 'postsSingle', params: { id: post.post_id } }"
    )
      .uk-background-cover(:style="{ backgroundImage: `url(${post.img_url})` }")
        span.uk-position-center.text-small.text-white READ MORE
      .date
        span.icon-group
          icon(
            type="calendar"
            width="15"
            height="15"
          )
          span.uk-margin-small-left {{ post.publish_date }}
        span.icon-group.uk-margin-left
          icon(
            type="layer"
            width="15"
            height="15"
          )
          span.uk-margin-small-left {{ post.category }}
      .title {{ post.title }}
      .desc(v-html="desc")
    .tags
      button.uk-button-default(
        v-for="tag in tags"
        :key="tag"
        @click.stop="clickTag(tag)"
      ) {{ tag }}
</template>

<script>
/*
interface IPost {
  post_id: number;
  img_url: string;
  publish_date: string;
  title: string;
  desc: string;
  tag: string;
}
*/

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  methods: {
    clickTag(tag) {
      this.$router.push({
        name: 'postsList',
        query: { tag },
      });
    },
  },
  computed: {
    tags() {
      return this.post.tag.split(',');
    },
    desc() {
      const maxLen = 100;
      return this.post.desc.length > maxLen
        ? `${this.post.desc.slice(0, maxLen)}...`
        : this.post.desc;
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_variable.scss';
@import '../../sass/helper/_mixin.scss';

.link-card {
  > div {
    margin-bottom: 12px;
  }
}

.uk-background-cover {
  padding-top: 65%;
}

.date {
  color: $primary;
  font-size: 16px;

  @include mq(960px) {
    font-size: 12px;
  }
}

.title {
  font-weight: bold;
  color: #000;
  line-height: 35px;
  font-size: 24px;

  @include mq(960px) {
    font-size: 16px;
    line-height: 24px;
  }

  @include mq(640px) {
    font-size: 14px;
  }
}

.desc {
  font-size: 15px;
  color: #646464;
  line-height: 30px;

  @include mq(960px) {
    display: none;
  }
}

.tags {
  display: flex;
  margin: 0 0.5rem;
  flex-wrap: wrap;

  > button {
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }
}

.icon-group {
  display: inline-flex;
  align-items: center;
}
</style>

