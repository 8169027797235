<template lang="pug">
  Hooper.slider.slider-date-picker(
    ref="slideDatePicker"
    style='height: auto'
    :settings="sliderDateSettings"
  )
    Slide(v-for='(date, index) in dates' :key='date' :index="index")
      .date-picker-item(
        :class="{ 'text-red active': date === activeDate }"
        :data-date="date"
        :data-index="index"
      )
        span.date-number {{ date }}
        span.date-text {{ dayText(date) }}
    HooperNavigation(slot="hooper-addons")
</template>

<script>
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation
} from 'hooper'
import 'hooper/dist/hooper.css';

const MAX_DATE = 14;

const DAY_TEXT = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

export default {
  name: 'ChannelSlideDatePicker',
  props: {
    initDate: {
      type: String, // YYYY-MM-DD
      default: '',
    },
  },
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
  data() {
    return {
      activeDate: 0,
      activeIndex: 0,
      sliderDateSettings: {
        itemsToShow: 5,
        centerMode: true,
        wheelControl: false,
        // infiniteScroll: true,
        breakpoints: {
          640: {
            itemsToShow: 7
          },
        }
      },
    };
  },
  mounted() {
    const initPos = this.initDate.split('-')[2] || moment().format('DD');
    this.activeDate = parseInt(initPos);
    $('.slider-date-picker').on('click', '.date-picker-item', this.setDate);
  },
  beforeDestroy() {
    $('.slider-date-picker').off('click', '.date-picker-item', this.setDate);
  },
  watch: {
    activeIndex: {
      immediate: true,
      async handler(v) {        
        await this.$nextTick();
        if (!this.$refs.slideDatePicker) return;
        this.$refs.slideDatePicker.slideTo(parseInt(v));
      },
    }
  },
  methods: {
    setDate(e) {
      const date = $(e.currentTarget).data('date');
      const index = $(e.currentTarget).data('index');
      this.activeDate = date;
      this.activeIndex = index;

      const formatDate = date > 9 ? `${date}` : `0${date}`;
      const currnetDate = this.currnetDatePrefix + formatDate;
      this.$emit('onChangeDate', currnetDate);
    },
    slideTo(index) {
      this.$refs.slideDatePicker.slideTo(index);
    },
    dayText(date) {
      const today = moment();
      const yearMonth = today.format('YYYYMM');
      const formatDate = date > 9 ? `${date}` : `0${date}`;
      const day = moment(`${yearMonth}${formatDate}`).weekday();
      return this.$t(DAY_TEXT[day]);
    },
  },
  computed: {
    // daysInMonth() {
    //   return moment().daysInMonth();
    // },
    dates() {
      const currentDate = moment();
      return new Array(MAX_DATE)
        .fill(1)
        .map((o, i) => moment().add('day', i).date());
      // return _.map(new Array(this.daysInMonth), (o, i) => i + 1);
    },
    currnetDatePrefix() {
      const date = this.activeDate < this.dates[0]
        ? moment().add('month', 1)
        : moment();
      return date.format('YYYYMM');
    },
    isWidthS() {
      return this.$store.getters['windowSize/isWidthS'];
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_variable.scss';
@import '../../sass/helper/_mixin.scss';

.slider-date-picker {
  margin-bottom: 65px;

  @include mq(640px) {
    margin-bottom: 35px;
  }

  .date-picker-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    cursor: pointer;
    color: #000;

    &.active {
      color: $primary;
    }

    &.active::before {
      content: '';
      position: absolute;
      bottom: -40px;
      left: 50%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 15px 10px;
      border-color: transparent transparent $primary transparent;
      transform: translate(-50%, -50%);

      @include mq(640px) {
        bottom: -25px;
        border-width: 0 6px 9px 6px;
      }
    }

    .date-number {
      font-size: 63px;
      font-weight: bold;
      

      @include mq(640px) {
        font-size: 42px;
      }
    }

    .date-text {
      font-size: 19px;

      @include mq(640px) {
        font-size: 12px;
      }
    }
  }
}
</style>

<style lang="scss">
@import '../../sass/helper/_mixin.scss';

.slider-date-picker {
  .hooper-navigation {
    @include mq(960px) {
      .hooper-next {
        right: 0;
        display: none;
      }

      .hooper-prev {
        left: 0;
        display: none;
      }
    }
  }

  .hooper-list {
    padding-bottom: 40px;

    @include mq(640px) {
      padding-bottom: 25px;
    }
  }
}
</style>
