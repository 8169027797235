<template lang="pug">
  Loading(v-if="isLoading")
  .home-page.event-home(v-else)
    //- <TopSlide>
    Hooper.slideshow(
      v-if="banners.length && !hasSearch"
      style="height: auto"
      :centerMode="true"
      :wheelControl="false"
      :infiniteScroll="true"
      :playSpeed="bannerSlideSpeed"
      autoplay
    )
      Slide(v-for="banner in banners" :key="banner.id")
        a.uk-position-relative.banner(
          style="display: block"
          :href="banner.link_url"
        )
          .uk-background-cover(:style='{ backgroundImage: "url(" + banner.img_url + ")"}')
          .uk-position-center-left.uk-padding.content-wrapper(
            class='uk-width-2-5@m uk-width-2-3@s uk-width-1-1'
            style="left: 10%; top:80%;"
          )
            .title.text-white {{ banner.title }}
            .date {{ banner.date_start }} | {{ banner.place }}
            p.text-white.desp(
              class="uk-visible@m"
              v-html="banner.desc"
            )
            p
              button.uk-button-primary.uk-button.uk-button-large.text-thin.status-btn(style="font-size:16px;")
                | {{ banner.status }}
                icon.icon-position-fixed.uk-margin-small-left(
                  type='arrow-right'
                  width='10'
                  height='10'
                )
      HooperNavigation(slot="hooper-addons")
      HooperPagination(slot="hooper-addons" class="uk-visible@m")

    .uk-container.layout
      SearchForm(
        :optionSets="searchOptionSets"
        v-model="searchForm"
        @onSearch="onSearch"
      )

      .uk-margin-large-top
        h1.uk-heading-line.uk-text-center.text-red
          span(v-if="!hasSearch") {{ $t('EVENT_LATEST_EVENT') }}
          span(
            v-else
            v-html="$t('SEARCH_RESULT_TITLE', { count: posts.length, filter: oldSearchForm.keyword })"
          )

        vk-grid(class="uk-child-width-1-3@s uk-child-width-1-2")
          EventPostCard(
            v-for="post in posts"
            :key="post.post_id"
            :post="post"
          )

        ButtonViewAll(
          v-if="!noMore"
          @click="getMorePost"
        )

      .uk-margin-large-top
</template>
<script>
import {
  Hooper,
  Slide,
  Pagination as HooperPagination,
  Navigation as HooperNavigation
} from 'hooper';
import 'hooper/dist/hooper.css';

import { eventBanners } from '../mocks/EventBanners';
import { eventPosts } from '../mocks/EventPosts';

import { EventSearch } from '../mixins/EventSearch';
import {
  apiGetEventsSlideshows,
  apiGetEventsList,
} from '../apiRequest/events';

import SearchForm from '../components/SearchForm.vue'
import ButtonViewAll from '../components/buttons/ButtonViewAll.vue';
import EventPostCard from '../components/EventPostCard.vue';

export default {
  name: 'EventHome',
  mixins: [EventSearch],
  components: {
    Hooper,
    Slide,
    HooperPagination,
    HooperNavigation,
    SearchForm,
    ButtonViewAll,
    EventPostCard,
  },
  data () {
    return {
      banners: [],
      posts: [],

      searchForm: {},
      noMore: false,

      isLoading: false,
    }
  },
  async created() {
    try {
      this.isLoading = true;
      await Promise.all([
        this.init(this.$route.query),
      ]);
    } finally {
      this.isLoading = false;
    };
  },
  methods: {
    async init(query) {
      this.oldSearchForm = { ...query };
      this.searchForm = { ...query };
      this.getBanners({ params: this.searchForm }),

      Promise.all([
        this.getPosts({ params: this.searchForm })
          .then(res => { this.posts = res; }),
      ]);
    },

    onSearch() {
      this.$router.replace({ query: this.searchForm });
    },

    async getMorePost() {
      const payload = {
        params: {
          offset: this.posts.length,
          ...this.searchForm,
        },
      };

      const res = await this.getPosts(payload);

      this.posts = [
        ...this.posts,
        ...res,
      ];
    },

    async getBanners(payload) {
      const res = await apiGetEventsSlideshows(payload);
      this.banners = res;
    },

    async getPosts(payload) {
      try {
        const res = await apiGetEventsList(payload);
        if (!res) return [];

        this.noMore = !res.length;
        return res;
      } catch (e) {
        console.log(e);
        this.noMore = true;
        return [];
      }
    },
  },
  computed: {
    hasSearch() {
      return !_.isEmpty(_.filter(_.omit(this.$route.query, ['lang', 'site']), o => o));
   },
    bannerSlideSpeed() {
      return this.$store.getters['slideshow/playSpeed']('seconds_event');
    },
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    this.init(to.query);
  },
  head: {
    title: {
      inner: ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_variable.scss';
@import '../../sass/helper/_mixin.scss';

.banner {
  .content-wrapper {
    @include mq(640px){
      text-align: center;
      top: initial;
      bottom: 0;
      left: 0 !important;
      transform: translateY(0);
    }
  }

  .title {
    font-size: 42px;
    font-weight: bold;

    @include mq(960px) {
      font-size: 36px;
    }
  }

  .date {
    font-size: 20px;
    color: $primary;

    @include mq(960px) {
      font-size: 18px;
    }
  }

  .desp {
    font-size: 20px;

    @include mq(960px) {
      font-size: 12px;
    }
  }

  .uk-button-default {
    background: rgba($color: #fff, $alpha: .7);
  }

  .status-btn {
    font-size: 12px;
    svg {
      filter: grayscale(1) brightness(8);
    }
  }
}
</style>

<style lang="scss">
@import '../../sass/helper/_variable.scss';
@import '../../sass/helper/_mixin.scss';

.event-home {
  @include mq-min($m-media) {
    .keyword-input {
      flex: 1;
    }
  }
}
</style>
