<template lang='pug'>
  div
    vk-modal-full(:show.sync="modalMobileNavState", center)
      div
        a.uk-position-top-right.trigger-close(@click="modalMobileNavToggle")
          icon(type="trigger-close" width="80" height="80")
        .uk-margin-auto.uk-width-2-3#mobileNav(class="uk-width-3-5@s")
          ul.uk-list
            li
              router-link(
                :class="{ 'route-active': routeActive('aaa') }"
                to="/"
              )
                span 00
                | {{ $t('HOME') }}
            li
              router-link(
                :class="{ 'route-active': routeActive('about') }"
                :to="{ name: 'about' }"
              )
                span 01
                | {{ $t('ABOUT') }}CNEX
            li
              router-link(
                :class="{ 'route-active': routeActive('catalogue') }"
                :to="{ name: 'catalogueHome' }"
              )
                span 02
                | {{ $t('CATALOGUE') }}
            li
              router-link(
                :class="{ 'route-active': routeActive('channel') }"
                :to="{ name: 'channelHome' }"
              )
                span 03
                | {{ $t('CHANNEL') }}
            li
              router-link(
                :class="{ 'route-active': routeActive('shopping') }"
                :to="{ name: 'shoppingHome' }"
              )
                span 04
                | {{ $t('SHOPPING') }}

            li
              router-link(
                :class="{ 'route-active': routeActive('post') }"
                :to="{ name: 'postsHome' }"
              )
                span 05
                | {{ $t('POSTS') }}

            li
              router-link(
                :class="{ 'route-active': routeActive('event') }"
                :to="{ name: 'eventsHome' }"
              )
                span 06
                | {{ $t('EVENTS') }}
            //- li
            //-   a.icon-white(
            //-     href="#"
            //-     @click="onToggle('release')"
            //-     :class="{\
            //-       'icon-active': toggle.release,\
            //-       'route-active': routeActive('posts') || routeActive('events'),\
            //-     }"
            //-   )
            //-     span 05
            //-     | {{ $t('RELEASE') }}
            //-     icon.uk-margin-left.icon-active(type='arrow-down', width='12' height='9')
            //- transition(name='fade')
            //-   li.uk-margin-left.lang-wrapper(v-if='toggle.release')
            //-     router-link.uk-margin-right(
            //-       :to="{ name: 'eventsHome' }"
            //-     ) {{ $t('EVENTS') }}
            //-     router-link(
            //-       :to="{ name: 'postsHome' }"
            //-     ) {{ $t('POSTS') }}

            li
              a.icon-white(
                href="#"
                @click="onToggle('foundation')"
                :class="{ 'icon-active': toggle.foundation }"
              )
                span 07
                | {{ $t('FOUNDATION') }}
                icon.uk-margin-left.icon-active(type='arrow-down', width='12' height='9')
            transition(name='fade')
              li.uk-margin-left.lang-wrapper(v-if='toggle.foundation')
                a.uk-margin-right(
                  :href="FOUNDATION.TW"
                  target="_blank"
                ) {{ $t('TAIPEI') }}
                a.uk-margin-right(
                  :href="FOUNDATION.CN"
                  target="_blank"
                ) {{ $t('BEIJING') }}
                a(
                  :href="FOUNDATION.HK"
                  target="_blank"
                ) {{ $t('HONG_KONG') }}

          hr
          ul.uk-list
            li.uk-text-nowrap
              a.link-no-underline.icon-white(href="#" @click="modalLoginToggle")
                icon.uk-margin-right(type="signin", width="21")
                | {{ $t('SIGN_IN') }}
                i.uk-margin-small-left.uk-margin-small-right /
              a.link-no-underline(href="#" @click="modalSignUpToggle")
                | {{ $t('SIGN_UP') }}

            li
              a.link-no-underline.icon-white.uk-text-nowrap(
                href="#"
                @click="onToggle('site')"
                :class="{ 'icon-active': toggle.site }"
              )
                  icon.uk-margin-right(type='global', width='22')
                  | {{ $t('SITE') }}
                  icon.uk-margin-left.icon-active(type='arrow-down', width='6' height='9')

            transition(name="fade")
              li.uk-margin-left.lang-wrapper(v-if="toggle.site")
                a.uk-margin-right(
                  href="#"
                  :class="{ active: nowSite === 't' }"
                  @click="onChangeSite('t')"
                ) {{ $t('SITE_TW') }}
                a.uk-margin-right(
                  href="#"
                  :class="{ active: nowSite === 'c' }"
                  @click="onChangeSite('c')"
                ) {{ $t('SITE_CH') }}
                a(
                  href="#"
                  :class="{ active: nowSite === 'h' }"
                  @click="onChangeSite('h')"
                ) {{ $t('SITE_HK') }}


            li
              a.link-no-underline.icon-white.uk-text-nowrap(
                href="#"
                @click="onToggle('lang')"
                :class="{ 'icon-active': toggle.lang }"
              )
                icon.uk-margin-right(type='lang', width='22')
                | {{ $t('LANGUAGE') }}
                icon.uk-margin-left.icon-active(type='arrow-down', width='6' height='9')

            transition(name="fade")
              li.uk-margin-left.lang-wrapper(v-if="toggle.lang")
                a.uk-margin-right(
                  href="#"
                  :class="{ active: nowLang === 'ch' }"
                  @click="onChangeLang('ch')"
                ) {{ $t('LANG_CH') }}
                a.uk-margin-right(
                  href="#"
                  :class="{ active: nowLang === 'cn' }"
                  @click="onChangeLang('cn')"
                ) {{ $t('LANG_CN') }}
                a(
                  href="#"
                  :class="{ active: nowLang === 'en' }"
                  @click="onChangeLang('en')"
                ) {{ $t('LANG_EN') }}

            li
              router-link.link-no-underline.icon-white.uk-text-nowrap(:to='{ name: "download" }')
                icon.uk-margin-right(type='download', width='26')
                | {{ $t('DOWNLOAD') }}
        .outline.outline-white
          i.top
          i.bottom
          i.left
          i.right
</template>
<script>
import { FOUNDATION } from '../utils/foundations';
import { urlAddLang } from '@/js/utils/helpers';
import { urlAddSite } from '@/js/utils/helpers';
import { nowLang } from '../utils/locales';
import { nowSite } from '../utils/sites';

const TYPES = ['lang', 'site', 'release', 'foundation'];

export default {
  name: 'ModalMobileNav',
  data () {
    return {
      nowLang,
      nowSite,
      FOUNDATION,
      toggle: {
        lang: false,
        site: false,
        release: false,
        foundation: false,
      },
    }
  },
  watch: {
    modalMobileNavState(v) {
      if (v) return;

      TYPES.forEach(o => {
        this.toggle[o] = false;
      });
    },
  },
  computed: {
    modalMobileNavState: {
      get() {
        return this.$store.state.modals.isModalMobileNavActive;
      },
      set() {
          this.$store.state.modals.isModalMobileNavActive;
      },
    }
  },
  methods: {
    modalMobileNavToggle(){
      this.$store.commit('modalMobileNavToggle');
    },
    modalLoginToggle(){
      this.$store.commit('modalLoginToggle');
    },
    modalSignUpToggle(){
      this.$store.commit('modalSignUpToggle');
    },
    onToggle(type) {
      const otherTypes = TYPES.filter(o => o !== type);
      this.toggle[type] = !this.toggle[type];
      otherTypes.forEach(o => {
        this.toggle[o] = false;
      });
    },
    routeActive(type) {
      return this.$route.path.includes(type);
    },
    onChangeSite(site) {
        window.localStorage.setItem('site', site);
        urlAddSite({ site });
    },
    onChangeLang(lang) {
      window.localStorage.setItem('locale', lang);
      urlAddLang({ lang });
    },
  },
  head: {
    title: {
      inner: ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_variable.scss';

.lang-wrapper {
  overflow: visible;
  white-space: nowrap;

  a.active {
    background: darken($primary, 5%);

    &::before {
      content: '▸';
      color: #fff;
      margin-right: 5px;
    }
  }
}
</style>
