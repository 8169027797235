import { mqWidthRange } from '../utils/mediaQuery';

function getWidthMq(width) {
  const { XL, L, M, S } = mqWidthRange
  // if (width > L.size) return XL.id;
  if (width > M.size) return L.id;
  else if (width > S.size) return M.id;
  return S.id;
}

export const windowSize = {
  namespaced: true,
  state: {
    width: 0,
    widthMq: 'large',
  },
  getters: {
    isWidthL: state => state.widthMq === mqWidthRange.L.id,
    isWidthM: state => state.widthMq === mqWidthRange.M.id,
    isWidthS: state => state.widthMq === mqWidthRange.S.id,
    isLogerThan: state => num => state.width > num,
    isSmallThan: state => num => state.width < num,
  },
  mutations: {
    updateWidth(state, width) {
      state.width = width;
      state.widthMq = getWidthMq(width);
    }
  },
};