<template lang="pug">
  Loading(v-if="isLoading")
  .cata-home-root(v-else)
    hooper.slideshow(
      v-if="banners.length"
      style="height: auto"
      :centerMode="true"
      :wheelControl="false"
      :infiniteScroll="true"
      :playSpeed="bannerSlideSpeed"
      autoPlay
    )
      slide(
        v-for="slide in banners"
        :key="slide.id"
      )
        .uk-position-relative
          SlideShowVideo(
            v-if="slide.video_url"
            :videoUrl="slide.video_url"
            :imgUrl="slide.img_url"
          )
          .uk-background-cover( :style="{ backgroundImage: `url(${slide.img_url})` }")
          .uk-position-bottom-center.uk-text-center.uk-padding(
            class="uk-width-2-3"
          )
            h2.text-white.title {{ slide.title }}
              span.uk-display-block.subtitle {{ slide.title_en }}
            p.text-white.desc(
              class="uk-visible@m"
              v-html="slide.desc"
            )
            p
              .button-wrapper
                a.uk-button.uk-button-primary.uk-button-large.text-thin.btn-read-more(
                  v-if="slide.link_url"
                  :href="slide.link_url"
                )
                  | {{ $t('READ_MORE') }}
                  icon.icon-position-fixed.uk-margin-small-left(type="play" width="21" height="21" )

                ButtonWatchTrailer.btn-trailer-lg(
                    v-if="slide.trailer_url"
                    @click="showTrailer(slide.trailer_url)"
                  )
            ButtonWatchTrailer.btn-trailer-m(
              v-if="slide.trailer_url"
              @click="showTrailer(slide.trailer_url)"
            )
      hooper-navigation(slot="hooper-addons")
      hooper-pagination(slot="hooper-addons" class="uk-visible@m")
    //- </TopSlide>
    .uk-container.layout
      //- <Search>
      SearchForm(
        :optionSets="searchOptionSets"
        v-model="searchForm"
        @onSearch="onSearch"
      )
      //- </Search>
      //- <LatestVideos>
      .uk-margin-large-top.post-list-wrapper
        h1.uk-heading-line.uk-text-center.text-red
          span {{ $t('CATA_FEATURES') }}
        .uk-margin-large-top
        CataloguePostSlide(
          :posts="featurePosts"
        )
        p.uk-padding.uk-text-center
          router-link.uk-button.uk-button-default.uk-button-large.uk-width-1-1.text-remove-spacing.text-medium(
            :to="{ name: 'catalogueList' }"
            class="uk-width-1-3@m uk-width-1-2@s"
          )
            | {{ $t('VIEW_ALL') }}
            icon.icon-position-fixed.uk-margin-small-left(
              type="arrow-right"
              width="7"
              height="11"
              style="top: -1px"
            )

      .uk-margin-large-top.post-list-wrapper
        h1.uk-heading-line.uk-text-center.text-red
          span {{ $t('FINISHED_VIDEO') }}
        .uk-margin-large-top
        CataloguePostSlide(
          :posts="posts.finished"
        )
        p.uk-padding.uk-text-center
          router-link.uk-button.uk-button-default.uk-button-large.uk-width-1-1.text-remove-spacing.text-medium(
            :to="{ name: 'catalogueList' }"
            class="uk-width-1-3@m uk-width-1-2@s"
          )
            | {{ $t('VIEW_ALL') }}
            icon.icon-position-fixed.uk-margin-small-left(
              type="arrow-right"
              width="7"
              height="11"
              style="top: -1px"
            )

      .uk-margin-large-top.post-list-wrapper
        h1.uk-heading-line.uk-text-center.text-red
          span {{ $t('PROGRESS_VIDEO') }}
        .uk-margin-large-top
        CataloguePostSlide(
          :posts="posts.progress"
        )
        p.uk-padding.uk-text-center
          router-link.uk-button.uk-button-default.uk-button-large.uk-width-1-1.text-remove-spacing.text-medium(
            :to="{ name: 'catalogueList' }"
            class="uk-width-1-3@m uk-width-1-2@s"
          )
            | {{ $t('VIEW_ALL') }}
            icon.icon-position-fixed.uk-margin-small-left(
              type="arrow-right"
              width="7"
              height="11"
              style="top: -1px"
            )

      .uk-margin-large-top.post-list-wrapper
        h1.uk-heading-line.uk-text-center.text-red
          span {{ $t('AGENT_VIDEO') }}
        .uk-margin-large-top
        CataloguePostSlide(
          :posts="posts.agent"
        )
        p.uk-padding.uk-text-center
          router-link.uk-button.uk-button-default.uk-button-large.uk-width-1-1.text-remove-spacing.text-medium(
            :to="{ name: 'catalogueList' }"
            class="uk-width-1-3@m uk-width-1-2@s"
          )
            | {{ $t('VIEW_ALL') }}
            icon.icon-position-fixed.uk-margin-small-left(
              type="arrow-right"
              width="7"
              height="11"
              style="top: -1px"
            )
</template>
<script>
import {
  Hooper,
  Slide,
  Pagination as HooperPagination,
  Navigation as HooperNavigation,
} from 'hooper'
import 'hooper/dist/hooper.css';

import {
  apiGetCataSlideshows,
  apiGetCataHomePosts,
  apiGetCataSearch,
} from '../apiRequest/catalogue';
import { CatalogueSearch } from '../mixins/CatalogueSearch';
import { MODAL_EVENT } from '../utils/events';

import SearchForm from '../components/SearchForm.vue'
import CataloguePostSlide from '../components/CataloguePostSlide.vue';
import CataloguePostList from '../components/CataloguePostList.vue';
import ButtonWatchTrailer from '../components/buttons/ButtonWatchTrailer.vue';
import SlideShowVideo from '../components/commons/SlideShowVideo.vue';

import { calalogueSidles } from '../mocks/calalogueSidles';
import { cataloguePosts } from '../mocks/cataloguePosts';

const POST_STATUS = {
  all: 'all',
  finished: 'finished',
  progress: 'progress',
  agent: 'agent',
};

export default {
  name: 'CatalogueHome',
  mixins: [CatalogueSearch],
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination,
    SearchForm,
    CataloguePostSlide,
    ButtonWatchTrailer,
    SlideShowVideo,
    CataloguePostList,
  },
  data () {
    return {
      banners: [],
      posts: {},
      featurePosts: [],
      isLoading: false,

      searchForm: {},
    }
  },
  async created() {
    try {
      this.isLoading = true;
      let q = this.$route.query;

      await Promise.all([
        this.getCataBanners({params: q}),

        this.getCataPosts1({ params: {...q, ...{ featured: 1 }}})
          .then(res => { this.featurePosts = res; }),
        this.getPostByStatus({ params: {...q, ...{ status: POST_STATUS.finished }}}),
        this.getPostByStatus({ params: {...q, ...{ status: POST_STATUS.progress }}}),
        this.getPostByStatus({ params: {...q, ...{ status: POST_STATUS.agent }}}),
      ]);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    onSearch() {
      this.$router.push({
        name: 'catalogueList',
        query: this.searchForm
      });
    },

    async getCataBanners(q) {
      const res = await apiGetCataSlideshows(q);
      this.banners = res;
    },

    async getCataPosts(payload) {
      const res = await apiGetCataHomePosts(payload);
      return res;
    },

    async getCataPosts1(payload) {
        let res = await apiGetCataHomePosts(payload);
        res = _.shuffle(res);
        return res;
    },

    async getPostByStatus(payload) {
      let res = await this.getCataPosts(payload);
      res = _.shuffle(res);
      this.posts = {
        ...this.posts,
        [payload.params.status]: res,
      };
    },

    showTrailer(trailerUrl) {
      this.$event.$emit(MODAL_EVENT.SHOW_TRAILER, {
        videoUrl: trailerUrl,
      });
    },
  },
  computed: {
    bannerSlideSpeed() {
      return this.$store.getters['slideshow/playSpeed']('seconds_catalogue');
    },
    isWidthS() {
      return this.$store.getters['windowSize/isWidthS'];
    },
  },
  head: {
    title: {
      inner: ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_mixin.scss';

.uk-position-bottom-center {
  width: calc(100% * 2 / 3.001);
}

.title {
  font-size: 50px;
  font-weight: bold;

  @include mq(960px) {
    font-size: 42px;
  }

  @include mq(640px) {
    font-size: 30px;
  }
}

.subtitle {
  font-size: 26px;
  font-weight: bold;

  @include mq(960px) {
    font-size: 24px;
  }

  @include mq(640px) {
    font-size: 18px;
  }
}

.desc {
  font-size: 22px;
  line-height: 35px;

  @include mq(960px) {
    font-size: 16px;
    line-height: 30px;
  }
}

.slideshow {
  .button-wrapper {
    position: relative;

    .btn-read-more {
      @include mq(640px) {
        padding: 0 20px;
        width: 100%;
      }
    }

    .btn-trailer-lg {
      position: absolute;
      top: 50%;
      transform: translate(20px, -50%);

      @include mq(960px) {
        display: none;
      }
    }
  }

  .btn-trailer-m {
    display: none;

    @include mq(960px) {
      display: inline-flex;
    }
  }
}
</style>

<style lang="scss">
@import '../../sass/helper/_mixin.scss';

.cata-home-root {
  .post-list-wrapper {
    .hooper-navigation {
      @include mq(640px) {
        .hooper-prev,
        .hooper-next {
          top: 24%;
        }
      }
    }
  }
}
</style>
