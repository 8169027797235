<template lang="pug">
  .editor-type-count
    .uk-position-relative.type-input
      select.uk-select(
        :value="form.type"
        @change="onChangeType"
      )
        option(
          v-for="type in typeOptions"
          :key="type.value"
          :value="type.value"
        ) {{ type.label }}
      vk-icon.down.uk-position-center-right(icon="triangle-down")
    .custom-input.count-input
      | {{ formQty }}
      .counter-wrapper
        vk-icon.up(
          icon="triangle-up"
          ratio="2"
          @click="onIncreaseQty"
        )
        vk-icon.down(
          icon="triangle-down"
          ratio="2"
          @click="onDecreaseQty"
        )
</template>

<script>
/*
interface IValue {
  type: string;
  quantity: number;
}

interface ITypeOption {
  value: string;
  label: string;
}
*/

export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        type: '',
        quantity: 1,
      }),
    },
    typeOptions: {
      type: Array,
      default: () => ([]),
    },
  },
  methods: {
    onChangeType(v) {
      this.form.type = v;
    },
    onIncreaseQty() {
      this.form.quantity += 1;
    },
    onDecreaseQty() {
      if (this.form.quantity < 2) return;
      this.form.quantity -= 1;
    },
  },
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', { ...v });
      },
    },
    formQty() {
      const { quantity } = this.value;
      return  quantity < 10 ? `0${quantity}` : `${quantity}`;
    }
  },
}
</script>

<style lang="scss" scoped>
$border-color: #e1e1e1;

.editor-type-count {
  display: inline-flex;

  .uk-icon {
    color: #000;
  }
}

.editor-type-count .uk-select {
  border: 1px solid $border-color;
  border-left: 0;
  color: #000;
  height: 100%;
  width: 120px;
  font-size: 14px;
  letter-spacing: 4.2px;
  font-weight: bold;
}

.uk-select:not([multiple]):not([size]):focus {
  background-color: transparent;
  border-color: $border-color;
  color: #000;
}

.custom-input {
  width: 80px;
  border: 1px solid $border-color;
  border-left: 0;
  font-size: 14px;
  display: inline-flex;
  padding: 18px 20px;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  letter-spacing: 4.2px;
  color: #000;

  &.disabled {
    .vk-icon {
      color: $border-color;
    }
  }
}

.type-input {
  .uk-position-center-right {
    right: 8px;
  }
}

.count-input {
  border-left: 0;
  border-right: 0;
}

.counter-wrapper {
  display: inline-flex;
  flex-direction: column;
  position: relative;

  .uk-icon {
    margin: -10px;
    cursor: pointer;
    position: absolute;
  }

  .up {
    top: -18px;
  }

  .down {
    bottom: -18px;
  }
}
</style>
