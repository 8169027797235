<template lang="pug">
  .post-single-related
    //- Hooper.slideshow(
    //-   style="height: auto"
    //-   :settings="sliderSetting"
    //- )
    //-   Slide(v-for="post in posts" :key="post.post_id")
    //-     router-link.link-no-underline(:to="{ name: 'postsSingle', params: { id: post.post_id } }")
    //-       .post-panel
    //-         vk-grid(gutter="small")
    //-           div(class="uk-width-2-5@m uk-width-1-2@s uk-visible@s")
    //-             .uk-background-cover(:style="{ backgroundImage: `url(${post.img_url})` }")
    //-           div.info-wrapper(class="uk-width-expand@m uk-width-1-2@s")
    //-             button.uk-button-default {{ post.btn_text }}
    //-             .title.uk-margin-top(class="uk-visible@s") {{ post.title }} 
    //-   HooperNavigation(slot="hooper-addons")

    router-link.link-no-underline.icon-link(
      v-if="prevPost.post_id"
      :to="{ name: 'postsSingle', params: { id: prevPost.post_id } }"
    )
      vk-icon.prev(
        icon="chevron-left"
        ratio="2.5"
      )

    router-link.link-no-underline.post-panel(
      :to="{ name: 'postsSingle', params: { id: prevPost.post_id } }"
    )
      template(v-if="prevPost.post_id")
        div(class="uk-width-2-5@m uk-width-1-2@s uk-visible@s")
          .uk-background-cover.uk-margin-small-right(:style="{ backgroundImage: `url(${prevPost.img_url})` }")
        div.info-wrapper(class="uk-width-expand@m uk-width-1-2@s")
          button.uk-button-default {{ prevPost.btn_text }}
          .title.uk-margin-top(class="uk-visible@s") {{ prevPost.title }}

    .hr

    router-link.link-no-underline.post-panel(
      :to="{ name: 'postsSingle', params: { id: nextPost.post_id } }"
    )
      template(v-if="nextPost.post_id")
        div.uk-margin-small-right(class="uk-width-2-5@m uk-width-1-2@s uk-visible@s")
          .uk-background-cover(:style="{ backgroundImage: `url(${nextPost.img_url})` }")
        div.info-wrapper(class="uk-width-expand@m uk-width-1-2@s")
          button.uk-button-default {{ nextPost.btn_text }}
          .title.uk-margin-top(class="uk-visible@s") {{ nextPost.title }}

    router-link.link-no-underline.icon-link(
      v-if="nextPost.post_id"
      :to="{ name: 'postsSingle', params: { id: nextPost.post_id } }"
    )
      vk-icon.next(
        icon="chevron-right"
        ratio="2.5"
      )
</template>

<script>
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation
} from 'hooper';
import 'hooper/dist/hooper.css';

/*
interface IRelatedPost {
  post_id_next: 0;
  title_next: string;
  img_url_next: string;
  post_id_prev: 0;
  title_prev: string;
  img_url_prev: string;
}
*/

export default {
  name: 'PostSingleRelated',
  components: {
    Hooper,
    HooperNavigation,
    Slide,
  },
  props: {
    relatedPost: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sliderSetting: {
        itemsToShow: 2,
        wheelControl: false,
        // centerMode: true,
      },
    };
  },
  computed: {
    posts() {
      return [
        this.prevPost,
        this.nextPost,
      ];
    },

    prevPost() {
      return {
        post_id: this.relatedPost.post_id_prev,
        title: this.relatedPost.title_prev,
        img_url: this.relatedPost.img_url_prev,
        btn_text: this.$t('POST_PREV'),
      };
    },

    nextPost() {
      return {
        post_id: this.relatedPost.post_id_next,
        title: this.relatedPost.title_next,
        img_url: this.relatedPost.img_url_next,
        btn_text: this.$t('POST_NEXT'),
      };
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_variable.scss';
@import '../../sass/helper/_mixin.scss';

.post-single-related {
  border-top: 2px solid $primary;
  border-bottom: 2px solid $primary;
  display: flex;
  padding: 30px 0;

  .info-wrapper {
    line-height: 1;
  }

  .post-panel {
    @include mq(640px) {
      padding: 0 10px;
      align-items: center;
    }

    &:nth-child(4) {
      @include mq(640px) {
        text-align: right;
      }
    }
  }
}

.post-panel {
  padding: 0 30px;
  display: flex;
  flex-grow: 1;
  flex-basis: 50%;
}

.hr {
  width: 3px;
  background: $primary;
}

// 不支援 deep
// .slideshow >>> {
//   .hooper-prev {
//     left: 0 !important;;
//   }

//   .hooper-next {
//     right: 0 !important;
//   }
// }

.uk-background-cover {
  padding-top: 65%;
}

.uk-button-default {
  background-color: $primary;
  color: #fff;
}

.title {
  font-size: 18px;
  font-weight: bold;
  color: #000;
  line-height: 30px;
}

.icon-link {
  display: flex;

  .uk-icon {
    display: flex;
    color: $primary;

    @include mq(640px) {
      &.prev {
        margin-left: -10px;
      }

      &.next {
        margin-right: -10px;
      }
    }
  }
}
</style>

<style lang="scss">
@import '../../sass/helper/_variable.scss';
@import '../../sass/helper/_mixin.scss';

.post-single-related {
  .hooper-slide {
    flex-shrink: initial;

    &:nth-child(1) {
      border-right: 2px solid $primary;
    }

    &:nth-child(2) {
      .info-wrapper {
        @include mq(640px) {
          text-align: right;
        }
      }
    }
  }

  .slideshow {
    .hooper-prev {
      left: 0 !important;;
    }

    .hooper-next {
      right: 0 !important;
    }
  }
}
</style>
