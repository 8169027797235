<template lang="pug">
  Loading(v-if="isLoading")
  .uk-container.layout.uk-margin-large-top.uk-margin-large-bottom.single-page.catalogue-single-page(v-else)
    ModalCataloguePostLicense(:content="post.public_license_desc")
    //- <Breadcrumb>
    ul.uk-breadcrumb(class="uk-visible@s")
      li
        router-link( :to="{ name: 'home' }")
          icon.icon-position-fixed.uk-margin-small-right(type="home" width="15" height="12")
          | {{ $t('HOME') }}
      li
        router-link( :to="{ name: 'catalogueHome' }") {{ $t('CATALOGUE') }}
      li {{ post.title }}
    //- </Breadcrumb>

    .uk-margin-medium-top
      h1.uk-h2.text-bold
        .title {{ post.title }}
        .subtitle {{ post.title_en }}

    vk-grid(gutter="small")
      div(class="uk-width-2-3@m")
        SlideWithThumbs(:items="post.slideshow_url")

      div(class="uk-width-1-3@m")
        vk-grid(gutter="small")
          div(class="uk-width-1-1@m uk-width-1-2@s uk-width-1-1")
            InfoTable(:infos="infos")

          div(class="uk-width-1-1@m uk-width-1-2@s uk-width-1-1")
            div(class="uk-visible@m")
              vk-sticky(:offset="100")
                div
                  CatalogueSingleSideButtons(:post="post")
                  ButtonShareGroup.uk-margin-small-top(
                    :post="post"
                  )
            CatalogueSingleSideButtons(
              :post="post"
              class="uk-hidden@m"
            )
            ButtonShareGroup.uk-margin-small-top(
              :post="post"
              class="uk-hidden@m"
            )

      div(class="uk-width-2-3@m")
        .uk-margin-medium-top.tab-group
          vk-tabs
            vk-tabs-item(:title="$t('CATA_INTRODUCTION')")
              div(v-html="post.detail")
            vk-tabs-item(:title="$t('AWARD')")
              div(v-html="post.award")
            vk-tabs-item(:title="$t('CATA_TEAM')")
              div(v-html="post.team")
            vk-tabs-item(:title="$t('COMMENT')")
              .fb-comments(
                :data-href="locationHref"
                data-width="100%"
                data-numposts="10"
              )
</template>
<script>
import { catalogueSinglePost } from '../mocks/catalogueSinglePost';

import SlideWithThumbs from '../components/commons/SlideWithThumbs.vue';
import InfoTable from '../components/commons/InfoTable.vue';
import CatalogueSingleSideButtons from '../components/CatalogueSingleSideButtons.vue';
import ModalCataloguePostLicense from '../partials/ModalCataloguePostLicense.vue';
import ButtonShareGroup from '../components/buttons/ButtonShareGroup.vue';
import { apiGetCataSingle } from '../apiRequest/catalogue';

/*
interface IPost {
  post_id: string;
  title: string;
  title_en: string;
  slideshow_url: {
    img_url: string;
    video_url: string;
  }[];
  country: sstring;
  time: string;
  date: string;
  company: string;
  director: string;
  type: string;
  quality: string;
  company_url: string;
  public_license_desc: string;
  agent_url: string;
  detail: string;
  award: string;
}
*/

export default {
  name: 'CatalogueSingle',
  components: {
    SlideWithThumbs,
    InfoTable,
    CatalogueSingleSideButtons,
    ModalCataloguePostLicense,
    ButtonShareGroup,
  },
  data () {
    return {
      post: {},
      isLoading: false,
      tabElms: [],
    }
  },
  async created() {
    try {
      this.isLoading = true;
      let q = this.$route.query;
      await Promise.all([
        this.getPost(this.$route.params.id, {params: q}),
      ]);
    } finally {
      this.isLoading = false;
    }
  },
  beforeDestroy() {
    this.tabElms.forEach(o => {
      o.removeEventListener('click', this.onTabClick);
    });
  },
  methods: {
    async getPost(id, q) {
      try {
        const res = await apiGetCataSingle(id, q);
        this.post = res[0] || {};

        setTimeout(() => {
          this.tabElms = document.querySelectorAll('.tab-group li a');
          console.log(this.tabElms);
          this.tabElms.forEach(o => {
            o.addEventListener('click', this.onTabClick);
          });
        }, 1000);
      } catch (e) {
        console.error(e);
        this.$router.push({ name: 'home' });
      }
    },

    async onTabClick(e) {
      console.log(e);
      const tab = $(e.currentTarget);
      // if (!tab[0] || tab[0].innerText !== this.$t('COMMENT')) return;

      // await this.$nextTick();
      // FB.XFBML.parse();
      setTimeout(() => { FB.XFBML.parse(); }, 500);
    },
  },
  computed: {
    infos() {
      const { post } = this;
      let p1 = (post.production == null)?'':post.production.replace(/,/g, ' | ');
      let p2 = (post.company == null)?'':post.company.replace(/,/g, ' | ');
      let p3 = (post.director == null)?'':post.director.replace(/,/g, ', ');
      let p4 = (post.type == null)?'':post.type.replace(/,/g, ', ');
      let p5 = (post.quality == null)?'':post.quality.replace(/,/g, ', ');
      return [
        { label: this.$t('CATA_INFO_TITLE'), values: [post.title_dhead] },
        { label: this.$t('COUNTRY'), values: [post.country] },
        { label: this.$t('RUN_TIME'), values: [`${post.time}`] },
        { label: this.$t('PUBLISH_DATE'), values: [post.date] },
        { label: this.$t('PRODUCTION'), values: [p1] },
        { label: this.$t('COMPANY'), values: [p2] },
        { label: this.$t('DIRECTOR'), values: [p3] },
        { label: this.$t('TYPE'), values: [p4] },
        { label: this.$t('QUALITY'), values: [p5] },
        { label: this.$t('CATA_STATUS'), values: [post.status] }
      ];
    },
    locationHref() {
      if (typeof window !== 'undefined') {
          return window.location.href;
      } else {
          return '';
      }
    },
  },
  head: {
    title: {
      inner: ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_mixin.scss';

.uk-margin-large-top {
  @include mq-range(960px, 640px) {
    margin-top: 120px !important;
  }
}

.title, .subtitle {
  font-size: 34px;

  @include mq(960px) {
    font-size: 24px;
  }
}
</style>
