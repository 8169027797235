<template lang="pug">
  span.share-btn(
    @click="click"
  )
    icon(
      type="line"
      width="14"
      height="14"
      style="margin-right: 5px;"
    )
    | {{ $t('SHARE_BY_LINE') }}
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    click() {
      window.open(
        `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(window.location.href)}`,
        '',
        'width=700,height=500',
      );
    },
  },
}
</script>

<style lang="scss" scoped>
$bg: #00b900;

.share-btn {
  background: $bg;

  &:hover {
    background: darken($bg, 5%);
  }
}
</style>
