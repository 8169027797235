export const mqWidthRange = {
  XL: {
    id: 'xxlarge',
    size: 1600,
  },
  L: {
    id: 'large',
    size: 1200,
  },
  M: {
    id: 'medium',
    size: 960,
  },
  S: {
    id: 'small',
    size: 640,
  },
};
