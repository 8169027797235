export const ListMixin = {
  methods: {
    async init(query) {
      this.oldSearchForm = { ...query };
      this.searchForm = { ...query };

      Promise.all([
        this.getPosts({ params: query }).then(res => { this.posts = res; }),
      ]);
    },

    onSearch() {
      this.$router.replace({ query: this.searchForm });
    },

    async getMorePost() {
      const payload = {
        params: {
          offset: this.posts.length,
          ...this.searchForm,
        },
      };

      const res = await this.getPosts(payload);

      this.posts = [
        ...this.posts,
        ...res,
      ];
    },
  },
};
