<template lang="pug">
  vk-grid.share-button-group-wrapper
    ButtonLine.uk-margin-small-right(:post="post")
    ButtonFb.uk-margin-small-right(:post="post")
    ButtonTweet.uk-margin-small-right(:post="post")
    ButtonWechat(:post="post")
</template>

<script>
import ButtonFb from './ButtonFb.vue';
import ButtonLine from './ButtonLine.vue';
import ButtonTweet from './ButtonTweet.vue';
import ButtonWechat from './ButtonWechat.vue';

export default {
  components: {
    ButtonFb,
    ButtonLine,
    ButtonTweet,
    ButtonWechat,
  },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style lang="scss">
.share-button-group-wrapper {
  margin-left: 0px !important;

  .uk-grid + .uk-grid, .uk-grid > .uk-grid-margin, * + .uk-grid-margin {
    margin-top: 10px !important;
  }
}
</style>
