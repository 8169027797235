export default {
  // Home, Nav, Footer, Utils
  HOME: '首頁',
  CATALOGUE: '影片',
  CHANNEL: '頻道',
  SHOPPING: '購物',
  EVENTS: '活動',
  POSTS: '文章',
  RELEASE: '發佈',
  FOUNDATION: '基金會',
  SIGN_IN: '登入',
  LANG: '語言',
  DOWNLOAD: '下載',
  ABOUT: '關於',
  CONTACT_US: '聯絡我們',
  TERMS_AND_CONDITION: '服務條款',
  PRIVACY: '隱私政策',
  SCROLL_DOWN: 'SCROLL DOWN',
  READ_MORE: 'READ MORE',
  VIEW_ALL: 'View All',
  LOAD_MORE: 'Load More',
  COPYRIGHT: '© 2019 CNEX Studio Corp. All Rights reserved.',
  SLOGAN_1: '給下一代太平盛世的備忘錄',
  SLOGAN_2: 'LOOKING FOR CHINESE 2.0',
  SEARCH: '搜尋',
  SEARCH_RESULT: '搜尋結果',
  SEARCH_RESULT_TITLE: '{count} <i class="text-xxlarge">Results for</i> {filter}',
  STATUS: '狀態',
  GENRE: '類型',
  COUNTRY: '出品地區',
  KEYWORDS: '關鍵字',
  PRODUCTION: '製片公司',
  COMPANY: '出品公司',
  DIRECTOR: '導演',
  TYPE: '種類',
  QUALITY: '畫質',
  PUBLISH_DATE: '出品日期',
  COMMENT: '留言評論',
  MONDAY: '星期一',
  TUESDAY: '星期二',
  WEDNESDAY: '星期三',
  THURSDAY: '星期四',
  FRIDAY: '星期五',
  SATURDAY: '星期六',
  SUNDAY: '星期日',
  MONDAY_SHORT: '一',
  TUESDAY_SHORT: '二',
  WEDNESDAY_SHORT: '三',
  THURSDAY_SHORT: '四',
  FRIDAY_SHORT: '五',
  SATURDAY_SHORT: '六',
  SUNDAY_SHORT: '日',
  YEAR: '年',
  MONTH: '月',
  DATE: '日',
  AWARD: '得獎紀錄',
  RUN_TIME: '片長',
  SERIAL_NUMBER: '產品編號',
  SIGN_UP_FREE_SHIPPING_FEE: '立即加入會員  享有免運費優惠',
  RELATED_PRODUCT: '相關商品',
  LAST_VIDEOS: '最新影片',
  FEATURES: '編輯精選',
  EMAIL: '電子郵件',
  USERNAME: '使用者名稱',
  COMMON_PROBLEM: '常見問題',
  PASSWORD: '密碼',
  CONFIRM_PASSWORD: '確認密碼',
  OR: '或',
  LOGIN: '登入',
  SIGN_UP: '立即註冊',
  WATCH_TRAILER: '觀看試映片',
  NOW_MOD: 'CNEX紀實頻道 (中華電信MOD第600台)',
  NOW_PLAYING: '現正播放',
  LANGUAGE: '語言',
  LANG_EN: 'ENGLISH',
  LANG_CH: '繁體中文',
  LANG_CN: '简体中文',
  FINISHED_VIDEO: 'CNEX出品',
  PROGRESS_VIDEO: '製作中',
  AGENT_VIDEO: '代理發行',
  TAG: '標籤',
  SHARE_BY_LINE: 'LINE分享',
  SHARE_BY_TWEET: 'Tweet分享',
  SHARE_BY_FB: 'FB分享',
  SHARE_BY_WECHAT: 'WECHAT分享',
  FB_LIKE: '讚',
  FB_SHARE: '分享',
  TAIPEI: '台北',
  BEIJING: '北京',
  HONG_KONG: '香港',
  SITE:'區域',
  SITE_TW: '台北',
  SITE_CH: '北京',
  SITE_HK: '香港',

  // Catalogue Home
  CATA_FEATURES: '推薦影片',
  CATA_PICK_UP: '<i class="text-white">P</i>ICK&nbsp;<i class="text-white">U</i>P',

  // Catalogue Single
  CATA_INFO_TITLE: '片名',
  CATA_VIDEO_OFFICIAL_SITE: '影片官方網站',
  CATA_LICENSE: '取得公播授權',
  CATA_LICENSE_DESCRIPTION: '取得公播授權',
  CATA_VIDEO_AGENT: '影片代理廠商',
  CATA_TVOD: '隨選影片平台',
  CATA_INTRODUCTION: '影片介紹',
  PLACE_OF_ORIGIN: '出品地區',
  CATA_TEAM: '主創團隊',
  CATA_STATUS: '來源與狀態',
  CATA_SEARCH_STATUS: '來源與狀態',

  // Channel Home
  CHANNEL_FEATURES: '接著播放',
  CHANNEL_PROGRAM_LIST: '節目表',
  CHANNEL_MONTH_UNIT: '月',
  CHANNEL_DATE_UNIT: '日',

  // Channel Single
  CHANNEL_INTRODUCTION: '影片介紹',
  CHANNEL_PLAY_DATE: '播放時間',

  // Shopping Home
  SHOP_LATEST: '最新上架',
  SHOP_HOT: '熱賣商品',
  SHOP_ADD_TO_CART: '加入購物車',
  SHOP_NOT_YET_SOLD: '尚未開賣',
  SHOP_PRODUCT_AGENT_SITE: '商品代銷網站',
  SHOP_PRODUCT_DESC: '商品描述',
  SHOP_SPEC: '商品規格',
  SHOP_PURCHASE_NOTE: '購買須知',
  SHOP_OFFICAL_SITE: '商品官網連結',

  SHOP_TITLE: '商品名稱',
  SHOP_RELEASE: '發行日期',
  SHOP_SERIAL: '參考編號',

    // Post
  POST_PREV: 'PREV | 上一篇',
  POST_NEXT: 'NEXT | 下一篇',
  POST_DATE: '文章日期',
  LAST_POST: '最新文章',
  POST_FTU: '編輯精選',
  POST_SRC: '文章來源：',
  POST_ORG: '原創',

  // Event
  EVENT_LATEST_EVENT: '最新活動',
  EVENT_NOW_ON_SALE: '售票中',
  EVENT_COMMING_SOON: '即將開賣',
  EVENT_INTRODUTION: '活動說明',
  EVENT_MAP: '地圖位置',
  EVENT_APPLY: '報名 / 售票',
  EVENT_OTHER_SITE: '其他代理售票網站',
  EVENT_APPLY_DATE: '報名日期',
  EVENT_EVENT_DATE: '活動日期',
  EVENT_SOLD_OUT: '售票已結束',
  EVENT_ACTIVING: '活動進行中',
  EVENT_NOT_YET_START: '尚未開賣',
  EVENT_DATE_TO: '至',
  EVENT_PLACE: '活動地點',

  // Memter
  MEMBER_CENTRE: '會員中心',
  MEMBER_CHANGE_PASSWORD: '變更新密碼',
  MEMBER_CONFIRM_PASSWORD: '確認變更新密碼',
  MEMBER_CONFIRM: '確認修改',
  MEMBER_CANCEL: '取消變更',
  MEMBER_SUBSCRIBE_PAPER: '訂閱電子報',

  // Contact Us
  CONTACT_NAME: '姓名',
  CONTACT_TITLE: '標題',
  CONTACT_CONTENT: '內容',
  CONTACT_CONFIRM: '確認送出',
  CONTACT_SUCCESS: '您的問題已成功送出，我們將有專人儘速與您聯繫！',
  CONTACT_FAIL: '系統出現異常，請稍候再試',
  CONTACT_GOAL: '聯絡目的',
  CONTACT_GOAL_CATA: '發行',
  CONTACT_GOAL_CHANNEL: '頻道',
  CONTACT_GOAL_SHOP: '購物',
  CONTACT_GOAL_POST: '文章',
  CONTACT_GOAL_EVENT: '活動',
  CONTACT_GOAL_FOUNDATION: '基金會',
  CONTACT_GOAL_WEBSITE: '網站',
  CONTACT_GOAL_OTHERS: '其他',


  // Download
  FILE_DOWNLOAD: '檔案下載',
  NO_MORE_FILE: 'No More File',
  UPLOAD_DATE: '上傳日期',

  // Login
  LOGIN_MEMBER_SIGN_IN: '會 員 登 入',
  LOGIN_USE_SOCIAL_MEDIA_SIGN_IN: '使用以下社群帳號登入',
  LOGIN_FACEBOOK_SIGN_IN: 'Facebook 登入',
  LOGIN_GOOGLE_SIGN_IN: 'Google 登入',
  LOGIN_NOT_MEMBER_YET: '還不是會員？',
  LOGIN_SIGN_UP_NOW: '立即註冊',
  LOGIN_EVEN_BEEN_MEMBER: '我曾經註冊過，但我',
  LOGIN_FORGET_PASSWORD: '忘記密碼',
  LOGIN_JOIN_MEMBER: '加入會員',
  LOGIN_SIGNED_UP: '我已經註冊過，',
  LOGIN_LOGIN_DIRECTLY: '直接登入',
  LOGIN_ENTER_EMAIL: '請輸入您的註冊過的Email',
  LOGIN_RETRIEVE_PASSWORD: '索取新密碼',
  LOGIN_TERMS_AND_CONDITION: '隱私權政策',
  LOGIN_FAILED_MSG: '登入資訊錯誤',

  // Validator
  VALIDATOR_MSG_CONFIRM_PASSWORD: '確認密碼 與 密碼 不符',

  // FAQ
  FAQ: '常 見 問 題',
  FAQ_CA: '問題分類 :',
  FAQ_0: '全部',
  FAQ_A: '會員',
  FAQ_B: '影片(發行)',
  FAQ_C: '頻道(節目表)',
  FAQ_D: '購物',
  FAQ_E: '文章',
  FAQ_F: '活動',
  FAQ_G: '網站(操作)',
  FAQ_H: '基金會',
  FAQ_I: '其他',
};
