<template lang="pug">
  router-link.uk-position-relative.uk-display-block.link-no-underline.link-card.card-item.event-post-card.uk-margin-remove(
    :to='{ name: "eventsSingle", params: { id: post.post_id } }'
  )
    .uk-background-cover(:style="{ backgroundImage: `url(${post.img_url})`}")
      span.uk-position-center.text-small.text-white {{ $t('READ_MORE') }}
    .date
      span {{ dateStart }}
      span(
        v-if="dateEnd"
        style="margin: 0 5px;"
      ) ~
      span(
        v-if="dateEnd"
      ) {{ dateEnd }}
    .title {{ post.title }}
    button.uk-button.uk-button-default.uk-margin-small-top(
      :class="{\
        'uk-button-primary': status,\
      }"
      style="font-size:16px;"
    )
      //- | {{ status ? $t('EVENT_NOW_ON_SALE') : $t('EVENT_COMMING_SOON') }}
      |  {{ this.post.status }}
      icon.icon-position-fixed.uk-margin-small-left.icon(type='arrow-right' width='12' height='12' )
</template>

<script>
import { eventsStatus } from '../utils/eventsStatus';
/*
interface IPost {
  post_id: number;
  img_url: string;
  date_start: string;
  title: string;
  status: string; // '0', '1'
}
*/

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  computed: {
    status() {
      // return Boolean(parseInt(this.post.status));
      return eventsStatus.onSale.includes(this.post.status);
    },
    dateStart() {
      const date = moment(this.post.date_start);
      return date.isValid()
        ? date.format('YYYY/MM/DD')
        : this.post.date_start;
    },
    dateEnd() {
      const date = moment(this.post.date_end);
      return date.isValid()
        ? date.format('YYYY/MM/DD')
        : '';
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_variable.scss';
@import '../../sass/helper/_mixin.scss';

.link-card {
  margin-bottom: 40px !important;

  @include mq(640px) {
    margin-bottom: 20px !important;
  }
}

.uk-background-cover {
  padding-top: 70%;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #000;
  line-height: 35px;

  @include mq(960px) {
    font-size: 16px;
    line-height: 24px;
  }

  @include mq(640px) {
    font-size: 14px;
  }
}

.date {
  font-size: 16px;
  color: $primary;

  @include mq(960px) {
    font-size: 12px;
  }
}

button {
  font-size: 12px;
  &.uk-button-primary {
    color: #fff;
    .icon {
      filter: grayscale(1) brightness(8);
    }
  }
}
</style>
