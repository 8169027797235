<template lang="pug">
  .wrapper
    button(:class="buttonClass")
      slot
      icon.uk-margin-small-left.icon-arrow(
        :class="{ 'is-open': isOpen }"
        type="arrow-down" width="12" height="12"
      )
    vk-drop(
      ref="dropdown"
      :mode="mode"
      :boundary="boundary"
    )
      vk-card
        slot(name="content")
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: 'click',
    },
    boundary: {
      type: String,
      default: 'boundary',
    },
    buttonClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      classObserver: null,
      isOpen: false,
    };
  },
  async mounted() {
    await this.$nextTick();
    this.classObserver = new MutationObserver(this.onChangeDropdown);
    this.classObserver.observe(
      this.$refs.dropdown.$el,
      {
        attributes: true,
        attributeOldValue : true,
        attributeFilter: ['class'],
      },
    );
  },
  beforeDestroy() {
    this.classObserver = null;
  },
  methods: {
    onChangeDropdown(mutations) {
      const mutation = mutations[0];
      const newClasses = mutation.target.className;
      this.isOpen = newClasses.includes('uk-open');
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/sass/helper/_variable.scss';
@import '@/sass/helper/_mixin.scss';

.wrapper {
  position: relative;
}

button {
  position: relative;

  &:active, &:focus {
    color: $primary;
  }

  &:hover {
    &:focus {
      color: #fff;
    }
  }

  .icon-arrow {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all .2s ease-in-out;

    &.is-open {
      transform: translate(-50%, -50%) rotateZ(-180deg);
    }
  }
}

.uk-drop {
  width: 100%;
  margin-top: 0;
  .uk-card-body {
    padding: 0 10px;
    line-height: 38px;
    border: 1px solid $primary;
    border-top: 0;
  }
}
</style>
