<template lang="pug">
  .uk-background-muted.uk-padding-small.info-table
    table.uk-table.uk-table-small.uk-margin-remove-bottom
      tr(
        v-for="info in infoss"
        :key="info.label"
      )
        td.text-red.cnex_nowrap {{ info.label }}
        td
          div(
            v-for="value in info.values"
            :key="value"
          ) {{ value }}
</template>

<script>
/*
interface IInfos {
  label: string;
  values: string[];
}
*/

export default {
  props: {
    infos: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
      infoss : function() {
          let a = [];
          for (let i of this.infos) {
              if ((i.values[0] !== '') && (i.values[0] != null))
                  a.push(i);
          }
          return a;
      },
  }
}
</script>

<style lang="scss" scoped>
.info-table {
  border: none;
  background-color: transparent;
}
</style>
