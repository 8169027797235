<template lang="pug">
  div.cata-single-side-buttons
    a.uk-button.uk-button-default.uk-width-1-1(
      v-if="post.company_url"
      :href="post.company_url"
      target="_blank"
      rel="nopener"
    )
      icon.uk-margin-small-right(
        type="home-button"
        width="24"
        height="19"
      )
      | {{ $t('CATA_VIDEO_OFFICIAL_SITE') }}

    a.uk-button.uk-button-default.uk-width-1-1.uk-margin-small-top(
      v-if="post.public_license_desc"
      @click="toggleModal"
    )
      icon.uk-margin-small-right(
        type="house-key"
        width="24"
        height="19"
      )
      | {{ $t('CATA_LICENSE') }}

    //- a.uk-button.uk-button-default.uk-width-1-1.uk-margin-small-top(
    //-   v-if="post.agent_url"
    //-   :href="post.agent_url"
    //-   target="_blank"
    //-   rel="nopener"
    //- )
    //-   icon.uk-margin-small-right(
    //-     type="hand"
    //-     width="24"
    //-     height="19"
    //-   )
    //-   | {{ $t('CATA_VIDEO_AGENT') }}
    DropdownButton.dropdown-button(
      v-if="hasAgent"
      buttonClass="uk-button uk-button-default uk-width-1-1 uk-margin-small-top btn-other-site"
    )
      span
        icon.uk-margin-small-right(type="hand" width="20" height="19")
        | {{ $t('CATA_VIDEO_AGENT') }}

      template(slot="content")
        a.agent-item(
          v-for="agent in post.agent"
          :v-if="agent.agent_name && agent.agent_url"
          :key="agent.agent_name + agent.agent_url"
          :href="agent.agent_url"
          target="_blank"
        ) {{ agent.agent_name }}
    DropdownButton.dropdown-button(
      v-if="hasTVOD"
      buttonClass="uk-button uk-button-default uk-width-1-1 uk-margin-small-top btn-other-site"
  )
      span
        icon.uk-margin-small-right(type="video-camera" width="20" height="19")
        | {{ $t('CATA_TVOD') }}

      template(slot="content")
          a.agent-item(
              v-for="tvod in post.tvod"
              :v-if="tvod.tvod_name && tvod.tvod_url"
              :key="tvod.tvod_name + tvod.tvod_url"
              :href="tvod.tvod_url"
              target="_blank"
          ) {{ tvod.tvod_name }}
</template>

<script>
import DropdownButton from '../components/buttons/DropdownButton.vue';

export default {
  name: 'CatalogueSingleSideButtons',
  components: {
    DropdownButton,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  methods: {
    toggleModal() {
      this.$store.commit('modalCataloguePostLicenseToggle');
    },
  },
  computed: {
    hasAgent() {
      if (!this.post.agent) return false;
      if (!this.post.agent.length) return false;

      const validAgents = this.post.agent.filter(o => o.agent_name && o.agent_url);
      return !!validAgents.length;
    },
    hasTVOD() {
      if (!this.post.tvod) return false;
      if (!this.post.tvod.length) return false;

      const validTVOD = this.post.tvod.filter(o => o.tvod_name && o.tvod_url);
      return !!validTVOD.length;
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/sass/helper/_variable.scss';

.uk-button {
  padding: 7px 0;
  padding-left: 35%;
  text-align: left;
  font-size: 15px;
}

.agent-item {
  color: $primary;
  margin-right: 10px;
  font-size: 15px;
  text-decoration: underline;

  &::after{
    content: ',';
    margin-left: 5px;
  }

  &:nth-last-child(1) {
    &::after{
      content: none;
    }
  }
}

.dropdown-button {
  .uk-button {
    padding: 7px 0;
    text-align: left;
    font-size: 15px;
  }
}
</style>

<style lang="scss">
.cata-single-side-buttons {
  .dropdown-button {
    .uk-button {
      padding-top: 7px;
      padding-bottom: 7px;
      font-size: 15px;
    }
  }
}
</style>
