<template lang="pug">
  .watch-trailer-group.text-white(
    @click="click"
  )
    vk-icon.icon(icon="play-circle")
    .text {{ $t('WATCH_TRAILER') }}
</template>

<script>
export default {
  name: 'ButtonWatchTariler',
  methods: {
    click() {
      this.$emit('click');
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../sass/helper/_mixin.scss';

.watch-trailer-group {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  @include mq(640px) {
    justify-content: initial;
    margin-bottom: 15px;
  }

  .icon {
    margin-right: 10px;
  }
  
  .text {
    font-size: 15px;
    font-weight: bold;
  }
}
</style>
