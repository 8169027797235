<template lang="pug">
  vk-modal-full.video-modal-root(
    :show.sync="showModal"
    center
  )
    vk-modal-full-close(
      outside
      @click="toggleModal"
    )
    .modal-body
      .modal-content(
        class="uk-width-2-3@m uk-width-5-6"
      )
        .iframe-box(
          v-if="showModal && payload.videoUrl !== ''"
          v-html="payload.videoUrl"
        )
        //- iframe(
        //-   v-if="showModal && payload.videoUrl !== ''"
        //-   width="100%"
        //-   height="100%"
        //-   :src="`${payload.videoUrl}?rel=0`"
        //-   frameborder="0"
        //-   allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        //-   allowfullscreen=""
        //- )
</template>

<script>
import { MODAL_EVENT } from '../../utils/events';

/*
interface IPayload {
  videoUrl: string;
}
*/

export default {
  data() {
    return {
      showModal: false,
      payload: {},
    };
  },
  created() {
    this.$event.$on(MODAL_EVENT.SHOW_TRAILER, this.onOpen);
  },
  beforeDestroy() {
    this.$event.$off(MODAL_EVENT.SHOW_TRAILER, this.onOpen);
  },
  methods: {
    toggleModal() {
      this.showModal = false;
    },

    onOpen(payload) {
      this.showModal = true;
      this.payload = payload;
    },
  },
}
</script>

<style lang="scss">
@import '../../../sass/helper/_variable.scss';

.video-modal-root {
  .uk-modal-dialog {
    background: transparent;
  }

  .modal-body {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .iframe-box {
    position: relative;
    padding-top: calc(582 / 1034 * 100%);

    iframe {
      position: absolute;
      top: 0;
    }
  }
}
</style>
