
export async function apiGetFAQ(payload) {
  return await axios.get(
    `faq`,
    payload,
  );
}

export async function apiGetDownloadSearch(payload) {
  return await axios.get(
    `download/search`,
    payload,
  );
}

export async function apiGetDownloadList(payload) {
  return await axios.get(
    `download/list`,
    payload,
  );
}

export async function apiGetAbout(payload) {
  return await axios.get(
    `about`,
    payload,
  );
}

export async function apiGetPrivacy(payload) {
  return await axios.get(
    `privacy`,
    payload,
  );
}

export async function apiGetTerms(payload) {
  return await axios.get(
    `terms`,
    payload,
  );
}

export async function apiGetPage(id, payload) {
  return await axios.get(
    `page/${id}`,
    payload,
  );
}

/**
 * [
    {
      "seconds_home": 0,
      "seconds_home_bottom": 0,
      "seconds_catalogue": 0,
      "seconds_channel": 0,
      "seconds_shopping": 0,
      "seconds_post": 0,
      "seconds_event": 0
    }
  ]
*/
export async function apiGetSlideshowSetting() {
  return await axios.get(
    `setting/slideshow`,
    {
      meta: {
        lang: 'ch',
      },
    },
  );
}
