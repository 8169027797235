import { render, staticRenderFns } from "./ChannelPostPanel.vue?vue&type=template&id=3b1cf2bd&scoped=true&lang=pug&"
import script from "./ChannelPostPanel.vue?vue&type=script&lang=js&"
export * from "./ChannelPostPanel.vue?vue&type=script&lang=js&"
import style0 from "./ChannelPostPanel.vue?vue&type=style&index=0&id=3b1cf2bd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b1cf2bd",
  null
  
)

export default component.exports