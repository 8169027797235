<template lang="pug">
  .uk-container.layout.uk-margin-large-top.uk-margin-xlarge-bottom.contact-page-root
    ValidationObserver(ref="validator" v-slot="{ errors }")
      ul.uk-breadcrumb(class="uk-visible@s")
        li
          router-link( :to="{ name: 'home' }")
            icon.icon-position-fixed.uk-margin-small-right(type="home" width="15" height="12")
            | {{ $t('HOME') }}
        li {{ $t('CONTACT_US') }}

      .uk-margin-medium-top
        h1.uk-h2.text-red.uk-text-center {{ $t('CONTACT_US') }}

      .uk-margin-medium-top
        .uk-width-1-1(class="uk-visible@s")
          iframe.map-iframe(
            frameborder="0"
            style="border:0"
            allowfullscreen
            :src="mapurl"
          )

      .uk-margin-medium-top
        vk-grid(gutter="small")
          .uk-width-1-1(class="uk-width-1-3@s")
            .uk-form-controls
              ValidationProvider(
                rules="required"
                v-slot="{ errors }"
                vid="name"
                :name="$t('CONTACT_NAME')"
              )
                FloatInput(
                  :label="$t('CONTACT_NAME')"
                  v-model.trim="form.name"
                  required
                )

          .uk-width-1-1(class="uk-width-1-3@s")
            .uk-form-controls
              ValidationProvider(
                rules="required|email"
                v-slot="{ errors }"
                vid="email"
                :name="$t('EMAIL')"
              )
                FloatInput(
                  :label="$t('EMAIL')"
                  v-model.trim="form.email"
                  required
                )

          .uk-width-1-1(class="uk-width-1-3@s")
            .uk-form-controls
              ValidationProvider(
                rules="required"
                v-slot="{ errors }"
                vid="goal"
                :name="$t('CONTACT_GOAL')"
              )
                FloatInput(
                  :label="$t('CONTACT_GOAL')"
                  v-model.trim="form.goal"
                  type="select"
                  required
                )
                  template(#options)
                    option(
                      v-for="o in goals"
                      :key="o"
                      :value="$t(o, 'ch')"
                    ) {{ $t(o) }}

          .uk-width-1-1.uk-margin-medium-top
            .uk-form-controls
              ValidationProvider(
                rules="required"
                v-slot="{ errors }"
                vid="subject"
                :name="$t('CONTACT_TITLE')"
              )
                FloatInput(
                  :label="$t('CONTACT_TITLE')"
                  v-model.trim="form.subject"
                  required
                )

          .uk-width-1-1.uk-margin-medium-top
            .uk-form-controls
              ValidationProvider(
                rules="required"
                v-slot="{ errors }"
                vid="content"
                :name="$t('CONTACT_CONTENT')"
              )
                FloatInput(
                  :label="$t('CONTACT_CONTENT')"
                  v-model.trim="form.content"
                  type="textarea"
                  rows="10"
                  required
                )

          .uk-width-1-1
            div(v-for="(v, k) in errors" :key="k")
              .input-error(v-show="v[0]") * {{ v[0] }}

          .uk-width-1-1.uk-text-center.uk-margin-medium-top
            button.uk-button.uk-button-primary.uk-button-large.uk-width-1-1.text-remove-spacing.text-medium(
              class="uk-width-1-3@s"
              @click="onSubmit"
            ) {{ $t('CONTACT_CONFIRM') }}
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import { apiContactUs } from '../apiRequest/user';

import FloatInput from '../components/form/FloatInput.vue';

const GOALS = [
  'CONTACT_GOAL_CATA',
  'CONTACT_GOAL_CHANNEL',
  'CONTACT_GOAL_SHOP',
  'CONTACT_GOAL_POST',
  'CONTACT_GOAL_EVENT',
  'CONTACT_GOAL_FOUNDATION',
  'CONTACT_GOAL_WEBSITE',
  'CONTACT_GOAL_OTHERS',
];

const initForm = () => ({
  name: '',
  email: '',
  subject: '',
  content: '',
  goal: '',
});

export default {
  name: 'Contact',
  components: {
    ValidationProvider,
    ValidationObserver,
    FloatInput,
  },
  data () {
    return {
      form: initForm(),
    }
  },
  async created() {
  },
  methods: {
    async onSubmit() {
      const valid = await this.$refs.validator.validate();
      if (!valid) return;

      try {
        const res = await apiContactUs(this.form);
        if (!res || !res[0]) {
          alert(this.$t('CONTACT_FAIL'));
          return;
        }
        this.form = initForm();
        alert(this.$t('CONTACT_SUCCESS'));
        this.$refs.validator.reset();
      } catch (e) {
        console.error(e);
        alert(this.$t('CONTACT_FAIL'));
      }
    },
  },
  computed: {
    goals() {
      return GOALS;
    },
    mapurl() {
        let s = this.$route.query.site;
        if (s === 'h')
            return 'https://snazzymaps.com/embed/242912';
        else if (s === 'c')
            return 'https://snazzymaps.com/embed/242923';
        else
            return 'https://snazzymaps.com/embed/242924';
    }
  },
  head: {
    title: {
      inner: ''
    },
  },
  mapurl: {

  }
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_variable.scss';

input, textarea {
  &:active, &:focus {
    border-bottom: 1px solid $primary;
  }
}

label {
  font-size: 10px;
  color: $primary;
}

textarea {
  height: 300px;
}

.map-iframe {
  width: 100%;
  height: 454px;
}
</style>

<style lang="scss">
.contact-page-root {
  textarea {
    height: 300px;
    padding-top: 0;
  }
}
</style>
