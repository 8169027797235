<template lang="pug">
  #app-layout(
    :class="{\
      'lang-en': $i18n.locale === 'en',\
      'lang-ch': $i18n.locale === 'ch',\
    }"
  )
    HeaderTop
    transition(name='fade' mode="out-in")
      router-view(
        v-if="gotSlideSettings"
      )
    Footer
    transition(name='fade')
      p.button-back-top(v-show='isButtonBackTopActive')
        a(href='#', v-vk-scroll)
    p.button-question
      a.link-opacity(@click='modalQuestionToggle')
        icon(type='question' width='46' height='46')

    ModalLogin
    ModalSignUp
    ModalPassword
    ModalMobileNav
    ModalQuestion
    ModalViedo
</template>

<script>
import _ from 'lodash';
import HeaderTop from '../partials/HeaderTop.vue'
import Footer from '../partials/Footer.vue'
import ModalLogin from '../partials/modalLogin.vue'
import ModalSignUp from '../partials/modalSignUp.vue'
import ModalPassword from '../partials/modalPassword.vue'
import ModalMobileNav from '../partials/modalMobileNav.vue'
import ModalQuestion from '../partials/modalQuestion.vue'
import ModalViedo from '../components/modals/ModalViedo.vue';

import { apiGetWechatToken } from '../apiRequest/wechat';
import { ACTIONS } from '../modules/auth';

export default {
  name: 'layout',
  components: {
    HeaderTop,
    Footer,
    ModalLogin,
    ModalSignUp,
    ModalPassword,
    ModalMobileNav,
    ModalQuestion,
    ModalViedo,
  },
  data () {
    return {
      winH: typeof window !== 'undefined' ? window.innerHeight : 0,
      isButtonBackTopActive: false,
      gotSlideSettings: false,
    };
  },
  async created() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener('resize', this.onResize);
    }
    this.$store.commit('windowSize/updateWidth', window.innerWidth);

    try {
      await this.$store.dispatch('slideshow/getPlaySpeeds');
    } finally {
      this.gotSlideSettings = true;
    }

    const isSignin = this.$store.dispatch(`auth/${ACTIONS.IS_SINGIN}`);
  },
  destroyed() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.handleSroll);
      window.removeEventListener('resize', this.onResize);
    }
  },
  async mounted() {
    gapi.load('auth2', () => {
      gapi.auth2.init({
        client_id: '953158717115-m9dcmr42hgnpva69q5atpqqf36o9ln5g.apps.googleusercontent.com',
        scope: 'profile email',
      })
        .then(res => {
          console.log('gapi inited');
        });
    });
  },
  methods: {
    handleScroll(event){
      if( event.scrollY > this.winH / 2.5 ) {
        this.isButtonBackTopActive = true;
      } else {
        this.isButtonBackTopActive = false;
      }
    },
    modalClose(){
      this.$store.commit('modalClose');
    },
    modalQuestionToggle(){
      this.$store.commit('modalQuestionToggle');
    },
    onResize: _.debounce(function() {
      this.$store.commit('windowSize/updateWidth', window.innerWidth);
    }, 500),
  },
  computed: {
    modalState() {
      return this.$store.state.modals;
    },
  },
  watch: {
    // 手機版選單點擊後關閉
    $route(to, from){
      setTimeout(() => {
        this.modalClose();
      }, 400);
    }
  }
  // head: {
  //   title: {
  //     inner: ''
  //   },
  // },

}
</script>

<style lang="scss">
@import '../../sass/helper/_mixin.scss';

.button-question {
  @include mq-range(1055px, 642px) {
    bottom: 5rem;
  }
}
</style>
