<template lang="pug">
  Loading(v-if="isLoading")
  .uk-container.layout.uk-margin-large-top(v-else)
    //- <Breadcrumb>
    ul.uk-breadcrumb(class='uk-visible@s')
      li
        router-link( :to='{ name: "home" }')
          icon.icon-position-fixed.uk-margin-small-right(type="home" width="15" height="12")
          | {{ $t('HOME') }}
      li
        router-link( :to='{ name: "catalogueHome" }') {{ $t('CATALOGUE') }}
      li {{ $t('SEARCH_RESULT') }}
    //- </Breadcrumb>
    //- <Search>
    SearchForm(
      :optionSets="searchOptionSets"
      v-model="searchForm"
      @onSearch="onSearch"
    )
    //- </Search>
    .uk-margin-large-top
      h1.uk-heading-line.uk-text-center.text-red
        span(v-html="$t('SEARCH_RESULT_TITLE', { count: posts.length, filter: oldSearchForm.keyword })")

      ul.uk-list.uk-margin-large-top.uk-margin-bottom
        li.uk-margin-medium-bottom(v-for="post in posts")
          router-link.with-link-card(:to="{ name: 'catalogueSingle', params: { id: post.post_id } }")
            vk-grid(gutter='small')
              div(class='uk-width-1-3@s uk-width-2-5')
                .link-card.uk-display-block.uk-margin-remove
                  .uk-background-cover(
                    :style='{ backgroundImage: "url("+ post.img_url +")"}'
                    style="padding-top: calc( 269 / 404 * 100% )"
                  )
                    span.uk-position-center.text-small.text-white {{ $t('READ_MORE') }}
              div(class='uk-width-2-3@s uk-width-3-5')
                h2.uk-margin-remove.text-xxlarge
                  router-link.text-dark.link-primary.link-no-underline(
                    :to="{ name: 'catalogueSingle', params: { id: post.post_id } }"
                  ) {{ post.title }}
                p.text-xlarge-x.uk-margin-remove.text-remove-spacing {{ post.title_en }}
                p.uk-margin-remove-bottom.uk-margin-small-top.text-xsmall-s.text-remove-spacing
                  | {{ post.director }}
                  br
                  | {{ post.date }}    |    {{ post.time }}    |    {{ post.country }}
                p.text-dark.text-small.uk-margin-small-bottom.uk-margin-small-top(class='uk-visible@m')
                  | {{ post.detail | removeHtmlTag }}
                router-link.text-red.link-no-underline.link-dark.btn-read-more(
                  :to='{ name: "catalogueSingle", params: { id: post.post_id } }'
                  class='uk-visible@s'
                )
                  | {{ $t('READ_MORE') }}
                  icon.uk-margin-small-left(type="arrow-right" width="7" height="11")

      ButtonLoadMore(
        v-if="!noMore"
        @click="getMorePost"
      )
</template>
<script>
import { CatalogueSearch } from '../mixins/CatalogueSearch';
import { apiGetCataSearch, apiGetCataList } from '../apiRequest/catalogue';

import SearchForm from '../components/SearchForm.vue'
import ButtonLoadMore from '../components/buttons/ButtonLoadMore.vue';

export default {
  name: 'CatalogueList',
  mixins: [CatalogueSearch],
  components: {
    SearchForm,
    ButtonLoadMore,
  },
  data () {
    return {
      posts: [],
      searchForm: {},
      oldSearchForm: {},
      noMore: false,
      isLoading: false,
    }
  },
  async created() {
    try {
      this.isLoading = true;
      await this.init(this.$route.query);
    } finally {
      this.isLoading = false;
    }
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    this.init(to.query);
  },
  methods: {
    async init(query) {
      this.oldSearchForm = { ...query };
      this.searchForm = { ...query };

      await Promise.all([
        this.getPosts({ params: query }).then(res => { this.posts = res; }),
      ]);
    },

    onSearch() {
      this.$router.replace({ query: this.searchForm });
    },

    async getMorePost() {
      const payload = {
        params: {
          offset: this.posts.length,
          ...this.searchForm,
          ...this.$route.query
        },
      };

      const res = await this.getPosts(payload);

      this.posts = [
        ...this.posts,
        ...res,
      ];
    },

    async getPosts(payload) {
      try {
        const res = await apiGetCataList({...payload});
        if (!res) return [];

        this.noMore = !res.length;
        return res;
      } catch (e) {
        console.log(e);
        this.noMore = true;
        return [];
      }
    },
  },
  computed: {
  },
  filters: {
    removeHtmlTag(detail) {
      const maxLen = 125;
      const div = document.createElement('div');
      div.innerHTML = detail;
      const text = div.textContent || div.innerText || '';

      return text.length > maxLen
        ? `${text.slice(0, maxLen)}...`
        : text;
    },
  },
  head: {
    title: {
      inner: ''
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-read-more {
  font-weight: bold;
}
</style>
