export default {
  // Home, Nav, Footer, Utils
  HOME: 'Home',
  CATALOGUE: 'Catalogue',
  CHANNEL: 'Channel',
  SHOPPING: 'Shopping',
  EVENTS: 'Events',
  POSTS: 'Posts',
  SIGN_IN: 'Sign in',
  LANG: 'Lang',
  DOWNLOAD: 'Download',
  ABOUT: 'About',
  CONTACT_US: 'Contact us',
  TERMS_AND_CONDITION: 'Terms & Condition',
  PRIVACY: 'Privacy',
  SCROLL_DOWN: 'Scorll down',
  READ_MORE: 'READ MORE',
  VIEW_ALL: 'View All',
  LOAD_MORE: 'Load More',
  COPYRIGHT: '© 2019 CNEX Studio Corp. All Rights reserved.',
  SLOGAN_1: '真‧見‧識‧無限可能',
  SLOGAN_2: 'Integrity‧Vision‧Knowledge‧Possibilities',
  SEARCH: 'Search',
  SEARCH_RESULT: 'Search Results',
  SEARCH_RESULT_TITLE: '{count} Results for',
  KEYWORDS: 'Keywords',
  STATUS: 'Status',
  GENRE: 'Categories',
  COUNTRY: 'Region',
  PRODUCTION: 'Producer',
  COMPANY: 'Presented By',
  DIRECTOR: 'Director',
  TYPE: 'Categories',
  QUALITY: 'Quality',
  PUBLISH_DATE: 'Release Date',
  COMMENT: 'Comment',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
  YEAR: 'Year',
  MONTH: 'Month',
  DATE: 'Date',
  AWARD: 'Awards',
  RUN_TIME: 'Running Time',
  SERIAL_NUMBER: 'Serial Number',
  SIGN_UP_FREE_SHIPPING_FEE: 'Sing up & Free Shipping Fee',
  RELATED_PRODUCT: 'Related Products',
  LAST_VIDEOS: 'Latest Videos',
  FEATURES: 'Features',
  EMAIL: 'E-Mail',
  USERNAME: 'Username',
  COMMON_PROBLEM: 'Common Problems',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  OR: 'or',
  LOGIN: 'Login',
  SIGN_UP: 'Register Now',
  WATCH_TRAILER: 'Watch Trailer',
  NOW_MOD: 'CNEX DOC Channel (MOD Channel 600)',
  NOW_PLAYING: 'Now Playing',
  LANG_EN: 'ENGLISH',
  LANG_CH: '繁體中文',
  LANG_CN: '简体中文',
  FINISHED_VIDEO: 'Our Productions',
  PROGRESS_VIDEO: 'In Production',
  AGENT_VIDEO: 'CNEX-Distributed',
  TAG: '标签',
  SHARE_BY_LINE: '用LINE传送',
  SHARE_BY_TWEET: 'Tweet',
  SHARE_BY_FB: '分享',
  SHARE_BY_WECHAT: 'WECHAT分享',
  FB_LIKE: '赞',
  FB_SHARE: '分享',
  TAIPEI: 'TAIPEI',
  BEIJING: 'BEIJING',
  HONG_KONG: 'HONG KONG',
  SITE:'SITE',
  SITE_TW: 'TAIPEI',
  SITE_CH: 'BEIJING',
  SITE_HK: 'HONG KONG',

  // Catalogue Home
  CATA_FEATURES: 'Features',
  CATA_PICK_UP: '<i class="text-white">P</i>ICK&nbsp;<i class="text-white">U</i>P',

  // Catalogue Single
  CATA_INFO_TITLE: 'Title',
  CATA_VIDEO_OFFICIAL_SITE: 'Official Website',
  CATA_LICENSE: 'Get Screening License',
  CATA_LICENSE_DESCRIPTION: 'Get Screening License',
  CATA_VIDEO_AGENT: 'Agent',
  CATA_TVOD: 'TVOD',
  CATA_INTRODUCTION: 'Synopsis',
  PLACE_OF_ORIGIN: 'Region',
  CATA_TEAM: 'Crew',
  CATA_STATUS: 'Status',
  CATA_SEARCH_STATUS: 'Source and Status',

  // Channel Home
  CHANNEL_FEATURES: 'Next On',
  CHANNEL_PROGRAM_LIST: 'Program List',

  // Channel Single
  CHANNEL_INTRODUCTION: 'Introduction',
  CHANNEL_PLAY_DATE: 'Play Date',

  // Shopping Home
  SHOP_LATEST: 'Latest',
  SHOP_HOT: 'Hot',
  SHOP_ADD_TO_CART: 'Add to Cart',
  SHOP_NOT_YET_SOLD: 'Not Yet Sold',
  SHOP_PRODUCT_AGENT_SITE: 'Online Shopping Site',
  SHOP_PRODUCT_DESC: 'Item Description',
  SHOP_SPEC: 'Item Specification',
  SHOP_PURCHASE_NOTE: 'Shopping Notes',
  SHOP_OFFICAL_SITE: 'Official Site',

  SHOP_TITLE: 'Item Name',
  SHOP_RELEASE: 'Release Date',
  SHOP_SERIAL: 'Ref. No.',

  // Post
  POST_PREV: 'PREV',
  POST_NEXT: 'NEXT',
  LAST_POST: 'Latest Posts',
  POST_FTU: 'Featured Posts',
  POST_SRC: 'Article Source : ',
  POST_ORG: 'Original',

  // Event
  EVENT_LATEST_EVENT: 'Latest Events',
  EVENT_NOW_ON_SALE: 'NOW ON SALE',
  EVENT_COMMING_SOON: 'COMMING SOON',
  EVENT_INTRODUTION: 'Event Introduction',
  EVENT_MAP: 'Map',
  EVENT_APPLY: 'Apply/Sale',
  EVENT_OTHER_SITE: 'Other Sites',
  EVENT_APPLY_DATE: 'Apply Date',
  EVENT_EVENT_DATE: 'Event Date',

  // Memter
  MEMBER_CENTRE: 'Member Centre',
  MEMBER_CHANGE_PASSWORD: 'Change New Password',
  MEMBER_CONFIRM_PASSWORD: 'Confirm New Password',
  MEMBER_CONFIRM: 'Confirm',
  MEMBER_CANCEL: 'Cancel',

  // Contact Us
  CONTACT_NAME: 'Name',
  CONTACT_TITLE: 'Title',
  CONTACT_CONTENT: 'Content',
  CONTACT_CONFRIM: 'Confirm',
  CONTACT_GOAL: 'Purpose',
  CONTACT_GOAL_CATA: 'Catalogue',
  CONTACT_GOAL_CHANNEL: 'Channel',
  CONTACT_GOAL_SHOP: 'Shopping',
  CONTACT_GOAL_POST: 'Posts',
  CONTACT_GOAL_EVENT: 'Events',
  CONTACT_GOAL_FOUNDATION: 'Foundation',
  CONTACT_GOAL_WEBSITE: 'Website',
  CONTACT_GOAL_OTHERS: 'Others',

  // Download
  FILE_DOWNLOAD: 'File Download',
  NO_MORE_FILE: 'No More File',

  // Login
  LOGIN_MEMBER_SIGN_IN: 'Member Sign In',
  LOGIN_USE_SOCIAL_MEDIA_SIGN_IN: 'Use social media account sign in',
  LOGIN_FACEBOOK_SIGN_IN: 'Facebook Sign In',
  LOGIN_GOOGLE_SIGN_IN: 'Google Sign In',
  LOGIN_NOT_MEMBER_YET: 'Not Member Yet?',
  LOGIN_SIGN_UP_NOW: 'Sign Up Now',
  LOGIN_EVEN_BEEN_MEMBER: 'Even been member, but I',
  LOGIN_FORGET_PASSWORD: 'Forget Password',
  LOGIN_JOIN_MEMBER: 'Join Member',
  LOGIN_SIGN_UP_ED: 'I have signed up',
  LOGIN_LOGIN_DIRECTLY: 'Login',
  LOGIN_FORGET_PASSWORD_REMIND: 'Please enter your Email which you use to sign up to retrieve password',
  LOGIN_RETRIEVE_PASSWORD: 'Retrieve New Password',
  LOGIN_FAILED_MSG: 'Login failed',

  // FAQ
  FAQ: 'Frequently Asked Questions',
  FAQ_CA: 'Categories',
  FAQ_0: 'All',
  FAQ_A: 'Membership',
  FAQ_B: 'Catalogue(Production)',
  FAQ_C: 'Channel(Program List)',
  FAQ_D: 'Shopping',
  FAQ_E: 'Posts',
  FAQ_F: 'Events',
  FAQ_G: 'How To...',
  FAQ_H: 'Foundation',
  FAQ_I: 'Miscellaneous',
};
