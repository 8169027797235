<template lang="pug">
  Loading(v-if="isLoading")
  .uk-container.layout.uk-margin-large-top.uk-margin-xxlarge-bottom(v-else)
    ul.uk-breadcrumb(class="uk-visible@s")
      li
        router-link( :to="{ name: 'home' }")
          icon.icon-position-fixed.uk-margin-small-right(type="home" width="15" height="12")
          | {{ $t('HOME') }}
      li
        router-link( :to="{ name: 'postsHome' }") {{ $t('POSTS') }}
      li {{ post.title }}

    .uk-margin-large-top
      .title {{ post.title }}

      vk-grid.subtitle-bar.uk-margin-top(gutter="small")
        .info-wrapper(class="uk-width-1-2@m")
          .info-content.info__date-wrapper.uk-margin-right
            .info__icon
              icon(
                type="calendar"
                width="15"
                height="15"
              )
            .info__text {{ post.publish_date }}

          .info-content.info__date-wrapper.uk-margin-right
            .info__icon
              icon(
                type="layer"
                width="15"
                height="15"
              )
            .info__text {{ post.category }}

          .info-content.info__author-wrapper.uk-margin-right
            .info__icon
              icon(
                type="contract"
                width="15"
                height="15"
              )
            .info__text {{ post.author }}

          .info-content.info__views-wrapper.uk-margin-right
            .info__icon
              icon(
                type="view"
                width="15"
                height="15"
              )
            .info__text {{ post.views }}

        .sharing-button-group(class="uk-width-1-2@m")
          ButtonShareGroup(:post="post")

    .uk-margin-large-top
      .content(v-html="post.content")
      PostSrc(:post="post" :key="post.post_id")

    .uk-margin-large-top
      .tag-row
        .tag-row__icon
        .tag-row__tags
          button.uk-button-default(
            v-for="tag in tags"
            :key="tag"
            @click="onClickTag(tag)"
          ) {{ tag }}

    .uk-margin-top
      PostSingleRelated(:relatedPost="relatedPost")

    .uk-margin-top
      .fb-comments(
        :data-href="locationHref"
        data-width="100%"
        data-numposts="10"
      )
</template>
<script>
import {
  apiGetPostsSingle,
  apiGetPostsSingleRelated,
  apiPostPostsSingleViews,
} from '../apiRequest/posts';

import PostSingleRelated from '../components/PostSingleRelated.vue';
import PostSrc from '../components/PostSrc.vue';
import ButtonShareGroup from '../components/buttons/ButtonShareGroup.vue';

import {
  postSinglePost,
  postSingleRelatedPost,
} from '../mocks/postSinglePost';
/*
interface {
  post_id: 0;
  title: string;
  publish_date: string;
  author: string;
  views: string;
  content: string;
  tag: string;
}
*/

export default {
  name: 'PostSingle',
  components: {
    PostSingleRelated,
    ButtonShareGroup,
    PostSrc,
  },
  data () {
    return {
      post: {},
      relatedPost: {},
      isLoading: false,
    };
  },
  async created() {
    try {
      this.isLoading = true;
      await this.init(this.$route.params.id);
    } finally {
      this.isLoading = false;
    }
  },
  async beforeRouteUpdate(to, from, next) {
    this.init(to.params.id);

    next();
  },
  async mounted() {
    // await this.$nextTick();
    // FB.XFBML.parse();
  },
  methods: {
    async init(id) {
      this.postViews({ postId: id });

      await Promise.all([
        this.getPost(id),
        this.getRelatedPosts(id),
      ]);
      setTimeout(() => { FB.XFBML.parse(); }, 1000);
    },
    async getPost(id) {
      try {
        const res = await apiGetPostsSingle(id);
        this.post = res[0];
      } catch (e) {
        console.error(e);
        this.$router.push({ name: 'home' });
      }
    },
    async getRelatedPosts(id) {
      const res = await apiGetPostsSingleRelated(id);
      this.relatedPost = res[0];
    },
    async postViews(payload/** { postId } */) {
      apiPostPostsSingleViews(payload);
    },

    onClickTag(tag) {
      this.$router.push({
        name: 'postsList',
        query: { tag },
      });
    }
  },
  computed: {
    tags() {
      return this.post.tag
        ? this.post.tag.split(',')
        : [];
    },
    locationHref() {
      if (typeof window !== 'undefined') {
        return window.location.href;
      } else {
        return '';
      }
    },
  },
  head: {
    title: {
      inner: ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_mixin.scss';
@import '../../sass/helper/_variable.scss';

.title {
  font-size: 28px;
  font-weight: bold;

  @include mq(640px) {
    font-size: 22px;
  }
}

.subtitle-bar {
  border-bottom: 2px solid $primary;
  padding-bottom: 15px;
}

.info-wrapper {
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;

  @include mq(640px) {
    font-size: 14px;
  }

  > .info-content {
    display: inline-flex;
    align-items: center;
    color: $primary;

    .info__text {
      margin-left: 7px;
    }
  }
}

.content {
  line-height: 1.8;
  font-size: 18px;

  @include mq(960px) {
    font-size: 18px;
  }

  @include mq(640px) {
    font-size: 16px;
  }
}

.tag-row__tags {
  display: flex;
  > button {
    cursor: pointer;
    margin-right: 10px;
  }
}

.share-button-group-wrapper {
  justify-content: flex-end;

  @include mq(640px) {
    justify-content: flex-start;
  }
}
</style>
