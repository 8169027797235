<template lang="pug">
  router-link.uk-position-relative.uk-display-block.link-no-underline.link-card(
    :to="{ name: 'catalogueSingle', params: { id: post.post_id } }"
  )
    .uk-background-cover(:style="{ backgroundImage: `url(${post.img_url})` }")
      span.uk-position-center.text-small.text-white {{ $t('READ_MORE') }}
    h3.uk-margin-small-top.uk-margin-remove-bottom.text-bold {{ post.title }}
    p.uk-margin-remove.text-gray.text-xlarge-x.text-no-spacing {{ post.title_en }}
    vk-grid(
      gutter="small"
      style="margin-top: .7rem;"
      v-if="post.short !== ''"
    )
      div.uk-width-expand
        p.text-dark.text-no-spacing(v-html="post.short")
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

