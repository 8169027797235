<template lang="pug">
  Loading(v-if="isLoading")
  .uk-container.layout.uk-margin-large-top.channel-list(v-else)
    ul.uk-breadcrumb(class="uk-visible@s")
      li
        router-link(:to="{ name: 'home' }")
          icon.icon-position-fixed.uk-margin-small-right(type="home" width="15" height="12")
          | {{ $t('HOME') }}
      li
        router-link(:to="{ name: 'channelHome' }") {{ $t('CHANNEL') }}
      li {{ $t('SEARCH_RESULT') }}

    SearchForm(
      :optionSets="searchOptionSets"
      v-model="searchForm"
      @onSearch="onSearch"
    )
      template(#withSearchButton)
        .date-picker-input-wrapper
          span(@click="showDatePickerPop")
            icon(
              type="calendar-search"
              width="30"
              height="30"
            )
          date-picker(
            ref="datePicker"
            valueType="format"
            v-model="datePicker"
            @change="onChangeDatePicker"
          )

    .uk-margin-large-top
      h1.uk-heading-line.uk-text-center.text-red
        span(v-html="$t('SEARCH_RESULT_TITLE', { count: posts.length, filter: oldSearchForm.keyword })")

      vk-grid(class="uk-child-width-1-4@m uk-child-width-1-3@s uk-child-width-1-2")
        ChannelVideoCard(
          v-for="post in posts"
          :key="post.post_id"
          :post="post"
        )

      ButtonLoadMore(
        v-if="!noMore"
        @click="getMorePost"
      )
</template>
<script>
import { channelFeatures } from '../mocks/channelFeatures';

import { SearchOptionSet } from '../utils/helpers';
import { ChannelSearch } from '../mixins/ChannelSearch';
import { apiGetChannelSearch } from '../apiRequest/channel';

import SearchForm from '../components/SearchForm.vue'
import ChannelVideoCard from '../components/ChannelVideoCard.vue';
import ButtonLoadMore from '../components/buttons/ButtonLoadMore.vue';

export default {
  name: 'ChannelList',
  mixins: [ChannelSearch],
  components: {
    SearchForm,
    ChannelVideoCard,
    ButtonLoadMore,
  },
  data () {
    return {
      posts: [],
      searchForm: {},
      oldSearchForm: {},
      noMore: false,
      datePicker: '',
      isLoading: false,
    };
  },
  async created() {
    try {
      this.isLoading = true;
      await this.init(this.$route.query);
    } finally {
      this.isLoading = false;
    }
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    this.init(to.query);
  },
  methods: {
    async init(query) {
      this.oldSearchForm = { ...query };
      this.searchForm = { ...query };

      const { year, month, date } = query;
      const payload = {
        params: {
          keyword: query.keyword,
          date: year && month && date
            ? `${year}${month}${date}`
            : undefined,
        },
      };

      await Promise.all([
        this.getPosts(payload).then(res => { this.posts = res; }),
      ]);
    },

    onSearch() {
      this.$router.replace({ query: this.searchForm });
    },

    async getMorePost() {
      const { year, month, date, keyword } = this.searchForm;

      const payload = {
        params: {
          keyword,
          offset: this.posts.length,
          date: year && month && date
            ? `${year}${month}${date}`
            : undefined,
        },
      };

      const res = await this.getPosts(payload);

      this.posts = [
        ...this.posts,
        ...res,
      ];
    },

    async getPosts(payload) {
      const newParams = {
        limit: 12,
        ...payload.params,
      };
      payload.params = newParams;
      try {
        const res = await apiGetChannelSearch(payload);
        if (!res || !Array.isArray(res)) {
          this.noMore = true;
          return [];
        }

        this.noMore = !res.length;
        return res;
      } catch (e) {
        console.log(e);
        this.noMore = true;
        return [];
      }
    },
  },
  head: {
    title: {
      inner: ''
    },
  },
}
</script>

<style lang="scss" scoped>
.channel-list {
  .link-card {
    margin-left: 0;
    margin-right: 0;
  }

  .uk-grid > * {
    padding-left: 20px;
  }
}
</style>
