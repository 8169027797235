export async function apiGetCataSlideshows(payload) {
  return await axios.get(
    `slideshow/catalogue/home`,
    payload,
  );
}

export async function apiGetCataHomePosts(payload) {
  return await axios.get(
    `catalogue/home`,
    payload,
  );
}

export async function apiGetCataSearch(payload) {
  return await axios.get(
    `catalogue/search`,
    payload,
  );
}

export async function apiGetCataList(payload) {
  return await axios.get(
    `catalogue/list`,
    payload,
  );
}

export async function apiGetCataSingle(id, payload) {
  return await axios.get(
    `catalogue/single/${id}`,
    payload,
  );
}
