<template lang="pug">
  Loading(v-if="isLoading")
  .uk-container.layout.uk-margin-large-top.uk-margin-large-bottom(v-else)
    ValidationObserver(ref="validator")
      ul.uk-breadcrumb(class="uk-visible@s")
        li
          router-link( :to="{ name: 'home' }")
            icon.icon-position-fixed.uk-margin-small-right(type="home" width="15" height="12")
            | {{ $t('HOME') }}
        li {{ $t('MEMBER_CENTRE') }}
      
      .uk-margin-medium-top
        h1.uk-h2.text-red.uk-text-center {{ $t('MEMBER_CENTRE') }}
      
      .uk-margin-medium-top
        .uk-margin-medium-top
          .uk-margin-bottom
            label.label-email.uk-form-label.uk-display-block(for="email") {{ $t('EMAIL') }}
            .uk-form-controls
              input#email.uk-input.uk-form-small.input-without-border(
                type="text"
                :value="form.email"
                disabled
              )
          
          .uk-margin-bottom
            label.label-name.uk-form-label.uk-display-block(for="username") {{ $t('USERNAME') }}
            .uk-form-controls
              ValidationProvider(
                rules="required"
                v-slot="{ errors }"
                vid="username"
                :name="$t('USERNAME')"
              )
                input#username.uk-input.uk-form-small.input-without-border(
                  type="text"
                  :placeholder="$t('USERNAME')"
                  v-model.trim="form.username"
                  required
                )
                .input-error {{ errors[0] }}

          .uk-margin-bottom
            .uk-form-controls
              ValidationProvider(
                rules=""
                v-slot="{ errors }"
                vid="password"
                :name="$t('PASSWORD')"
              )
                input.uk-input.uk-form-small.input-without-border(
                  type="password"
                  v-model.trim="password"
                  :placeholder="$t('MEMBER_CHANGE_PASSWORD')"
                )

          .uk-margin-bottom
            .uk-form-controls
              ValidationProvider(
                  :rules="comfirmPasswordValidator"
                  v-slot="{ errors, failedRules }"
                  vid="confirmPassword"
                  :name="$t('CONFIRM_PASSWORD')"
                )
                input.uk-input.uk-form-small.input-without-border(
                  type="password"
                  :placeholder="$t('MEMBER_CONFIRM_PASSWORD')"
                  v-model.trim="passwordConfirm"
                )
                .input-error(v-if="!failedRules.confirmed && $t.locale !== 'ch'") {{ errors[0] }}
                .input-error(v-else) {{ $t('VALIDATOR_MSG_CONFIRM_PASSWORD') }}
          
          .uk-margin-bottom
            .uk-form-controls.subscription-wrapper(
              :class="{ 'no-checked': !form.subscription }"
            )
              label
                input.uk-checkbox(
                  type="checkbox"
                  v-model="form.subscription"
                )
                | {{ $t('MEMBER_SUBSCRIBE_PAPER') }}

          vk-grid(gutter="small").uk-margin-large-top.uk-margin-large-bottom.button-row
            .uk-width-1-1(
              class="uk-width-1-3@m uk-width-1-2@s"
            )
              button.uk-button.uk-button-default.uk-button-large.uk-width-1-1.text-remove-spacing.text-medium(
                @click="onCancel"
              ) {{ $t('MEMBER_CANCEL') }}
            .uk-width-1-1(
              class="uk-width-1-3@m uk-width-1-2@s"
            )
              button.uk-button.uk-button-primary.uk-button-large.uk-width-1-1.text-remove-spacing.text-medium(
                @click="onSubmit"
              ) {{ $t('MEMBER_CONFIRM') }}
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import { ACTIONS } from '../modules/auth';

export default {
  name: 'AccountUserInfo',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data () {
    return {
      form: {
        email: '',
        username: '',
        subscription: false,
      },
      password: '',
      passwordConfirm: '',
      isLoading: false,
    };
  },
  async created() {
    try {
      this.isLoading = true;
      await this.getUserInfo();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    init() {
      this.form = { ...this.$store.state.auth.info };
      this.password = '';
      this.passwordConfirm = '';
    },

    async getUserInfo() {
      try {
        await this.$store.dispatch(`auth/${ACTIONS.GET_INFO}`);

        this.init();
      } catch (e) {
        console.error(e);
        this.$router.push({ name: 'home' });
      }
    },

    onCancel() {
      this.init();
    },

    async onSubmit() {
      const valid = await this.$refs.validator.validate();
      if (!valid) return;

      const form = { ...this.form };
      if (this.password) form.password = this.password;

      await this.$store.dispatch(
        `auth/${ACTIONS.UPDATE_INFO}`,
        form,
      );

      this.getUserInfo();
    }
  },
  computed: {
    comfirmPasswordValidator() {
      return this.password
        ? 'required|confirmed:password'
        : '';
    },
  },
  head: {
    title: {
      inner: ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_variable.scss';

.uk-form-label {
  font-size: 10px;
  color: $primary;
}

.button-row {
  justify-content: center;
}

#email.uk-input:disabled {
  background-color: transparent;
  color: #666;
}

.subscription-wrapper {
  &.no-checked {
    color: #999;

    .uk-checkbox {
      border-color: #999;
    }
  }

  .uk-checkbox:checked:focus {
    background-color: #F5F6F6;
  }

  label {
    font-size: 14px;
  }
}

label {
  &.label-email,
  &.label-name {
    font-size: 16px;
  }
}
</style>
