<template lang='pug'>
  div
    ValidationObserver(ref="validator")
      vk-modal.modal-small-width.modal-remove-padding(:show.sync='modalState', center)
        vk-modal-close.text-dark(@click='modalPasswordToggle')
        .uk-margin-remove.background-light-blue
          .uk-position-relative.background-light-gray.uk-padding
            p.uk-text-center.uk-margin-remove-bottom
              icon(type='icon-logo', width='178')
            h3.uk-text-center.uk-margin-top(style='font-size: 22px;') {{ $t('LOGIN_FORGET_PASSWORD') }}
            p.text-red.uk-text-center.text-xsmall.uk-margin-remove-bottom
              | {{ $t('LOGIN_ENTER_EMAIL') }}
              br
              | {{ $t('LOGIN_RETRIEVE_PASSWORD') }}
          div.uk-width-5-6.uk-margin-auto
            form.uk-margin-medium-top(
              @submit.prevent="forgetPassword"
            )
              .uk-margin.uk-position-relative
                ValidationProvider(
                  rules="required|email"
                  v-slot="{ errors }"
                  vid="email"
                  :name="$t('EMAIL')"
                )
                  input.uk-input.uk-text-small(
                    type='email'
                    :placeholder="$t('EMAIL')"
                    v-model="form.email"
                    required
                  )
                  icon.uk-position-small.uk-position-center-right(type='mail', width='19')
                  .input-error {{ errors[0] }}
              .uk-margin
                button.uk-button.button-primary.button-medium-height.uk-width-1-1.text-small-spacing.text-xsmall(
                  style='color: #fff;'
                ) {{ $t('LOGIN_RETRIEVE_PASSWORD') }}
            .uk-margin
              p.uk-text-center.text-xsmall(
                style='margin-bottom: 10px;'
              ) {{ $t('LOGIN_NOT_MEMBER_YET') }}
                a.text-red.link-underline(@click='modalSignUpToggle') {{ $t('LOGIN_SIGN_UP_NOW') }}
            div
              p.uk-text-center.uk-marign-top.text-xsmall.uk-margin-remove-bottom(style='padding-top: 10rem; padding-bottom: 10px;')
                a.text-dark.link-underline(
                  @click="toPrivacy"
                ) {{ $t('LOGIN_TERMS_AND_CONDITION') }}
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { apiForgetPassword } from '../apiRequest/user';

const initForm = () => ({
  email: '',
});

export default {
  name: 'ForgetPassword',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data () {
    return {
      form: initForm(),
    };
  },
  watch: {
    modalState() {
      this.form = initForm();
      this.$refs.validator.reset();
    },
  },
  computed: {
    modalState: {
      get() {
        return this.$store.state.modals.isModalPasswordNavActive;
      },
      set() { 
        this.$store.state.modals.isModalPasswordNavActive;
      }
    }
  },
  methods: {
    modalPasswordToggle() {
      this.$store.commit('modalPasswordToggle');
    },

    modalLoginToggle() {
      this.$store.commit('modalLoginToggle');
    },

    modalSignUpToggle() {
      this.$store.commit('modalSignUpToggle');
    },

    modalClose() {
      this.$store.commit('modalClose');
    },

    toPrivacy() {
      this.$router.push({ name: "privacy" });
    }, 

    async forgetPassword() {
      const valid = await this.$refs.validator.validate();
      if (!valid) return;

      try {
        const res = await apiForgetPassword(this.form);
        if (!res || res[0].status === 'fail') return;

        this.$store.commit('modalClose');
        this.modalLoginToggle();
      } catch (e) {
        console.error(e);
      }
    },
  },
  head: {
    title: {
      inner: ''
    },
  },
}
</script>
<style lang="scss" scoped>

</style>