<template lang="pug">
  router-link.uk-position-relative.uk-display-block.link-no-underline.card-item.shopping-product-card(
      :to="{ name: 'shoppingSingle', params: { id: post.post_id } }"
    )
    vk-grid(gutter="small")
      .cover-group(:class="{\
        'uk-width-2-5': !vertical,\
        'uk-width-1-1': vertical,\
        'uk-padding-remove': vertical,\
      }")
        .tags
          .tag(v-if="cutOff > 0") -{{ cutOff }}%
        img(v-if="post.hot" style="position:absolute;width:30%;top:0;left:70%;" :src="require('../../img/hot-sale.png')")
        .uk-background-cover(:style="{ backgroundImage: `url(${post.img_url})`}")
      hr.uk-width-1-1(v-if="vertical")
      .info-group(:class="{\
        'uk-width-3-5': !vertical,\
        'uk-width-1-1': vertical,\
        'uk-margin-remove': vertical,\
        'uk-padding-remove': vertical,\
      }")
        .title {{ post.title }}
        .title-en {{ post.title_en }}
        .price-row
          priceGroup(
            :price="post.price"
            :priceSale="post.price_sale"
            :curr="post.curr"
          )
          //- span.price $ {{ post.price }}
          //- span.price-sale $ {{ post.price_sale }}
        .disc {{ post.disc }}

        //- button.uk-button.uk-button-primary.uk-button-small(
        //-   @click.stop="onPutToCart"
        //- ) {{ $t('SHOP_ADD_TO_CART') }}
</template>

<script>
import priceGroup from '../components/commons/priceGroup.vue';
/*
interface IPost {
  post_id: number;
  img_url: string;
  title: string;
  title_en: string;
  price: string;
  price_sale: string;
  disc: string;
}
*/

export default {
  components: {
    priceGroup,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
    vertical: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    onPutToCart() {
      // TODO
    },
  },
  computed: {
    cutOff() {
      const { price, price_sale } = this.post;
      if (!price_sale) return;
      return Math.round((1 - (price_sale / price)) * 100);
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_variable.scss';
@import '../../sass/helper/_mixin.scss';

.cover-group {
  position: relative;

  .tags {
    position: absolute;
    top: 15px;
    left: 0;

    .tag {
      background: $primary;
      color: #fff;
      padding: 0 10px;
    }
  }

  .uk-background-cover {
    padding-top: 120%;
  }
}

hr {
  margin: 10px 0;
  border-top: 2px solid $primary;
}

.title, .title-en, .disc {
  color: #000;
  margin-bottom: 5px;
}

.title {
  font-size: 20px;

  @include mq(640px){
    font-size: 16px;
  }
}

.title-en {
  font-size: 15px;
  color: #707070;

  @include mq(640px){
    font-size: 14px;
  }
}

.disc {
  font-size: 15px;

  @include mq(960px){
    font-size: 14px;
  }
}

.price-row {
  margin-bottom: 5px;
  font-size: 20px;

  @include mq(960px){
    font-size: 14px;
  }
}
</style>
