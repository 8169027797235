<template lang="pug">
  .uk-position-center.iframe-wrapper.slide-show-video-root(v-if="videoUrl")
    .iframe-box(v-html="videoUrl")
    .mask-cover
    .cover(:style="{ backgroundImage: `url(${imgUrl})` }")
</template>

<script>
export default {
  props: {
    videoUrl: {
      type: String,
      default: '',
    },
    imgUrl: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.iframe-wrapper {
  width: 100%;
  height: 100%;

  .cover,
  .mask-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .mask-cover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .iframe-box {
    // width: 100%;
    // height: 100%;
    // width: 350%;
    // /* height: 250%; */
    // transform: translateX(-50%);
    position: relative;
    padding-top: calc(582 / 1034 * 100%);
    

    iframe {
      width: 100%;
      height: 100%;
      pointer-events: none;
      position: absolute;
      top: 0;
    }
  }
}
</style>

<style lang="scss">
.slide-show-video-root {
  .iframe-box {
    // width: 100%;
    height: 100%;
    // width: 350%;
    // /* height: 250%; */
    // transform: translateX(-50%);
    position: relative;
    // padding-top: calc(582 / 1034 * 100%);
    
    iframe {
      width: 100%;
      height: 100%;
      pointer-events: none;
      position: absolute;
      top: 0;
    }
  }
}
</style>
