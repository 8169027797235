/*
interface IData {
  title: string;
  image: string;
  description: string
}
*/

import striptags from 'striptags';

const DEFAULT_DATA = {
  title: 'CNEX',
  description: '給下一代太平盛世的備忘錄Looking for Chinese 2.0',
  image: require('../../img/logo.jpg'),
};

function fbHandler(data) {
  return `
    <meta name="og:title" property="og:title" content="${data.title}" />
    <meta name="og:description" property="og:description" content="${striptags(data.description)}" />
    <meta name="og:image" property="og:image" content="${encodeURI(data.image)}" />
    <meta name="twitter:title" property="twitter:title" content="${data.title}" />
    <meta name="twitter:image" property="twitter:image" content="${encodeURI(data.image)}" />
    <meta name="twitter:card" content="summary" />
  `;
}

export function genMeta(data = DEFAULT_DATA) {
  return fbHandler(data);
}