<template lang='pug'>
  div
    ValidationObserver(ref="validator")
      vk-modal.modal-small-width.modal-remove-padding(
        :show.sync="modalState"
        center
        stuck
      )
        vk-modal-close(@click="modalLoginToggle")
        .uk-margin-remove.background-light-blue
          .uk-position-relative.background-light-gray.uk-padding
            p.uk-text-center.uk-margin-remove-bottom
              icon(
                type="icon-logo"
                width="178"
              )
            h3.uk-text-center.uk-margin-top(style="font-size: 22px;") {{ $t('LOGIN_MEMBER_SIGN_IN') }}

          div.uk-width-5-6.uk-margin-auto
            form.uk-margin-medium-top(
              v-if="modalState"
              @submit.prevent="signin"
            )
              .uk-margin.uk-position-relative
                ValidationProvider(
                  rules="required|email"
                  v-slot="{ errors }"
                  vid="email"
                  :name="$t('EMAIL')"
                )
                  input.uk-input.uk-text-small(
                    type="email"
                    :placeholder="$t('EMAIL')"
                    v-model="form.email"
                    required
                  )
                  icon.uk-position-small.uk-position-center-right(
                    type="mail"
                    width="19"
                  )
                  .input-error {{ errors[0] }}
              .uk-margin.uk-position-relative
                ValidationProvider(
                  rules="required"
                  v-slot="{ errors }"
                  vid="password"
                  :name="$t('PASSWORD')"
                )
                  input.uk-input.uk-text-small(
                    type="password"
                    :placeholder="$t('PASSWORD')"
                    v-model="form.password"
                    required
                  )
                  icon.uk-position-small.uk-position-center-right(
                    type="key"
                    width="19"
                  )
              .login-error(v-if="loginError") {{ $t(loginError) }}
              .uk-margin
                button.uk-button.button-primary.button-medium-height.uk-width-1-1.text-small-spacing.text-xsmall(type='submit', style='color: #fff;') 立即登入

            .uk-margin
              p.uk-text-center(style="font-size:12px;")
                | {{ $t('OR') }}
                br
                | {{ $t('LOGIN_USE_SOCIAL_MEDIA_SIGN_IN') }}
              .uk-margin
                button.uk-button.button-medium-height.uk-width-1-1.button-padding-small.uk-flex.uk-flex-middle.uk-flex-center.text-small-spacing.text-xsmall(
                  style='color: #fff; background-color: #254D90;'
                  @click="signinFB"
                )
                  vk-icon.text-white(icon="facebook", ratio="1.2")
                  | &nbsp;{{ $t('LOGIN_FACEBOOK_SIGN_IN') }}
                button.uk-button.button-medium-height.uk-width-1-1.button-padding-small.uk-margin-small-top.uk-flex.uk-flex-middle.uk-flex-center.text-small-spacing.text-xsmall(
                  style='color: #fff; background-color: #D33C26;'
                  @click="signinGoogle"
                )
                  vk-icon.text-white(icon="google-plus", ratio="1.2")
                  | &nbsp;{{ $t('LOGIN_GOOGLE_SIGN_IN') }}

            .uk-margin
              p.uk-text-center.text-xsmall(style="margin-bottom: 10px;") {{ $t('LOGIN_NOT_MEMBER_YET') }}
                a.text-dark.link-underline(@click="modalSignUpToggle") {{ $t('LOGIN_SIGN_UP_NOW') }}
              p.uk-text-center.text-xsmall.uk-margin-remove-top {{ $t('LOGIN_EVEN_BEEN_MEMBER') }}
                a.text-dark.link-underline(@click="modalPasswordToggle") {{ $t('LOGIN_FORGET_PASSWORD') }}

            div
              p.uk-text-center.uk-marign-top.text-xsmall.uk-margin-remove-bottom.uk-padding(style="padding-bottom: 10px;")
                a.text-dark.link-underline(
                  @click="toPrivacy"
                ) {{ $t('LOGIN_TERMS_AND_CONDITION') }}
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import { ACTIONS } from '../modules/auth';

const initForm = () => ({
  email: '',
  password: '',
});

export default {
  name: 'ModalLogin',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data () {
    return {
      form: initForm(),
      loginError: '',
    };
  },
  watch: {
    modalState() {
      this.form = initForm();
      this.$refs.validator.reset();
      this.loginError = '';
    },
  },
  methods: {
    modalLoginToggle() {
      this.$store.commit('modalLoginToggle');
    },
    modalPasswordToggle() {
      this.$store.commit('modalPasswordToggle');
    },
    modalSignUpToggle() {
      this.$store.commit('modalSignUpToggle');
    },
    modalClose() {
      this.$store.commit('modalClose')
    },
    toPrivacy() {
      this.$router.push({ name: "privacy" });
    },

    async signin() {
      const valid = await this.$refs.validator.validate();
      if (!valid) return;

      try {
        const res = await this.$store.dispatch(
          `auth/${ACTIONS.SIGNIN}`,
          this.form,
        );

        if (!res || res[0].status === 'fail') {
          this.loginError = 'LOGIN_FAILED_MSG';
          return;
        }

        this.loginError = '';

        this.$store.dispatch(`auth/${ACTIONS.GET_INFO}`);
        this.modalClose();
      } catch (e) {
        console.error(e);
      }
    },

    async signinFB() {
      FB.login(async (resFb) => {
        if (!resFb || resFb.status !== 'connected') return;

        const { id: fid, name: username, email } = resFb;
        const res = await this.$store.dispatch(
          `auth/${ACTIONS.SIGNIN_FB}`,
          { fid,  username,  email },
        );

        this.$store.dispatch(`auth/${ACTIONS.GET_INFO}`);

        this.modalClose();
      }, { scope: 'public_profile,email' });
    },

    async signinGoogle() {
      const googleAuth = gapi.auth2.getAuthInstance();

      try {
        await googleAuth.signIn();
        const googleUser = googleAuth.currentUser.get();
        const profile = googleUser.getBasicProfile();

        const res = await this.$store.dispatch(
          `auth/${ACTIONS.SIGNIN_GOOGLE}`,
          {
            /** google 不建議使用 userId，推薦用 idToken */
            // gid: googleUser.getAuthResponse().id_token,
            gid: profile.getId(),
            username: profile.getName(),
            email: profile.getEmail(),
          },
        );

        this.$store.dispatch(`auth/${ACTIONS.GET_INFO}`);

        this.modalClose();
      } catch (e) {
        console.error(e);
      }
    },
  },
  computed: {
    modalState: {
      get() {
        return this.$store.state.modals.isModalLoginActive;
      },
      set() {
        this.$store.state.modals.isModalLoginActive;
      }
    }
  },
  head: {
    title: {
      inner: ''
    },
  },
}
</script>
<style lang="scss" scoped>
.login-error {
  margin-top: -10px;
  color: #E0000C;
  font-size: 12px;
}
</style>