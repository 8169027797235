<template lang="pug">
  hooper.slider(
    v-if="posts.length"
    style="height: auto"
    :settings="sliderSettings"
  )
    slide(
      v-for="post in posts"
      :key="post.post_id"
    )
      CataloguePost(:post="post")
      //- router-link.uk-position-relative.uk-display-block.link-no-underline.link-card(
      //-   :to="{ name: 'catalogueSingle', params: { id: post.post_id } }"
      //- )
      //-   .uk-background-cover(:style="{ backgroundImage: `url(${post.img_url})` }")
      //-     span.uk-position-center.text-small.text-white {{ $t('READ_MORE') }}
      //-   h3.uk-margin-small-top.uk-margin-remove-bottom.text-bold {{ post.title }}
      //-   p.uk-margin-remove.text-gray.text-xlarge.text-no-spacing {{ post.title_en }}
      //-   vk-grid(
      //-     gutter="small"
      //-     style="margin-top: .2rem;"
      //-     v-if="post.award !== ''"
      //-   )
      //-     div(style="width: 48px")
      //-       hr.uk-background-primary.uk-position-relative(
      //-         style="height: 1px; top: 11px; border: 0;"
      //-       )
      //-     div.uk-width-expand
      //-       p.text-red.text-no-spacing(v-html="post.award")
    hooper-navigation(slot="hooper-addons")
</template>

<script>
import {
  Hooper,
  Slide,
  Pagination as HooperPagination,
  Navigation as HooperNavigation,
} from 'hooper'
import 'hooper/dist/hooper.css';

import CataloguePost from './CataloguePost.vue';
/*
interface IPost {
  post_id: 0,
  img_url: string,
  title: string,
  title_en: string,
  award: string
}
*/

export default {
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination,
    CataloguePost,
  },
  props: {
    posts: {
      type: Array,
      default: () => ([]),
    },
    sliderSettings: {
      type: Object,
      default: () => ({
        itemsToShow: 1,
        // centerMode: true,
        wheelControl: false,
        infiniteScroll: false,
        breakpoints: {
          960: {
            itemsToShow: 3
          },
          640: {
            itemsToShow: 2
          },
        }
      }),
    },
  },
}
</script>
