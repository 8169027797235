<template lang='pug'>
  div
    ValidationObserver(ref="validator")
      vk-modal.modal-small-width.modal-remove-padding(:show.sync='modalSignUpState', center)
        vk-modal-close.text-dark(@click='modalSignUpToggle')
        .uk-margin-remove.background-light-blue
          .uk-position-relative.background-light-gray.uk-padding
            p.uk-text-center.uk-margin-remove-bottom
              icon(type='icon-logo', width='178')
            h3.uk-text-center.uk-margin-top(style='font-size: 22px;') {{ $t('LOGIN_MEMBER_SIGN_IN') }}
          div.uk-width-5-6.uk-margin-auto
            form.uk-margin-medium-top(
              @submit.prevent="signup"
            )
              .uk-margin.uk-position-relative
                ValidationProvider(
                  rules="required|email"
                  v-slot="{ errors }"
                  vid="email"
                  :name="$t('EMAIL')"
                )
                  input.uk-input.uk-text-small(
                    type='email'
                    :placeholder="$t('EMAIL')"
                    v-model.trim="form.email"
                    required
                  )
                  icon.uk-position-small.uk-position-center-right(type='mail', width='19')
                  .input-error {{ errors[0] }}
              .uk-margin.uk-position-relative
                ValidationProvider(
                  rules="required"
                  v-slot="{ errors }"
                  vid="password"
                  :name="$t('PASSWORD')"
                )
                  input.uk-input.uk-text-small(
                    name="password"
                    type='password'
                    :placeholder="$t('PASSWORD')"
                    v-model.trim="form.password"
                    required
                  )
                  icon.uk-position-small.uk-position-center-right(type='key', width='19')
                  .input-error {{ errors[0] }}
              .uk-margin.uk-position-relative
                ValidationProvider(
                  rules="required|confirmed:password"
                  v-slot="{ errors, failedRules }"
                  vid="confirmPassword"
                  :name="$t('CONFIRM_PASSWORD')"
                )
                  input.uk-input.uk-text-small(
                    type='password'
                    :placeholder="$t('CONFIRM_PASSWORD')"
                    v-model.trim="confirmPassword"
                    required
                  )
                  icon.uk-position-small.uk-position-center-right(type='key2', width='23')
                  .input-error(v-if="!failedRules.confirmed && $t.locale !== 'ch'") {{ errors[0] }}
                  .input-error(v-else) {{ $t('VALIDATOR_MSG_CONFIRM_PASSWORD') }}
              .uk-margin
                button.uk-button.button-primary.button-medium-height.uk-width-1-1.text-small-spacing.text-xsmall(
                  style='color: #fff;'
                ) {{ $t('SIGN_UP') }}
            .uk-margin
              p.uk-text-center.text-xsmall(style='margin-bottom: 10px;') {{ $t('LOGIN_SIGNED_UP') }}
                a.text-red.link-underline(
                  @click='modalLoginToggle'
                ) {{ $t('LOGIN_LOGIN_DIRECTLY') }}
            div
              p.uk-text-center.uk-marign-top.text-xsmall.uk-margin-remove-bottom.uk-padding(style='padding-bottom: 10px;')
                a.text-dark.link-underline(
                  @click="toPrivacy"
                ) {{ $t('LOGIN_TERMS_AND_CONDITION') }}
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import { apiSignup } from '../apiRequest/user';

const initForm = () => ({
  email: '',
  username: '',
  password: '',
});

export default {
  name: 'ModalSignUp',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data () {
    return {
      form: initForm(),
      confirmPassword: '',
    };
  },
  watch: {
    modalSignUpState() {
      this.form = initForm();
      this.confirmPassword = '';
      this.$refs.validator.reset();
    },
    ['form.email'](v) {
      if (!v.includes('@')) this.form.username = '';
      else  this.form.username = v.split('@')[0];
    },
  },
  methods: {
    modalSignUpToggle() {
      this.$store.commit('modalSignUpToggle')
    },

    modalLoginToggle() {
      this.$store.commit('modalLoginToggle');
    },

    modalClose() {
      this.$store.commit('modalClose')
    },

    toPrivacy() {
      this.$router.push({ name: "privacy" });
    },

    async signup() {
      const valid = await this.$refs.validator.validate();
      if (!valid) return;

      try {
        const res = await apiSignup(this.form);
        if (!res || res[0].status === 'fail') return;

        this.modalLoginToggle();
      } catch (e) {
        console.error(e);
      }
    },
  },
  computed: {
    modalSignUpState: {
      get() {
        return this.$store.state.modals.isModalSingUpActive;
      },
      set() {
        this.$store.state.modals.isModalSingUpActive;
      }
    },
  },
  head: {
    title: {
      inner: ''
    },
  },
}
</script>
<style lang="scss" scoped>

</style>