<template lang="pug">
  router-link.uk-position-relative.uk-display-block.link-no-underline.link-card.card-item(
    :to='{ name: "channelSingle", params: { id: post.post_id } }'
  )
    .uk-background-cover(:style='{ backgroundImage: `url(${imgUrl})`}')
      .title {{ post.title }}
      span.uk-position-center.text-small.text-white {{ $t('READ_MORE') }}
    .date-time-row
      .date {{ post.program_start }}
      .time
        //- icon(type="play" width="20" height="20")
        span(v-if="time")
          vk-icon.uk-margin-small-right(icon="clock" ratio="0.8")
          span {{ time }}
</template>

<script>
import { getImgUrl, formatTimeWithMin } from '../utils/helpers';
/*
interface IPost {
  post_id: number;
  img_url: string;
  title: string;
  program_start: string;
  time: string;
}
*/

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imgUrl: '',
    };
  },
  async created() {
    await this.getImgUrl();
  },
  methods: {
    async getImgUrl() {
      const url = await getImgUrl([
        this.post.img_url,
        `https://files.cnex.com.tw/channel/${this.post.post_id}_still.jpg`,
        'https://files.cnex.com.tw/channel/default_still.jpg',
      ]);
      this.imgUrl = url;
    },
  },
  computed: {
    date() {
      const date = moment(this.post.program_start);

      return date.isValid()
        ? date.format('MM/DD')
        : this.post.program_start;
    },
    time() {
      // const date = moment(this.post.program_start);
      //
      // return date.isValid()
      //   ? date.format('HH:mm')
      //   : '';
        return '';
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_mixin.scss';

.uk-background-cover {
  padding-top: 50%;
}

.title {
  background-color: black;
  opacity: .7;
  color: white;
  padding: 5px;
  font-size: 18px;
  font-weight: 100;

  @include mq(960px) {
    font-size: 16px;
  }
  @include mq(640px) {
    font-size: 14px;
  }
}

.date-time-row {
  display: flex;
  justify-content: space-between;
  color: black;
  margin-top: 5px;
  font-size: 18px;

  @include mq(640px) {
    font-size: 14px;
  }

  .date {
    color: #000;
  }

  .time {
    display: inline-flex;
    align-items: center;

    svg {
      stroke: black;
    }
  }
}
</style>

