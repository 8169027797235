export async function apiGetPostsSlideshows(payload) {
  return await axios.get(
    `slideshow/post/home`,
    payload,
  );
}

export async function apiGetPostSearch(payload) {
  return await axios.get(
    `posts/search`,
    payload,
  );
}

export async function apiGetPostsList(payload) {
  return await axios.get(
    `posts/list`,
    payload,
  );
}

export async function apiGetPostsSingle(id, payload) {
  return await axios.get(
    `posts/single/${id}`,
    payload,
  );
}

export async function apiGetPostsSingleRelated(id, payload) {
  return await axios.get(
    `posts/single/${id}/related`,
    payload,
  );
}

export async function apiPostPostsSingleViews(body, payload) {
  return await axios.post(
    `posts/single/views`,
    body,
    payload,
  );
}
