var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"validator"},[_c('vk-modal',{staticClass:"modal-small-width modal-remove-padding",attrs:{"show":_vm.modalSignUpState,"center":""},on:{"update:show":function($event){_vm.modalSignUpState=$event}}},[_c('vk-modal-close',{staticClass:"text-dark",on:{"click":_vm.modalSignUpToggle}}),_c('div',{staticClass:"uk-margin-remove background-light-blue"},[_c('div',{staticClass:"uk-position-relative background-light-gray uk-padding"},[_c('p',{staticClass:"uk-text-center uk-margin-remove-bottom"},[_c('icon',{attrs:{"type":"icon-logo","width":"178"}})],1),_c('h3',{staticClass:"uk-text-center uk-margin-top",staticStyle:{"font-size":"22px"}},[_vm._v(_vm._s(_vm.$t('LOGIN_MEMBER_SIGN_IN')))])]),_c('div',{staticClass:"uk-width-5-6 uk-margin-auto"},[_c('form',{staticClass:"uk-margin-medium-top",on:{"submit":function($event){$event.preventDefault();return _vm.signup($event)}}},[_c('div',{staticClass:"uk-margin uk-position-relative"},[_c('ValidationProvider',{attrs:{"rules":"required|email","vid":"email","name":_vm.$t('EMAIL')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.form.email),expression:"form.email",modifiers:{"trim":true}}],staticClass:"uk-input uk-text-small",attrs:{"type":"email","placeholder":_vm.$t('EMAIL'),"required":""},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('icon',{staticClass:"uk-position-small uk-position-center-right",attrs:{"type":"mail","width":"19"}}),_c('div',{staticClass:"input-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"uk-margin uk-position-relative"},[_c('ValidationProvider',{attrs:{"rules":"required","vid":"password","name":_vm.$t('PASSWORD')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.form.password),expression:"form.password",modifiers:{"trim":true}}],staticClass:"uk-input uk-text-small",attrs:{"name":"password","type":"password","placeholder":_vm.$t('PASSWORD'),"required":""},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('icon',{staticClass:"uk-position-small uk-position-center-right",attrs:{"type":"key","width":"19"}}),_c('div',{staticClass:"input-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"uk-margin uk-position-relative"},[_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password","vid":"confirmPassword","name":_vm.$t('CONFIRM_PASSWORD')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.confirmPassword),expression:"confirmPassword",modifiers:{"trim":true}}],staticClass:"uk-input uk-text-small",attrs:{"type":"password","placeholder":_vm.$t('CONFIRM_PASSWORD'),"required":""},domProps:{"value":(_vm.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmPassword=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('icon',{staticClass:"uk-position-small uk-position-center-right",attrs:{"type":"key2","width":"23"}}),(!failedRules.confirmed && _vm.$t.locale !== 'ch')?_c('div',{staticClass:"input-error"},[_vm._v(_vm._s(errors[0]))]):_c('div',{staticClass:"input-error"},[_vm._v(_vm._s(_vm.$t('VALIDATOR_MSG_CONFIRM_PASSWORD')))])]}}])})],1),_c('div',{staticClass:"uk-margin"},[_c('button',{staticClass:"uk-button button-primary button-medium-height uk-width-1-1 text-small-spacing text-xsmall",staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(_vm.$t('SIGN_UP')))])])]),_c('div',{staticClass:"uk-margin"},[_c('p',{staticClass:"uk-text-center text-xsmall",staticStyle:{"margin-bottom":"10px"}},[_vm._v(_vm._s(_vm.$t('LOGIN_SIGNED_UP'))),_c('a',{staticClass:"text-red link-underline",on:{"click":_vm.modalLoginToggle}},[_vm._v(_vm._s(_vm.$t('LOGIN_LOGIN_DIRECTLY')))])])]),_c('div',[_c('p',{staticClass:"uk-text-center uk-marign-top text-xsmall uk-margin-remove-bottom uk-padding",staticStyle:{"padding-bottom":"10px"}},[_c('a',{staticClass:"text-dark link-underline",on:{"click":_vm.toPrivacy}},[_vm._v(_vm._s(_vm.$t('LOGIN_TERMS_AND_CONDITION')))])])])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }