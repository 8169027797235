<template lang='pug'>
  .uk-margin-medium-top
    h1.uk-heading-line.uk-text-center.text-red 
      span {{ $t('SEARCH') }}

    .uk-margin-medium-top

      vk-grid
        div.keyword-input(class="uk-width-expand@m")
          input.uk-input(
            type="text"
            :placeholder="`${$t('KEYWORDS')}...`"
            :value="value.keyword"
            @input="onChangeSelect('keyword', $event)"
          )
        .uk-position-relative.others-input(
          v-for="os in optionSets"
          :key="os.key"
          class="uk-width-expand@s"
          :class="`${os.key}-input`"
        )
          icon.uk-position-center-left.uk-position-small.position-small-l.cross-icon(
            type="close"
            width="14"
            height="14"
            class="uk-visible@s"
          )
          select.uk-select(
            :value="value[os.key]"
            @change="onChangeSelect(os.key, $event)"
          )
            option(
              v-if="os.label"
              value=""
            ) {{ os.label }}
            option(
              v-for="o in os.options"
              :key="o.value"
              :value="o.value"
              :disabled="o.disabled"
            ) {{ o.label }}
          icon.icon-select.uk-position-center-right.uk-position-small(
            type="select"
            width="30"
            height="31"
          )
        
        slot

        .uk-width-1-1.btn-search.search-btn-group(
          class="uk-width-auto@s"
        )
          slot(name="withSearchButton")

          .button-square(@click="onSearch")
            icon.uk-position-center(
              type="search"
              width="25"
              height="26"
            )
</template>

<script>
/*
interface IOptionSet {
  options: {
    value: string;
    label: string;
  }[];
  key: string;
  inputType: 'select'|'input'|'datepicker'
}
*/

export default {
  name: 'SearchFrom',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    optionSets: {
      type: Array,
      default: () => ([]),
    }
  },
  methods: {
    emitInput(e) {
      this.$emit('input', {
        ...this.value,
        ...e,
      });
    },
    onSearch() {
      this.$emit('onSearch');
    },
    onChangeSelect(key, e) {
      this.emitInput({ [key]: e.target.value });
    },
  },
  head: {
    title: {
      inner: ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_variable.scss';
@import '../../sass/helper/_mixin.scss';

.uk-first-column {
  padding-left: 0 !important;
  margin-left: 0 !important;

  &.others-input {
    .cross-icon {
      display: none;
    }
  }
}

.uk-grid {
  margin-left: 0 !important;
}

.btn-search {
  @include mq-min($m-media) {
    padding-left: 10px;
  }
}

.icon-select {
  pointer-events: none;
}

.search-btn-group {
  display: flex;
  align-items: center;
}

.button-square {
  cursor: pointer;
}
</style>