import {
  apiLogin,
  apiLoginFb,
  apiLoginGoogle,
  apiGetUser,
  apiUpdateUser,
} from '../apiRequest/user';

export const MUTATIONS = {
  UPDATE_ID: 'UPDATE_ID',
  UPDATE_INFO: 'UPDATE_INFO',
};

export const ACTIONS = {
  SIGNIN: 'SIGNIN',
  SIGNIN_FB: 'SIGNIN_FB',
  SIGNIN_GOOGLE: 'SIGNIN_GOOGLE',

  SIGNOUT: 'SIGNOUT',

  GET_INFO: 'GET_INFO',
  UPDATE_INFO: 'UPDATE_INFO',

  IS_SINGIN: 'IS_SINGIN',
};

export const STORAGE_KEY_USER_INFO = 'user-info';

function setUserInfo2Storage(data) {
  let userData = getUserInfoFromStorage() || {};
  userData = {
    ...userData,
    ...data,
  };
  sessionStorage.setItem(STORAGE_KEY_USER_INFO, JSON.stringify(userData));
}

function getUserInfoFromStorage() {
  let data;
  try {
    data = JSON.parse(sessionStorage.getItem(STORAGE_KEY_USER_INFO));
    return data;
  } catch {
    return null;
  }
}

/**
interface IUserInfo {
  email: string;
  username: string;
}
*/

export const auth = {
  namespaced: true,
  state: {
    id: '',
    info: {},
  },
  mutations: {
    [MUTATIONS.UPDATE_ID](state, id) {
      state.id = id;
      setUserInfo2Storage({ id });
    },
    [MUTATIONS.UPDATE_INFO](state, info) {
      state.info = info;
      setUserInfo2Storage({ info });
    },
  },
  actions: {
    async [ACTIONS.SIGNIN]({ commit }, body) {
      const res = await apiLogin(body);
      commit(MUTATIONS.UPDATE_ID, res[0].user_id);

      return res;
    },

    async [ACTIONS.SIGNIN_FB]({ commit }, body) {
      const res = await apiLoginFb(body);
      commit(MUTATIONS.UPDATE_ID, res[0].user_id);

      return res;
    },

    async [ACTIONS.SIGNIN_GOOGLE]({ commit }, body) {
      const res = await apiLoginGoogle(body);
      commit(MUTATIONS.UPDATE_ID, res[0].user_id);

      return res;
    },

    async [ACTIONS.GET_INFO]({ commit, state }) {
      const res = await apiGetUser(state.id);
      commit(MUTATIONS.UPDATE_INFO, res[0]);

      return res;
    },

    async [ACTIONS.UPDATE_INFO]({ dispatch, state }, body) {
      const res = await apiUpdateUser(state.id, body);
      dispatch(ACTIONS.GET_INFO, state.id);

      return res;
    },

    [ACTIONS.IS_SINGIN]({ commit }) {
      const user = getUserInfoFromStorage();
      try {
        commit(MUTATIONS.UPDATE_ID, user.id);
        commit(MUTATIONS.UPDATE_INFO, user.info);
        return true;
      } catch {
        return false;
      }
    },

    [ACTIONS.SIGNOUT]({ commit }) {
      commit(MUTATIONS.UPDATE_ID, '');
      commit(MUTATIONS.UPDATE_INFO, {});
      sessionStorage.setItem(STORAGE_KEY_USER_INFO, null);
    },
  },
};
