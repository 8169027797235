<template lang="pug">
  .uk-position-relative.home-post-panel
    .content-wrapper
      .subtitle {{ post.title_en }}
      .title(style="margin-bottom: 20px;") {{ post.title }}
      .desc(v-html="post.desc")
      a.uk-button.uk-button-default.uk-button-large.uk-margin-top.text-thin(
        :href="post.link_url"
      )
        | {{ $t('READ_MORE') }}
        icon.icon-position-fixed.uk-margin-small-left.icon-playf(
          type="play-f"
          width="21"
          height="21"
        )
        icon.icon-position-fixed.uk-margin-small-left.icon-play(
          type="play"
          width="21"
          height="21"
        )
</template>

<script>
/*
interface IPost {
  id: 0;
  img_url: string;
  title: string;
  title_en: string;
  desc: string;
  link_url: string;
}
*/

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_variable.scss';
@import '../../sass/helper/_mixin.scss';

.home-post-panel {
  height: 100%;
}

.content-wrapper {
  padding: 100px;
  padding-left: 280px;
}

.title, .subtitle {
  font-size: 50px;
  font-weight: bold;
  color: #c7000b;
}

.desc {
  font-size: 20px;
  line-height: 40px;
  color: #5A5A5A;
}
</style>

<style lang="scss">
@import '../../sass/helper/_variable.scss';
@import '../../sass/helper/_mixin.scss';

.home-post-panel {
  .uk-button {
    &:hover {
      .icon-playf {
        display: none;
      }
      .icon-play {
        display: inline-block;
      }
    }
    .icon-play {
      display: none;
    }
  }
}
</style>
