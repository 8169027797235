<template lang="pug">
  //- .twitter-share-button-wrapper
  //-   a.twitter-share-button(
  //-     :href="href"
  //-   )
  span.share-btn.twitter-share-button(
    @click="click"
  )
    vk-icon(
      icon="twitter"
      ratio="0.8"
    )
    | {{ $t('SHARE_BY_TWEET') }}
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {

  },
  methods: {
    click() {
      window.open(
        `https://twitter.com/intent/tweet?text=${encodeURIComponent(this.post.title)} ${encodeURIComponent(window.location.href)}`,
        '',
        'width=700,height=450',
      );
    },
  },
  computed: {
    href() {
      if (typeof window !== 'undefined') {
        return `https://twitter.com/intent/tweet?text=${encodeURIComponent(this.post.title)}`;
      } else {
        return '';
      }
    }
  },
}
</script>

<style lang="scss" scoped>
$bg: #1b95e0;

.share-btn {
  background: $bg;

  &:hover {
    background: darken($bg, 5%);
  }
}

.uk-icon {
  margin-right: 5px;
}
</style>

<style lang="scss">
.twitter-share-button-wrapper {
  padding-left: 0;

  iframe {
    height: 22px !important;
  }
}
</style>

