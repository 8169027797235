<template lang="pug">
  div
    vk-modal.modal-large-width.modal-remove-padding(:show.sync="modalState", center, stuck)
      vk-modal-close(@click="modalClose")
      .uk-margin-remove.background-light-blue
        .uk-position-relative.background-light-gray.uk-padding
          p.uk-text-center.uk-margin-remove-bottom
            icon(type="icon-logo", width="178")
          h3.uk-text-center.uk-margin-top(style="font-size: 22px;") {{ $t('CATA_LICENSE_DESCRIPTION') }}

      .modal-body.uk-padding
        div(v-html="content")
</template>

<script>
export default {
  name: 'ModalCataloguePostLicense',
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  watch: {
    $route(to, from){
      this.modalClose()
    }
  },
  methods: {
    modalToggle() {
      this.$store.commit('modalCataloguePostLicenseToggle');
    },
    modalClose() {
      this.$store.commit('modalClose');
    }
  },
  computed: {
    modalState: {
      get() {
        return this.$store.state.modals.isModalCataloguePostLicenseActive;
      },
      set() {
        this.$store.state.modals.isModalCataloguePostLicenseActive;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_mixin.scss';

.modal-body {
  max-height: 50vh;
  overflow: auto;
  font-size: 16px;

  @include mq(640px) {
    max-height: 68vh;
  }
}
</style>
