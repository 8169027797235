<template lang="pug">
  Loading(v-if="isLoading")
  .uk-container.layout.uk-margin-large-top.uk-margin-large-bottom(v-else)
    ul.uk-breadcrumb(class="uk-visible@s")
      li
        router-link( :to="{ name: 'home' }")
          icon.icon-position-fixed.uk-margin-small-right(type="home" width="15" height="12")
          | {{ $t('HOME') }}
      li {{ post.title }}

    .uk-margin-medium-top
      h1.uk-h2.text-red.uk-text-center {{ post.title }}

    .uk-margin-medium-top
      .content(v-html="post.content")
</template>

<script>
import { apiGetPage } from '../apiRequest/others';

export default {
  name: 'Page',
  data () {
    return {
      post: {
        title: '',
        content: ''
      },
      isLoading: false
    };
  },
  async created() {
    try {
      this.isLoading = true;
      await Promise.all([
        this.getContent(),
      ]);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async getContent() {
      const res = await apiGetPage(this.$route.params.id);
      if (!res || !res[0]) return;

      this.post = res[0];
    }
  },
  head: {
    title: {
      inner: ''
    },
  },
}
</script>
