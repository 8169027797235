import axios from 'axios';
import { LANG_ID } from './locales';
import { SITE_ID } from './sites';
//jesse chan
/** axios instance for check image url */
const axoisImg = axios.create();

export function getImgUrl(imgUrls = []) {
    return new Promise(async reslove => {
        if (typeof window !== 'undefined') {
            /** For client render */
            const img = new Image();
            let index = 0;
            const len = imgUrls.length;

            img.src = imgUrls[index];

            img.onerror = () => {
                index += 1;
                if (index >= len) {
                    reslove('https://files.cnex.com.tw/channel/default_still.jpg');
                    return;
                }
                img.src = imgUrls[index];
            }

            img.onload = () => {
                reslove(imgUrls[index]);
            }
        } else {
            /** For server render */
            for (let i = 0; i < imgUrls.length; i++) {
                if (!imgUrls[i]) continue;
                try {
                    await axoisImg.get(imgUrls[i]);
                    reslove(imgUrls[i]);
                    return;
                } catch (e) {
                    continue;
                }
            }
        }
    });
}


export function formatTimeWithMin(timeStr) {
    const time = parseInt(timeStr);

    const hours = time % 60;
    const mins = parseInt(time / 60);

    const hoursStr = hours < 10 ? `0${hours}` : hours;
    const minsStr = mins < 10 ? `0${mins}` : mins;

    return `${hoursStr}:${minsStr}`;
}

const LANG_IDS = _.map(LANG_ID, v => v);
const SITE_IDS = _.map(SITE_ID, v => v);

export function urlAddLangSite(o) {
    if (!LANG_IDS.includes(o.lang)) {
        return;
    }
    if (!SITE_IDS.includes(o.site)) {
        return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    const currentLang = searchParams.get('lang');
    const currentSite = searchParams.get('site');
    if ((currentLang === o.lang)&&(currentSite === o.site)) {
        return;
    }
    searchParams.set('lang', o.lang);
    searchParams.set('site', o.site);
    window.location.search = searchParams.toString();
}

export function urlAddLang({ lang }) {
    if (!LANG_IDS.includes(lang)) {
        return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    const currentLang = searchParams.get('lang');
    if (currentLang === lang) {
        return;
    }
    searchParams.set('lang', lang);
    urlRedirect(searchParams.toString());
}

export function urlAddSite({ site }) {
    if (!SITE_IDS.includes(site)) {
        return;
    }
    const searchParams = new URLSearchParams(window.location.search);
    const currentSite = searchParams.get('site');
    if (currentSite === site) {
        return;
    }
    searchParams.set('site', site);
    urlRedirect(searchParams.toString());
}

function urlRedirect(s) {
    let o = window.location.origin;
    let p = window.location.pathname.split('/');
    let pn = '/' + p[1];

    if (['catalogue', 'channel', 'shopping', 'posts', 'events'].includes(p[1])) {
        pn += '/home';
    }
    window.location.href = o + pn + '?' + s;
}

export function checkLangSite() {
    if (typeof window !== 'undefined') {
        let locale = LANG_ID.ch;
        let site = SITE_ID.t;
        let shouldAppendQueryString = false;

        const url = new URL(window.location.href);
        const urlLang = url.searchParams.get('lang');
        if (LANG_IDS.includes(urlLang)) {
            window.localStorage.setItem('locale', urlLang);
        } else {
            shouldAppendQueryString = true;
        }

        const urlSite = url.searchParams.get('site');
        if (SITE_IDS.includes(urlSite)) {
            window.localStorage.setItem('site', urlSite);
        } else {
            shouldAppendQueryString = true;
        }

        locale = window.localStorage.getItem('locale') || locale;
        site = window.localStorage.getItem('site') || site;

        if (shouldAppendQueryString) {
            urlAddLangSite({ lang: locale,  site: site});
        }

        return !shouldAppendQueryString;
    } else {
        return true;
    }
}
