<template lang="pug">
  Loading(v-if="isLoading")
  .uk-container.layout.uk-margin-large-top.uk-margin-large-bottom(v-else)
    ul.uk-breadcrumb(class="uk-visible@s")
      li
        router-link( :to="{ name: 'home' }")
          icon.icon-position-fixed.uk-margin-small-right(type="home" width="15" height="12")
          | {{ $t('HOME') }}
      li {{ $t('DOWNLOAD') }}

    SearchForm(
      :optionSets="searchOptionSets"
      v-model="searchForm"
      @onSearch="onSearch"
    )

    .uk-margin-large-top
      h1.uk-heading-line.uk-text-center.text-red
        span {{ $t('FILE_DOWNLOAD') }}

    .uk-margin-large-top
      a.item-row(
        v-for="post in posts"
        :key="post.post_id"
        :href="post.url"
        target="_blank"
      )
        .uk-width-auto.uk-margin-small-right.date {{ post.date }}
        .uk-width-auto.uk-margin-small-right.title {{ post.title }}
        .uk-width-expand.uk-margin-small-right.dots
        .uk-width-auto.uk-margin-small-right.size {{ post.size }}
        .uk-width-auto.icon
          icon(
            :type="getIcon(post.type)"
            width="28"
            height="30"
          )

    p.uk-padding.uk-text-center
      button.uk-button.uk-button-primary.uk-button-large.uk-width-1-1.text-remove-spacing.text-medium(
        v-if="noMore"
        class="uk-width-1-3@m uk-width-1-2@s"
        disabled
      )
        | {{ $t('NO_MORE_FILE') }}

      ButtonLoadMore(
        v-else
        @click="getMorePost"
      )
</template>
<script>
import { DownloadSearch } from '../mixins/DownloadSearch';
import { apiGetDownloadList } from '../apiRequest/others';

import SearchForm from '../components/SearchForm.vue';
import ButtonLoadMore from '../components/buttons/ButtonLoadMore.vue';

import { downloads } from '../mocks/downloads';

/*
interface IPost {
  post_id: number;
  date: string;
  title: string;
  size: string;
  url: string;
}
*/

const ICON_MAP = {
  ppt: 'icon-ppt',
  pdf: 'icon-pdf',
  word: 'icon-doc',
  excel: 'icon-xls',
  zip: 'icon-zip',
  jpg: 'icon-jpg',
};

export default {
  name: 'Download',
  mixins: [DownloadSearch],
  components: {
    SearchForm,
    ButtonLoadMore,
  },
  data () {
    return {
      posts: [],
      searchForm: {},
      noMore: false,
      isLoading: false,
    };
  },
  async created() {
    this.searchForm = { ...this.$route.query };

    try {
      this.isLoading = true;
      Promise.all([
        this.getPosts({ params: { ...this.searchForm } })
          .then(res => { this.posts = res; }),
      ]);
    } finally {
      this.isLoading = false;
    }
  },
  async beforeRouteUpdate(to, from, next) {
    const payload = {
      params: { ...to.query },
    };

    const res = await this.getPosts(payload);
    this.posts = res;

    next();
  },
  methods: {
    onSearch() {
      this.$router.replace({
        query: this.searchForm
      });
    },

    onClickPost(url) {
      if (typeof window !== 'undefined') {
        window.open(url, '_blank');
      }
    },

    async getMorePost() {
      const payload = {
        params: {
          offset: this.posts.length,
          ...this.searchForm,
        },
      };
      const res = await this.getPosts(payload);
      this.posts = [
        ...this.posts,
        ...res,
      ];
    },

    async getPosts(payload) {
      try {
        const res = await apiGetDownloadList(payload);
        if (!res) return [];

        this.noMore = !res.length;

        return res;
      } catch (e) {
        console.log(e);
        this.noMore = true;
        return [];
      }
    },

    getIcon(type) {
      if (!type) return '';
      return ICON_MAP[type.toLowerCase()];
    }
  },
  head: {
    title: {
      inner: ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_variable.scss';
@import '../../sass/helper/_mixin.scss';

.item-row {
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  display: flex;
  width: 100%;

  &:hover {
    background: rgba($color: #C7000B, $alpha: .3);
    text-decoration: none;
  }

  @include mq(640px) {
    padding: 0;
  }
}

.dots {
  border-top: 2px dotted $primary;
  margin: 0;
  margin-left: 12px;
}

.date {
  font-weight: bold;
  color: $primary;
  font-size: 20px;

  @include mq(960px) {
    font-size: 16px;
  }

  @include mq(640px) {
    font-size: 12px;
  }
}

.title {
  font-size: 24px;
  margin: 0;
  color: #000;

  @include mq(960px) {
    font-size: 20px;
  }

  @include mq(640px) {
    font-size: 14px;
  }
}

.size {
  font-size: 14px;
  color: $primary;
  margin: 0;

  @include mq(640px) {
    display: none;
  }
}

.icon {
  margin: 0;

  svg {
    @include mq(640px) {
      width: 20px;
    }
  }
}
</style>
