export async function apiGetEventsSlideshows(payload) {
  return await axios.get(
    `slideshow/events/home`,
    payload,
  );
}

export async function apiGetEventSearch(payload) {
  return await axios.get(
    `events/search`,
    payload,
  );
}

export async function apiGetEventsList(payload) {
  return await axios.get(
    `events/list`,
    payload,
  );
}

export async function apiGetEventsSingle(id, payload) {
  return await axios.get(
    `events/single/${id}`,
    payload,
  );
}
