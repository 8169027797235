<template lang='pug'>
  .footer(
    :style="{ backgroundImage: 'url(../../img/footer-bg.svg)' }"
  )
    vk-modal-full.footer-qr-code-modal(
      :show.sync="showModal"
      center
    )
      vk-modal-full-close(
        outside
        @click="toggleWechatModal"
      )
      .modal-body
        .title.uk-margin-bottom WeChat社群
        .subtitle.uk-margin-bottom 您可以掃描以下QRcode找到我們，與我們互動！
        img(:src="require('../../img/wechat-qr.jpg')")

    p.uk-text-center.uk-padding
      icon(type='icon-logo-w', width='178')
    vk-grid.uk-padding.padding-s.uk-position-relative.uk-margin-left.uk-margin-right(
      class='uk-child-width-1-3@m', gutter='collapse'
    )
      p.uk-margin-remove.uk-flex-first.padding-bottom-m.icon-group
        a.text-white.margin-small-horizonal.link-icon(
          href='https://www.youtube.com/channel/UCO2bWCLjyYU817qW5ZXeN0w'
          target="_blank"
        )
          vk-icon(icon='youtube', ratio='1.2')
        a.text-white.margin-small-horizonal.link-icon(
          href="https://www.facebook.com/cnex.channel"
          target="_blank"
        )
          vk-icon(icon='facebook', ratio='1.2')
        a.text-white.margin-small-horizonal.link-icon.wechat-icon(
          @click="onClickWechatIcon"
        )
          icon(
            type="wechat"
            width="22"
            height="22"
          )
      p.text-small-s.text-white.uk-margin-remove.uk-flex-last.uk-text-center.padding-bottom-m(
        class='uk-flex-first@m'
      ) {{ $t('COPYRIGHT') }}
      p.uk-margin-remove.text-no-spacing.uk-text-center.padding-large-bottom-m(
        class='uk-flex-last@m uk-text-right@m'
      )
        router-link.text-white.text-small.uk-text-nowrap(:to='{ name: "contact"}' ) {{ $t('CONTACT_US') }}
        span.text-white.margin-small-horizonal | 
        router-link.text-white.text-small.uk-text-nowrap(:to='{ name: "privacy" }') {{ $t('PRIVACY') }}
        span.text-white.margin-small-horizonal | 
        router-link.text-white.text-small.uk-text-nowrap(:to='{ name: "terms" }') {{ $t('TERMS_AND_CONDITION') }}
    i.line.bottom
    i.line.left
    i.line.right

</template>
<script>

export default {
  name: 'Footer',
  data () {
    return {
      showModal: false,
    };
  },
  methods: {
    toggleWechatModal() {
      this.showModal = !this.showModal;
    },
    onClickWechatIcon() {
      /** 用寬度判斷 */
      const isMobile = this.$store.getters['windowSize/isSmallThan'](960);
      if (!isMobile) this.toggleWechatModal();
    },
  },
  head: {
    title: {
      inner: ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../sass/helper/_mixin.scss';

.icon-group {
  @include mq(960px) {
    text-align: center;
  }
}

.footer {
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.wechat-icon {
  svg {
    fill: #fff;
  }
}
</style>

<style lang="scss">
.footer-qr-code-modal {
  .uk-modal-dialog  {
    width: auto;
    background: transparent;
    

    .modal-body {
      color: #fff;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        font-size: 30px;
      }

      .subtitle {
        font-size: 18px;
      }
    }
  }
}
</style>
