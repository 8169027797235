<template lang="pug">
  Loading(v-if="isLoading")
  .uk-container.layout.uk-margin-large-top.uk-margin-xlarge-bottom(v-else)
    ul.uk-breadcrumb(class="uk-visible@s")
      li
        router-link( :to="{ name: 'home' }")
          icon.icon-position-fixed.uk-margin-small-right(type="home" width="15" height="12")
          | {{ $t('HOME') }}
      li {{ $t('ABOUT') }} CNEX

    .uk-margin-medium-top
      h1.uk-h2.text-red.uk-text-center {{ $t('ABOUT') }} CNEX

    .uk-margin-medium-top
      .content(v-html="content")
</template>

<script>
import { apiGetAbout } from '../apiRequest/others';

export default {
  name: 'About',
  data () {
    return {
      content: '',
      isLoading: false,
    };
  },
  async created() {
    try {
      this.isLoading = true;
      let q = this.$route.query;
      await Promise.all([
        this.getContent({params:q}),
      ]);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async getContent(q) {
      const res = await apiGetAbout(q);
      if (!res || !res[0]) return;

      this.content = res[0].content;
    }
  },
  head: {
    title: {
      inner: ''
    },
  },
}
</script>
