import Vue from "vue";
import VueRouter from "vue-router";

import Layout from './pages/Layout.vue';
import Home from './pages/Home.vue';
import CatalogueHome from './pages/CatalogueHome.vue';
import CatalogueList from './pages/CatalogueList.vue';
import CatalogueSingle from './pages/CatalogueSingle.vue';
import ChannelHome from './pages/ChannelHome.vue';
import ChannelList from './pages/ChannelList.vue';
import ChannelCalendar from './pages/ChannelCalendar.vue';
import ChannelSingle from './pages/ChannelSingle.vue';
import ShoppingHome from './pages/ShoppingHome.vue';
import ShoppingList from './pages/ShoppingList.vue';
import ShoppingSingle from './pages/ShoppingSingle.vue';
import PostsHome from './pages/PostsHome.vue';
import PostsList from './pages/PostsList.vue';
import PostsSingle from './pages/PostsSingle.vue';
import EventsHome from './pages/EventsHome.vue';
import EventsSingle from './pages/EventsSingle.vue';
import AccountUserInfo from './pages/AccountUserInfo.vue';
import Contact from './pages/Contact.vue';
import Privacy from './pages/Privacy.vue';
import Terms from './pages/Terms.vue';
import Download from './pages/Download.vue';
import About from './pages/About.vue';
import Page from './pages/Page.vue';

import { genMeta } from './utils/genMeta';
import { getImgUrl } from './utils/helpers';

import { apiGetCataSingle } from './apiRequest/catalogue';
import { apiGetChannelSingle } from './apiRequest/channel';
import { apiGetPostsSingle } from './apiRequest/posts';
import { apiGetShopSingle } from './apiRequest/shopping';
import { apiGetEventsSingle } from './apiRequest/events';
import { DEFAULT_LANG } from "./utils/locales";
import { DEFAULT_SITE } from "./utils/sites";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'layout',
      component: Layout,
      children: [
        {
          path: '/',
          name: 'home',
          component: Home,
        },
        {
          path: 'catalogue/home',
          name: 'catalogueHome',
          component: CatalogueHome,
        },
        {
          path: 'catalogue/list',
          name: 'catalogueList',
          component: CatalogueList,
        },
        {
          path: 'catalogue/single/:id',
          name: 'catalogueSingle',
          component: CatalogueSingle,
          meta: {
            async genMeta(route) {
              const { lang } = route.query;
              const res = await apiGetCataSingle(route.params.id, { meta: { lang } });
              const slideshow_url = res[0].slideshow_url.find(o => o.img_url);
              const image = slideshow_url ? slideshow_url.img_url : '';
              const metaData = {
                image,
                title: res[0].title,
                description: res[0].detail,
              };

              return genMeta(metaData);
            },
          },
        },
        {
          path: 'channel/home',
          name: 'channelHome',
          component: ChannelHome,
        },
        {
          path: 'channel/list',
          name: 'channelList',
          component: ChannelList,
        },
        {
          path: 'channel/calendar',
          name: 'channelCalendar',
          component: ChannelCalendar,
        },
        {
          path: 'channel/single/:id',
          name: 'channelSingle',
          component: ChannelSingle,
          meta: {
            async genMeta(route) {
              const { lang } = route.query;
              const res = await apiGetChannelSingle(route.params.id, { meta: { lang } });
              const image = await getImgUrl([
                res[0].slideshow_url,
                `https://files.cnex.com.tw/channel/${res[0].post_id}_still.jpg`,
                'https://files.cnex.com.tw/channel/default_still.jpg',
              ]);
              const metaData = {
                image,
                title: res[0].title,
                description: res[0].detail,
              };

              return genMeta(metaData);
            },
          },
        },
        {
          path: 'shopping/home',
          name: 'shoppingHome',
          component: ShoppingHome,
          meta: {},
        },
        {
          path: 'shopping/list',
          name: 'shoppingList',
          component: ShoppingList,
        },
        {
          path: 'shopping/single/:id',
          name: 'shoppingSingle',
          component: ShoppingSingle,
          meta: {
            async genMeta(route) {
              const { lang } = route.query;
              const res = await apiGetShopSingle(route.params.id, { meta: { lang } });
              const metaData = {
                title: res[0].title,
                image: res[0].slideshow_url[0],
                description: res[0].summary,
              };

              return genMeta(metaData);
            },
          },
        },
        {
          path: 'posts/home',
          name: 'postsHome',
          component: PostsHome,
        },
        {
          path: 'posts/list',
          name: 'postsList',
          component: PostsList,
        },
        {
          path: 'posts/single/:id',
          name: 'postsSingle',
          component: PostsSingle,
          meta: {
            async genMeta(route) {
              const { lang } = route.query;
              const res = await apiGetPostsSingle(route.params.id, { meta: { lang } });
              const metaData = {
                title: res[0].title,
                image: res[0].img_url,
                description: res[0].content,
              };

              return genMeta(metaData);
            },
          },
        },
        {
          path: 'events/home',
          name: 'eventsHome',
          component: EventsHome,
        },
        {
          path: 'events/single/:id',
          name: 'eventsSingle',
          component: EventsSingle,
          meta: {
            async genMeta(route) {
              const { lang } = route.query;
              const res = await apiGetEventsSingle(route.params.id, { meta: { lang } });
              const metaData = {
                title: res[0].title,
                image: res[0].slider_img_url[0],
                description: res[0].detail,
              };

              return genMeta(metaData);
            },
          },
        },
        {
          path: 'account/userinfo',
          name: 'accountUserInfo',
          component: AccountUserInfo,
        },
        {
          path: 'contact',
          name: 'contact',
          component: Contact,
        },
        {
          path: 'privacy',
          name: 'privacy',
          component: Privacy,
        },
        {
          path: 'terms',
          name: 'terms',
          component: Terms,
        },
        {
          path: 'download',
          name: 'download',
          component: Download,
        },
        {
          path: 'about',
          name: 'about',
          component: About,
        },
        {
          path: 'page/:id',
          name: 'page',
          component: Page,
        },
      ]
    },
  ]
});

router.beforeEach((to, from, next) => {
  const lang = from.query.lang || DEFAULT_LANG;
  const site = from.query.site || DEFAULT_SITE;
  let flag = false;

    // console.log('GG1 = ', from.query);
    // console.log('GG11 = ', from.path);
    // console.log('GG2 = ', to.query);
    // console.log('GG22 = ', to.path);
    // console.log('GG3 = ', lang, site);

  if (!to.query.lang) {
      to.query.lang = lang;
      flag = true;
  }
  if (!to.query.site) {
      to.query.site = site;
      flag = true;
  }

  // console.log('GG4 = ', flag);

  if (flag) {
    next({ path: to.path, query: to.query });
  } else {
    next();
  }
});

export default router;
