import { apiGetCataSearch } from '../apiRequest/catalogue';

const SEARCH_FIELD = {
  status: 'status',
  genre: 'genre',
  country: 'country',
};

export const CatalogueSearch = {
  data() {
    return {
      searchOptions: {},
    };
  },
  async created() {
    Promise.all([
      this.getCataSearch({ params: { ...this.$route.query, field: SEARCH_FIELD.status } }),
      this.getCataSearch({ params: { ...this.$route.query, field: SEARCH_FIELD.genre } }),
      this.getCataSearch({ params: { ...this.$route.query, field: SEARCH_FIELD.country } }),
    ]);
  },
  methods: {
    async getCataSearch(payload) {
      const res = await apiGetCataSearch(payload);
      this.searchOptions = {
        ...this.searchOptions,
        [payload.params.field]: res,
      };
    },
  },
  computed: {
    searchOptionSets() {
      return [
        {
          key: SEARCH_FIELD.status,
          label: this.$t('CATA_SEARCH_STATUS'),
          options: this.searchOptions[SEARCH_FIELD.status],
        },
        {
          key: SEARCH_FIELD.genre,
          label: this.$t('GENRE'),
          options: this.searchOptions[SEARCH_FIELD.genre]
        },
        {
          key: SEARCH_FIELD.country,
          label: this.$t('PLACE_OF_ORIGIN'),
          options: this.searchOptions[SEARCH_FIELD.country],
        },
      ];
    },
  },
};
