export const SITE_ID = {
    t: 't',
    c: 'c',
    h: 'h',
};

export const SITE_OPTIONS = [
    {
        id: SITE_ID.t,
        label: 'SITE_TW',
    },
    {
        id: SITE_ID.c,
        label: 'SITE_CH',
    },
    {
        id: SITE_ID.h,
        label: 'SITE_HK',
    }
];

export const SITE_MAP = {
    [SITE_ID.ch]: {
        id: SITE_ID.t,
        label: 'SITE_TW',
        api: 't',
    },
    [SITE_ID.cn]: {
        id: SITE_ID.c,
        label: 'SITE_CH',
        api: 'c',
    },
    [SITE_ID.en]: {
        id: SITE_ID.h,
        label: 'SITE_HK',
        api: 'h',
    },
};

export const DEFAULT_SITE = SITE_ID.t;


export const nowSite = typeof window !== 'undefined'
  ? window.localStorage.getItem('site')
  : SITE_ID.t;
