export async function apiGetShopSlideshows(payload) {
  return await axios.get(
    `slideshow/shopping/home`,
    payload,
  );
}

export async function apiGetShopSearch(payload) {
  return await axios.get(
    `shopping/search`,
    payload,
  );
}

export async function apiGetShopList(payload) {
  return await axios.get(
    `shopping/list`,
    payload,
  );
}

export async function apiGetShopSingle(id, payload) {
  return await axios.get(
    `shopping/single/${id}`,
    payload,
  );
}

export async function apiGetShopSingleRelated(id, payload) {
  return await axios.get(
    `shopping/single/${id}/related`,
    payload,
  );
}
