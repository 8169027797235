<template lang="pug">
  span.share-btn(
    @click="click"
  )
    icon(
      type="fb-like"
      width="14"
      height="14"
      style="margin-right: 5px;"
    )
    | {{ $t('FB_SHARE') }}
    span(v-if="like") {{ like }}
</template>

<script>
export default {
  props: {
    like: Number,
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    click() {
      FB.ui({
        method: 'share',
        href: window.location.href,
      });
    },
  },
}
</script>

<style lang="scss" scoped>
$bg: #4267b2;

.share-btn {
  background: $bg;

  &:hover {
    background: darken($bg, 5%);
  }
}
</style>
