// TODO ssr
// import DatePicker from 'vue2-datepicker';
import DatePicker from '../libs/vue2-datepicker/index.vue';

const SEARCH_FIELD = {
  year: 'year',
  month: 'month',
  date: 'date',
};

/**
 * parent 需定義 
 * data: searchForm
 * ref: datePicker
 */

export const ChannelSearch = {
  components: {
    DatePicker,
  },
  data() {
    return {
      datePickerForm: '',
    };
  },
  methods: {
    async onChangeDatePicker(value /** YYYY-MM-DD */) {
      const [ year, month, date ] = value.split('-');
      this.searchForm = {
        ...this.searchForm,
        year: year,
        month: month,
        date: '',
      };
      await this.$nextTick();
      this.searchForm.date = date;
    },

    showDatePickerPop() {
      const { datePicker } = this.$refs;
      if (datePicker.popupVisible) return;
      datePicker.showPopup();
    },
  },
  computed: {
    searchOptionSets() {
      return [
        {
          key: SEARCH_FIELD.year,
          label: this.$t('YEAR'),
          options: this.yearOptions,
        },
        {
          key: SEARCH_FIELD.month,
          label: this.$t('MONTH'),
          options: this.monthOptions,
        },
        {
          key: SEARCH_FIELD.date,
          label: this.$t('DATE'),
          options: this.dateOptions,
        },
      ];
    },
    yearOptions() {
      const nowYear = new Date().getFullYear();
      const startYear = nowYear - 10;
      return new Array(21)
        .fill(1)
        .map((o, i) => ({
          label: startYear + i,
          value: startYear + i,
        }));
    },
    monthOptions() {
      return new Array(12)
        .fill(1)
        .map((o, i) => ({
          label: `${i + 1}${this.$t('CHANNEL_MONTH_UNIT')}`,
          value: i + 1 > 9 ? `${i + 1}` : `0${i + 1}`,
        }));
    },
    dateOptions() {
      const { year, month } = this.searchForm;
      if (!year || !month) return [];
      const days = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();

      return new Array(days)
        .fill(1)
        .map((o, i) => ({
          label: `${i + 1}${this.$t('CHANNEL_DATE_UNIT')}`,
          value: i + 1 > 9 ? `${i + 1}` : `0${i + 1}`,
        }));
    },
  },
}