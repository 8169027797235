import Vue from 'vue';
import VueI18n from 'vue-i18n';
import _ from 'lodash';

import { LANG_ID } from './utils/locales';
import { SITE_ID } from './utils/sites';

import en from './local/en';
import ch from './local/ch';
import cn from './local/cn';

Vue.use(VueI18n);

const LANG_IDS = _.map(LANG_ID, v => v);
const SITE_IDS = _.map(SITE_ID, v => v);
let locale = LANG_ID.ch;
//**************************************
let site = SITE_ID.t;
//**************************************

if (typeof window !== 'undefined') {
    const url = new URL(window.location.href);
    const urlLang = url.searchParams.get('lang');
    if (LANG_IDS.includes(urlLang)) {
        window.localStorage.setItem('locale', urlLang);
    }

    const urlSite = url.searchParams.get('site');
    if (SITE_IDS.includes(urlSite)) {
        window.localStorage.setItem('site', urlSite);
    }

    locale = window.localStorage.getItem('locale') || locale;
    site = window.localStorage.getItem('site') || site;
}

const numberFormats = {
    en: {
        currency: {
            style: 'currency', currency: 'USD',
        },
    },
    ch: {
        currency: {
            style: 'currency', currency: 'TWD',
        },
    },
};

const i18n = new VueI18n({
    locale,
    numberFormats,
    messages: {
        en,
        ch,
        cn,
    },
});

export default i18n;
